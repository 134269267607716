import {
    authHeader,
    createParams,
    createQuery, getLocale,
    handleBinDownloadResponse,
    handleResponse,
    handleResponseForOrganization
} from '../helper';

import {fetchWithToken as fetch, login, logout, loginWithCode} from '../helper/fetchIntercept.js';
import {fetchForDownload} from "../helper/fetchIntercept";
import {setExclusive} from "../helper/fetchUtils";
import {snakeCase} from 'lodash';

const backendHost = window.globalConfig.backendHost || process.env.REACT_APP_BACKEND_URL;

export const userService = {
    login,
    logout,
    loginWithCode,
    fetchUsers,
    fetchUserGroupsById,
    fetchUserSubGroupById,
    createUserGroups,
    updateUserGroupsById,
    deleteUserGroupsById,
    moveUserGroup,
    deleteUserOrganizationById,
    checkUserIdAvailability,
    fetchUserFilter,
    fetchUserById,
    fetchUserContentsAdministrators,
    fetchUserDevicePermissionById,
    updateUserDevicePermissionById,
    fetchUserLoginInformation,
    updateUserCurrentManagedOrganization,
    fetchOrganizations,
    fetchOrganizationsGroups,
    fetchOrganizationsGroupsCount,
    fetchOrganizationGroupsById,
    fetchOrganizationGroupDeletableStatus,
    fetchOrganizationGroups,
    deleteOrganizationGroups,
    createOrganizationGroups,
    updateOrganizationGroups,
    fetchOrganizationGroupDetail,
    fetchOrganizationGroupOrganizations,
    fetchUserRoles,
    fetchUserAssignableRoles,
    fetchUserRolesFilter,
    fetchAllRoleAbilities,
    fetchUserRoleUserList,
    fetchUserRoleAbilities,
    deleteUserRole,
    createUserRoles,
    updateUserRoles,
    checkUserRoleNameValidation,
    saveUsers,
    updateUsers,
    updateUnapprovedUsers,
    updateUsersByRole,
    saveOrganizations,
    withdrawnUsers,
    deleteWithdrawanUsers,
    moveOrganization,
    fetchLDAPOrganizations,
    fetchLDAPOrganizationsFilter,
    fetchLDAPOrganizationDetail,
    fetchLDAPOrganizationsById,
    fetchLDAPUsers,
    fetchLdapGroupList,
    fetchLdapRootGroupList,
    fetchDeviceGroupList,
    fetchCheckExistSyncGroup,
    fetchCheckExistSyncLDAPOrganization,
    fetchGetServerSetUp,
    fetchOrganizationsForSignUp,
    saveUsersForSignUp,
    createLDAPOrganizations,
    updateLDAPOrganizations,
    syncLDAPOrganizations,
    deleteLDAPOrganizations,
    fetchUserFileDownload,
    updateTemporaryPassword,
    checkResetPasswordValid,
    changePassword,
    resetPassword,
    updateLocale,
    searchUserGroupByGroupName,
    setupMfaAuth,
    fetchStoredDevices,
    deleteStoredDeviceById,
    resetMfa
};

/*
function login(username, password) {
    let data = JSON.stringify({
        password: password,
        username: username
    })
    return fetch(`${backendHost}/restapi/v2.0/auth`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: data
    })
        .then(handleResponse)
        .then(user => {
            const jwt = jwtDecode(user.token);
            user = {...user, id: username, authority: JSON.parse(jwt.viewAuthority), refreshToken: user.refreshToken};
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        })
}
*/

/*function refreshToken() {
    const user = localStorage.getItem('user');
    const parse = JSON.parse(user);
    if (user && parse) {
        const body = JSON.stringify({
            token: parse.token,
            refreshToken: parse.refreshToken,
            grantType: 'refresh_token'
        });
        return fetch(`${backendHost}/restapi/v2.0/auth`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: body
        })
            .then(handleResponse)
            /!*.then(rtn => {
                if (rtn.token) {
                    const updateUser = {...user, token: rtn.token};
                    localStorage.setItem('user', JSON.stringify(updateUser));

                    return true;
                }
            })
            .catch(e => {
                toastr.error(getErrorMessage(e));
                logout();
                return false;
            })*!/
    }
}*/

/*function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}*/

function fetchUsers() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let params = {
        "groupId": 0,
        "groupType": "all",
        "searchId": -1,
        "pageSize": 30
    }
    return fetch(backendHost + '/restapi/v2.0/ums/users?' + createQuery(params), requestOptions).then(handleResponse);
}

function fetchUserGroupsById(groupId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(backendHost+`/restapi/v2.0/ums/users/groups/${groupId}`, requestOptions).then(handleResponse);
}

function fetchUserSubGroupById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(backendHost + '/restapi/v2.0/ums/users/groups/' + id + '/sub-groups', requestOptions).then(handleResponse);
}

function createUserGroups(values) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(values)
    }
    return fetch(backendHost + '/restapi/v2.0/ums/users/groups', requestOptions).then(handleResponse);
}

function updateUserGroupsById(groupId, values) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(values)
    }
    return fetch(`${backendHost}/restapi/v2.0/ums/users/groups/${groupId}`, requestOptions).then(handleResponse);
}

function deleteUserGroupsById(groupId){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(backendHost+`/restapi/v2.0/ums/users/groups/${groupId}`, requestOptions).then(handleResponse);
}

function moveUserGroup(groupId, destinationGroupId) {
    return fetch(`${backendHost}/restapi/v2.0/ums/users/groups/${groupId}/destination-groups/${destinationGroupId}`, {
        method: 'PUT',
        headers: authHeader('PUT'),
    }).then(handleResponse);
}

function deleteUserOrganizationById(organizationId){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(backendHost+`/restapi/v2.0/ums/organizations/${organizationId}`, requestOptions).then(handleResponse);
}

function checkUserIdAvailability(userId, ldapFullId = '') {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(backendHost + `/restapi/v2.0/ums/users/${userId}/available-user-id?ldapFullId=${encodeURIComponent(ldapFullId)}`, requestOptions).then(handleResponse);
}

function fetchUserFilter(values, exclusive) {
    const requestOptions = exclusive ? setExclusive({
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(values)
    }) : {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(values)
    };
    return fetch(backendHost + '/restapi/v2.0/ums/users/filter', requestOptions).then(handleResponse);
}

function fetchUserById(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET'),
    }
    return fetch(backendHost+`/restapi/v2.0/ums/users/${userId}`, requestOptions).then(handleResponse);
}

function fetchUserContentsAdministrators(values) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(values)
    }
    return fetch(backendHost + '/restapi/v2.0/ums/users/contents/administrators', requestOptions).then(handleResponse);
}

function fetchUserDevicePermissionById(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET'),
    }
    return fetch(backendHost+`/restapi/v2.0/ums/users/groups/${userId}/device-permission`, requestOptions).then(handleResponse);
}

function updateUserDevicePermissionById(userId, param) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(param)
    }
    return fetch(backendHost+`/restapi/v2.0/ums/users/groups/${userId}/device-permission`, requestOptions).then(handleResponse);
}

function fetchUserLoginInformation() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET'),
    }
    return fetch(backendHost+`/restapi/v2.0/ums/users/me`, requestOptions).then(handleResponse);
}

function updateUserCurrentManagedOrganization(param) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(param)
    }
    return fetch(`${backendHost}/restapi/v2.0/ums/users/current-managed-organization`, requestOptions).then(handleResponse);
}

function fetchOrganizations(needChildGroup = false) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(backendHost + '/restapi/v2.0/ums/organizations?subGroup=false&count=false', requestOptions).then((response) => {return handleResponseForOrganization(response, needChildGroup)});
}

function fetchOrganizationsGroups(needChildGroup = false) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(backendHost + '/restapi/v2.0/ums/organizations?subGroup=true&count=false', requestOptions).then((response) => {return handleResponseForOrganization(response, needChildGroup)});
}

function fetchOrganizationsGroupsCount(needChildGroup = false) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(backendHost + '/restapi/v2.0/ums/organizations?subGroup=true&count=true', requestOptions).then((response) => {return handleResponseForOrganization(response, needChildGroup)});
}

function fetchOrganizationGroups(params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    if(params!==undefined && params.sorted){
        params['sortColumn'] = snakeCase(params.sorted[0].id);
        params['sortOrder'] = params.sorted[0].desc ? 'DESC' : 'ASC';
    }

    return fetch(`${backendHost}/restapi/v2.0/ums/organization-groups?` + createQuery(params), requestOptions).then(handleResponse);
}

function fetchOrganizationGroupsById(organizationGroupId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${backendHost}/restapi/v2.0/ums/organization-groups/${organizationGroupId}`, requestOptions).then(handleResponse);
}

function fetchOrganizationGroupDeletableStatus(requestBody) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body:createParams(requestBody)
    };
    return fetch(`${backendHost}/restapi/v2.0/ums/organization-groups/deletability`, requestOptions).then(handleResponse);
}

function deleteOrganizationGroups(ids) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(ids)
    }
    return fetch(`${backendHost}/restapi/v2.0/ums/organization-groups/deleted-organizationGroups`, requestOptions).then(handleResponse);
}

function createOrganizationGroups(group) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(group)
    }
    return fetch(`${backendHost}/restapi/v2.0/ums/organization-groups`, requestOptions).then(handleResponse);
}

function updateOrganizationGroups(groupId, group) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(group)
    }
    return fetch(`${backendHost}/restapi/v2.0/ums/organization-groups/${groupId}`, requestOptions).then(handleResponse);
}

function fetchOrganizationGroupDetail(groupId) {
    return fetch(`${backendHost}/restapi/v2.0/ums/organization-groups/${groupId}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchOrganizationGroupOrganizations(needChildGroup = false) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(backendHost + '/restapi/v2.0/ums/organizations?subGroup=false&count=false', requestOptions).then((response) => {return handleResponseForOrganization(response, needChildGroup)});
}

function fetchUserRoles(params) {
    return fetch(`${backendHost}/restapi/v2.0/ums/roles?` + createQuery(params), {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchUserAssignableRoles() {
    return fetch(`${backendHost}/restapi/v2.0/ums/roles/assignable-roles`, {
        method: 'GET',
        headers: authHeader()
    }).then(handleResponse);
}

function fetchUserRolesFilter(params) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(params)
    }
    return fetch(`${backendHost}/restapi/v2.0/ums/roles/filter`, requestOptions).then(handleResponse);
}

function fetchAllRoleAbilities() {
    return fetch(`${backendHost}/restapi/v2.0/ums/roles/abilities`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchUserRoleUserList(roleId) {
    return fetch(`${backendHost}/restapi/v2.0/ums/roles/${roleId}/users`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchUserRoleAbilities(roleId) {
    return fetch(`${backendHost}/restapi/v2.0/ums/roles/${roleId}/abilities`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function deleteUserRole(ids) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(ids)
    }
    return fetch(`${backendHost}/restapi/v2.0/ums/roles/deleted-roles`, requestOptions).then(handleResponse);
}

function createUserRoles(role) {
    return fetch(`${backendHost}/restapi/v2.0/ums/roles`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(role),
    }).then(handleResponse);
}

function updateUserRoles(roleId, role) {
    return fetch(`${backendHost}/restapi/v2.0/ums/roles/${roleId}/abilities`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(role)
    }).then(handleResponse);
}

function checkUserRoleNameValidation(params) {
    return fetch(`${backendHost}/restapi/v2.0/ums/roles/available-role-name?` + createQuery(params), {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function saveUsers(values) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(values)
    }
    return fetch(backendHost + '/restapi/v2.0/ums/users', requestOptions).then(handleResponse);
}

function updateUsers(userId, values) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(values)
    }
    return fetch(backendHost + `/restapi/v2.0/ums/users/${userId}`, requestOptions).then(handleResponse);
}

function updateUnapprovedUsers(values) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(values)
    }
    return fetch(backendHost + `/restapi/v2.0/ums/users/unapproved-users`, requestOptions).then(handleResponse);
}

function updateUsersByRole(roleId, values) {
    return fetch(`${backendHost}/restapi/v2.0/ums/users/roles/${roleId}`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(values)
    }).then(handleResponse);
}

function saveOrganizations(values) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(values)
    }
    return fetch(backendHost + '/restapi/v2.0/ums/organizations', requestOptions).then(handleResponse);
}

function withdrawnUsers(values) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(values)
    }
    return fetch(backendHost + '/restapi/v2.0/ums/users/withdrawn-users', requestOptions).then(handleResponse);
}

function deleteWithdrawanUsers(values) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(values)
    }
    return fetch(backendHost + '/restapi/v2.0/ums/users/deleted-users', requestOptions).then(handleResponse);
}

function moveOrganization(organizationId, values) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(values)
    }
    return fetch(backendHost + `/restapi/v2.0/ums/users/organizations/${organizationId}`, requestOptions).then(handleResponse);
}

function fetchLDAPOrganizations(params) {
    return fetch(`${backendHost}/restapi/v2.0/ums/ldap-organizations?` + createQuery(params), {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchLDAPOrganizationsFilter(params) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(params)
    }
    return fetch(`${backendHost}/restapi/v2.0/ums/ldap-organizations/filter`, requestOptions).then(handleResponse);
}

function syncLDAPOrganizations(values) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(values)
    }
    return fetch(backendHost + '/restapi/v2.0/ums/ldap-organizations/synchronization', requestOptions).then(handleResponse);
}

function createLDAPOrganizations(values) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(values)
    }
    return fetch(backendHost + '/restapi/v2.0/ums/ldap-organizations', requestOptions).then(handleResponse);
}

function updateLDAPOrganizations(ldapOrganizationId, values) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(values)
    }
    return fetch(backendHost + `/restapi/v2.0/ums/ldap-organizations/${ldapOrganizationId}`, requestOptions).then(handleResponse);
}

function deleteLDAPOrganizations(values) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(values)
    }
    return fetch(backendHost + '/restapi/v2.0/ums/ldap-organizations/deleted-ldap-organizations', requestOptions).then(handleResponse);
}

function fetchLDAPOrganizationDetail(ldapOrganizationId) {
    return fetch(`${backendHost}/restapi/v2.0/ums/ldap-organizations/${ldapOrganizationId}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchLDAPOrganizationsById(organizationId) {
    return fetch(`${backendHost}/restapi/v2.0/ums/users/ldap/organization/${organizationId}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchLDAPUsers(values) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(values)
    }
    return fetch(backendHost + '/restapi/v2.0/ums/organizations/remote-ldap-organizations/searched-ldap-user', requestOptions).then(handleResponse);
}

function fetchLdapGroupList(groupId) {
    return fetch(`${backendHost}/restapi/v2.0/ums/users/groups/${groupId}/remote-ldap-organizations`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchLdapRootGroupList(groupId) {
    return fetch(`${backendHost}/restapi/v2.0/ums/users/groups/${groupId}/remote-ldap-organizations/root`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchDeviceGroupList(groupId) {
    return fetch(`${backendHost}/restapi/v2.0/ums/users/devices?groupId=${groupId}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchCheckExistSyncGroup(groupId) {
    return fetch(`${backendHost}/restapi/v2.0/ums/users/groups/${groupId}/ldap-organizations/in-use-check`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchCheckExistSyncLDAPOrganization(param) {
    return fetch(`${backendHost}/restapi/v2.0/ums/remote-ldap-organizations/in-use-check`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(param)
    }).then(handleResponse);
}

function fetchGetServerSetUp(groupId) {
    return fetch(`${backendHost}/restapi/v2.0/ums/users/groups/${groupId}/server-settings`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchUserFileDownload(exportType, filter) {
    const locale = getLocale();
    return fetchForDownload(`${backendHost}/restapi/v2.0/ums/users/filter/export?exportType=${exportType}&locale=${locale}`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(filter),
    }).then(handleBinDownloadResponse);
}

function fetchOrganizationsForSignUp() {
    return fetch(`${backendHost}/restapi/v2.0/ums/organizations/anonymous`, {
        method: 'GET',
        headers: {'accept': 'application/json'},
    }).then(handleResponse);
}

function saveUsersForSignUp(values) {
    return fetch(`${backendHost}/restapi/v2.0/ums/users/signup`, {
        method: 'POST',
        headers: {'accept': 'application/json', 'Content-Type': 'application/json'},
        body: createParams(values)
    }).then(handleResponse);
}

function handleResponseForSignUp(response) {
    return new Promise((resolve, reject) => {
        if (!response.ok) {
            return reject(response);
        } else {
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    return new reject(data);
                }
                resolve(data);
            });
        }
    });

}

function updateTemporaryPassword(userId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT')
    }
    return fetch(backendHost + `/restapi/v2.0/ums/users/${userId}/temporary-password`, requestOptions).then(handleResponse);
}

function changePassword(params) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(params)
    }
    return fetch(backendHost + `/restapi/v2.0/ums/users/passwords`, requestOptions).then(handleResponse);
}

function resetPassword(userId, userEmail, locale, authType) {
    //return fetch(`${backendHost}/restapi/v2.0/ums/users/passwords/token`, {
    return fetch(`${backendHost}/restapi/v2.0/auth/reset/${authType}`, {
        method: 'POST',
        headers: {'accept': 'application/json', 'Content-Type': 'application/json'},
        body: createParams({userId: userId, email: userEmail, locale: locale})
    }).then(handleResponse);
}

function checkResetPasswordValid(code, userId, authType) {
    //return fetch(`${backendHost}/restapi/v2.0/ums/users/passwords/token/valid`, {
    return fetch(`${backendHost}/restapi/v2.0/auth/reset/valid/${authType}`, {
        method: 'POST',
        headers: {'accept': 'application/json', 'Content-Type': 'application/json'},
        body: createParams({encryptionToken: code, userId: userId})
    }).then(handleResponse);
}

function updateLocale(locale) {
    return fetch(`${backendHost}/restapi/v2.0/ums/users/current-managed-locale?locale=${locale}`, {
        method: 'PUT',
        headers: authHeader('PUT')
    }).then(handleResponse);
}

function searchUserGroupByGroupName(searchText) {
    return fetch(`${backendHost}/restapi/v2.0/ums/users/groups/searched-groups?searchText=${searchText}`, {
        method: 'POST',
        headers: authHeader('POST')
    }).then(handleResponse);
}

function setupMfaAuth(authData) {
    return fetch(`${backendHost}/restapi/v2.0/auth`, {
        method: 'POST',
        headers: {'accept': 'application/json', 'Content-Type': 'application/json'},
        body: createParams(authData)
    }).then(handleResponse);
}

function fetchStoredDevices(userId){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${backendHost}/restapi/v2.0/auth/devices/${userId}?ts=${new Date().getTime()}`, requestOptions).then(handleResponse);
}


function deleteStoredDeviceById(userId, deviceId){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(backendHost+`/restapi/v2.0/auth/devices/${userId}/${deviceId}`, requestOptions).then(handleResponse);
}


function resetMfa(userId){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(backendHost+`/restapi/v2.0/auth/user/reset/otp/${userId}`, requestOptions).then(handleResponse);
}
