import React from 'react';
import FLIP_1 from '../../../images/icon/FLIP1.0.png';
import FLIP_2 from '../../../images/icon/FLIP2.0.png';
const Flip = ({deviceTypeVersion, text, disabled = false}) => {
    return(
        <span className={"device_icon"}>
            {deviceTypeVersion < 2 &&
                <img src={FLIP_1} style={{opacity: disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'FLIP'} data-deviceTypeVersion={deviceTypeVersion}/>
            }
            {deviceTypeVersion > 1 && deviceTypeVersion < 3 &&
                <img src={FLIP_2} style={{opacity: disabled ? 0.7 : 1, webkitFilter: disabled ? 'grayscale(100%)' : undefined}} data-deviceType={'FLIP'} data-deviceTypeVersion={deviceTypeVersion}/>
            }
            {text ?
                `Flip ${deviceTypeVersion > 1 ? deviceTypeVersion : ''}`
                : ''
            }
        </span>
    )
}
export default Flip