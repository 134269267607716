import React, {useCallback, useEffect, useState} from 'react';
import './Dashboard.css';
import DashboardPanel from "./DashboardPanel";
import WhiteButton from "../../components/button/WhiteButton";
import {useTranslation} from "react-i18next";
import CircleButton from "../../components/button/CircleButton";
import {dashboardService, settingService} from "../../services";
import {toastr} from 'helper/toastrIntercept';
import Moment from "moment";
import PrivacyPolicyFooter from "../../components/link/PrivacyPolicyFooter";
import {getErrorMessage} from "../../helper/responseHandler";
import {useDispatch, useSelector} from "react-redux";
import {popupAction} from "../../actions";
import {commonConstants, settingConstants} from "../../constants";
import {updateCache} from "../../helper/cache/tableCache";
import {isEmpty} from 'lodash';

const Dashboard = (props) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [isEditMode, setIsEditMode] = useState(false);
    const [myDashboards, setMyDashboards] = useState(props.cache.dashboards || []);
    const [editDashboards, setEditDashboards] = useState([]);
    const [privacyPolicyList, setPrivacyPolicyList] = useState('');
    const [privacyPolicySettings, setPrivacyPolicySettings] = useState('');

    useEffect(()=> {
        if (myDashboards.length === 0) {
            fetchGetMyDashboardList();
        }
        fetchPrivacyPolicy();
    }, []);

    useEffect(()=> {
        if (isEditMode) {
            fetchGetEditDashboardList(myDashboards)
        }
    }, [isEditMode]);

    const onEditClickEvent = () => {
        setIsEditMode(!isEditMode);
        if(isEditMode) {
            fetchGetEditDashboardList(myDashboards);
        }
    };

    const onAddLayoutClickEvent = (id) => {
        let dashboard = editDashboards.find(o => o.dashboardId === id);
        if (dashboard) {
            fetchAddMyDashboard(dashboard);
        }
    };

    const onRemoveLayoutClickEvent = (id) => {
        const dashboard = myDashboards.find(o => o.dashboardId === id);
        if (dashboard) {
            fetchDeleteMyDashboard(dashboard.dashboardName);
        }
    };

    const onSortChangeEvent = (dashboards) => {
        let data = {};
        dashboards.forEach((dashboard, index) => {
            let key = dashboard.dashboardName;
            dashboard.priority = index + 1;
            let obj = {[key]: dashboard.priority};
            Object.assign(data, obj);
        });
        setMyDashboards(dashboards);
        fetchSortMyDashboard(data);
    };

    const getDashboardName = (id) => {
        switch (id) {
            case 1:
                return t("TEXT_TITLE_PORTLET_LOGIN_P");
            case 2:
                return t("COM_DID_MAIN_NOTICE");
            case 3:
                return t("MIS_SID_20_SERVER_STORAGE");
            case 4:
                return t("MIS_SID_20_CONTENT_SUMMARY");
            case 5:
                return t("MIS_SID_20_PLAYLIST_SUMMARY");
            case 6:
                return t("TEXT_AD_SCHEDULE_SUMMARY_VIEW_P");
            case 7:
                return t("TEXT_DEVICE_SUMMARY_P_KR_MIS20");
            case 8:
                return t("TEXT_USER_SUMMARY_P_KR_MIS20");
            case 9:
                return t("MIS_SID_EDGE_SERVER") + " " + t("COM_TV_SID_DASHBOARD");
            case 10:
                return 'Remote Control Server' + " " + t("COM_TV_SID_DASHBOARD");
            case 11:
                return t("MIS_SID_20_DATALINK_SERVER") + " " + t("COM_TV_SID_DASHBOARD");
            case 12:
                return t('MIS_SID_CONNECTED_DEVICE_INFO');
            case 13:
                return t("MIS_SID_CONNECTED_DEVICE_INFO") + " (" + t("TEXT_DAY_P") + ")";
            case 14:
                return t("MESSAGE_STATISTICS_PAGE_SUB_TITLE_ERROR_P") + " / " + t("TEXT_TITLE_WARNING_P");
            case 15:
                return t("COM_SOFTWARE_UPDATE") + " " + t('COM_TV_SID_DASHBOARD');
            default:
                return t("TEXT_UNKNOWN_P") + " " + t('COM_TV_SID_DASHBOARD');
        }
    };

    const sortDashboard = (items) => {
        items.sort(function (a, b) {
            return a.priority < b.priority ? -1 : a.priority > b.priority ? 1 : 0;
        });
        return items;
    };

    const fetchGetMyDashboardList = () => {
        dashboardService.fetchMyDashboardList().then(res => {
            if (res) {
                const sortedMyDashboards = Object.assign([], sortDashboard(res.items));
                setMyDashboards(sortedMyDashboards);
                updateCache('DASHBOARD', {dashboards: sortedMyDashboards});
            }
        }).catch(e => toastr.error(getErrorMessage(e)));
    };

    const fetchGetEditDashboardList = (mine) => {
        dashboardService.fetchAllDashboardList().then(res => {
            sortDashboard(res.items);
            const edit = [];
            res.items.forEach((item) => {
                let index = mine.findIndex(o => o.dashboardId === item.dashboardId);
                if (index === -1) {
                    edit.push(item);
                }
            });
            setEditDashboards(edit);
        }).catch(e => {
            const ermsg = e.errorCode == "405" ? t("ERROR_NO_ROLE") : getErrorMessage(e);
            toastr.error(ermsg)
        });
    };

    const fetchAddMyDashboard = (dashboard) => {
        dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}));
        dashboardService.addMyDashboard(dashboard.dashboardName).then(res => {
            Promise.all([dashboardService.fetchMyDashboardList(), dashboardService.fetchAllDashboardList()]).then(res => {
                sortDashboard(res[0].items);
                sortDashboard(res[1].items);
                const edit = [];
                res[1].items.forEach((item) => {
                    let index = res[0].items.findIndex(o => o.dashboardId === item.dashboardId);
                    if (index === -1) {
                        edit.push(item);
                    }
                });
                setMyDashboards(res[0].items);
                setEditDashboards(edit);
            });
        }).catch(e => toastr.error(getErrorMessage(e))).finally(dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP)));
    };

    const fetchDeleteMyDashboard = (name) => {
        dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}));
        dashboardService.deleteMyDashboard(name).then(res => {
            Promise.all([dashboardService.fetchMyDashboardList(), dashboardService.fetchAllDashboardList()]).then(res => {
                sortDashboard(res[0].items);
                sortDashboard(res[1].items);
                const edit = [];
                res[1].items.forEach((item) => {
                    let index = res[0].items.findIndex(o => o.dashboardId === item.dashboardId);
                    if (index === -1) {
                        edit.push(item);
                    }
                });
                setMyDashboards(res[0].items);
                setEditDashboards(edit);
            })
        }).catch(e => toastr.error(getErrorMessage(e))).finally(dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP)));
    };

    const fetchPrivacyPolicy = () => {
        settingService.fetchServerSettings().then(res => {

            if(!isEmpty(res) && !isEmpty(res.items)){
                setPrivacyPolicySettings(res.items.privacyPolicy);
            }
        }).catch(err => console.log(err));

        const privacyPolicyList = localStorage.getItem(settingConstants.PRIVACY_POLICY);
        if (isEmpty(privacyPolicyList)) {
            settingService.fetchPrivacyPolicyList().then(res => {
                if(!isEmpty(res) && !isEmpty(res.items)){
                    setPrivacyPolicyList(res.items);
                    localStorage.setItem(settingConstants.PRIVACY_POLICY,res.items);
                }

            }).catch(err => console.log(err));
        }else{
            setPrivacyPolicyList(JSON.parse(privacyPolicyList));
        }

    };

    const fetchSortMyDashboard = (dashboards) => {
        dashboardService.sortMyDashboard(dashboards).then(res => {
            Promise.all([dashboardService.fetchMyDashboardList(), dashboardService.fetchAllDashboardList()]).then(res => {
                sortDashboard(res[0].items);
                sortDashboard(res[1].items);
                const edit = [];
                res[1].items.forEach((item) => {
                    let index = res[0].items.findIndex(o => o.dashboardId === item.dashboardId);
                    if (index === -1) {
                        edit.push(item);
                    }
                });
                setMyDashboards(res[0].items);
                setEditDashboards(edit);
            })
        }).catch(e => toastr.error(getErrorMessage(e)));
    };

    const onRefreshClickEvent = () => {
        setMyDashboards([]);
        fetchGetMyDashboardList();
    }

    const renderEditLayout = () => {
        if (!isEditMode || !editDashboards || editDashboards.length <= 0) {
            return (null);
        }
        return (
            <div className="sDashboard-edit">
                {
                    editDashboards.map((dashboard, index) => {
                        let dashboardName = getDashboardName(dashboard.dashboardId);
                        return (
                            <div key={dashboard.id + "-" + index} className="sDashboard-edit-menu">
                                <h4>
                                    {dashboardName}
                                    <button className="add" onClick={() => {
                                        onAddLayoutClickEvent(dashboard.dashboardId)
                                    }}/>
                                </h4>
                            </div>
                        );
                    })
                }
            </div>
        )
    }

    return (
        <div className="dashboardWrap">
            <div className="btnWrap">
                <WhiteButton id={"EDIT"} name={t('MIS_SID_20_EDIT_LAYOUT')} onClick={onEditClickEvent} selected={isEditMode} style={{marginRight: '4px'}}/>
                <CircleButton id={"REFRESH"} onClick={onRefreshClickEvent} disable={isEditMode}>
                    <span className='dashboard_refresh_btn'/>
                </CircleButton>
            </div>
            {
                renderEditLayout()
            }
            <DashboardPanel isEditMode={isEditMode} dashboards={myDashboards} onSortChangeEvent={onSortChangeEvent} onRemoveLayoutClickEvent={onRemoveLayoutClickEvent}/>
            <div className='copyRight'>
                Copyright @ 2009 - 2020 Samsung Electronics Co., Ltd.<br/>
                {window.globalConfig.magicInfoFrontEndVersion}&nbsp;|&nbsp;
                {
                    privacyPolicyList && <><PrivacyPolicyFooter login={true} privacyPolicyList={privacyPolicyList} privacyPolicySettings={privacyPolicySettings}/></>
                }
                <a href="https://displaysolutions.samsung.com/about-us/contact-us" target="_blank">{t('MIS_SID_CONTACT_US')}</a>
            </div>
        </div>
    )
}

export default Dashboard;
