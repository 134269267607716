import React from 'react';
import {connect} from 'react-redux';
import {alertActions, userActions} from '../../actions';
import queryString from 'query-string';
import {withTranslation} from "react-i18next";
import {history} from "../../helper";

class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        const username = localStorage.getItem('magicInfoUserId') || '';

        this.state = {
            username: username,
            password: '',
        };

        this.requestToken();
    }

    requestToken() {
        const {loginWithCode} = this.props;
        let params = queryString.parse(this.props.location.search);
        loginWithCode(params);
        localStorage.setItem('useAuthServer', true);
    }

    render() {
        return (
            <>
            </>
        );
        //<div className="container bg">Loading....</div>
    }
}
export default connect(
    state => ({
        authentication: state.authentication,
        error: state.users.error
    }),
    dispatch => ({
        logout: ()=> dispatch(userActions.logout()),
        login: (userId, password)=> dispatch(userActions.login(userId, password)),
        loginWithCode:(params)=> dispatch(userActions.loginWithCode(params)),
    })
)(withTranslation()(LandingPage));
