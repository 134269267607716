import React from 'react';
import './SearchBar.css';
import {withTranslation} from "react-i18next";
import {commonService, userService} from "../../services";
import WhiteButton from "../button/WhiteButton";
import DateInput from "../input/DateInput";
import {popupAction} from "../../actions";
import {commonConstants, contentConstants, userConstants} from "../../constants";
import {connect} from "react-redux";
import Chip from "../chip/Chip";

const detailItems = [
    {id: 'creator', value: 'TEXT_CREATOR_P'},
    {id: 'content', value: 'TEXT_CONTENT_P'},
    {id: 'deviceGroup', value: 'TEXT_DEVICE_GROUP_P'},
    {id: 'contents', value: 'TEXT_CONTENT_P'},
    {id: 'organization', value: 'TABLE_ORGANIZATION_P'},
    {id: 'group', value: 'TEXT_GROUP_P'},
    {id: 'role', value: 'COM_TEXT_ROLE_P'},
    {id: 'date', value: 'COM_TEXT_MODIFY_DATE_P'},
];

const dateOptionItems = [
    {id: 'userDefine', value: 'MESSAGE_STATISTICS_MENU_USER_DEFINE_P'},
    {id: 'aWeekAgo', value: 'COM_MESSAGE_STATISTICS_MENU_A_WEEK_AGO_P'},
    {id: 'aMonthAgo', value: 'MESSAGE_STATISTICS_MENU_A_MONTH_AGO_P'},
    {id: 'threeMonthAgo', value: 'MIS_MESSAGE_STATISTICS_MENU_THREE_MONTH_AGO_P'},
    {id: 'sixMonthAgo', value: 'MIS_MESSAGE_STATISTICS_MENU_SIX_MONTH_AGO_P'},
    {id: 'aYearAgo', value: 'MIS_MESSAGE_STATISTICS_MENU_A_YEAR_AGO_P'},
];

class SearchDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            creator: '',
            organizationId: '',
            organizationName: '',
            groupId: '',
            groupName: '',
            roleId: '',
            roleName: '',
            date: {option: dateOptionItems[0].value, startDate: '', endDate: ''},

            isGroupSelectionDisabled: true,
            organizations: undefined,
            groups: undefined,
            roles: undefined,
            deviceGroups: undefined,
            contents: undefined,

            selectTitle: undefined,
            selectOptions: undefined,
            dateOptions: undefined,
        };
    }

    componentDidMount() {
        const {searchDetails = [], addPopup, closePopup} = this.props;

        searchDetails.map((detail) => {
            switch(detail.title) {
                case 'organization':
                    addPopup({id: 'SEARCH_DETAIL_POPUP_LOADING', type: commonConstants.LOADING_POPUP});
                    userService.fetchOrganizations().then(
                    data => {
                        this.parseOrganizations(data);

                    }
                ).finally(() => {
                        closePopup('SEARCH_DETAIL_POPUP_LOADING');
                    });
                    break;
                case 'group':
                    this.setState({groups: []});
                    break;
                case 'deviceGroup':
                    this.setState({deviceGroups: []});
                    break;
                case 'role':
                    commonService.fetchRole().then(
                        data => {
                            this.parseRoles(data);
                        }
                    );
                    break;
                case 'contents':
                    this.setState({contents: []});
                    break;
                default:
                    break;
            }
        });
    };

    parseOrganizations = (data) => {
        const {t} = this.props;
        let parsedOrganizations = [];

        if(data.items !== null && data.items.length > 0) {
            data.items.map((organization) => {
                parsedOrganizations.push({id: organization.groupId, value: organization.groupName});
            });

            this.setState({organizations: [...parsedOrganizations]});
        }
    };

    parseRoles = (data) => {
        let parsedRoles = [];

        if(data.items !== null && data.items.length > 0) {
            data.items.map((role) => {
                parsedRoles.push({id: role.roleId, value: role.roleName});
            });

            this.setState({roles: [...parsedRoles]});
        }
    };

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            ...this.state,
            [name]: value,
        });

        //this.onChangeDetail({...playlist, [name]: value});
    };

    onClickSearch = () => {
        this.props.onClickSearch({...this.state});

    };

    onClickSelectBtn = (e) => {
        const {organizations = [], groups, roles, selectTitle, selectOptions} = this.state;
        switch (e.target.id) {
            case detailItems[4].value :
                selectOptions === undefined ?
                    this.setState({selectTitle: e.target.id, selectOptions : [...organizations]}) : this.setState({selectOptions: undefined});
                break;
            case detailItems[5].value :
                selectOptions === undefined ?
                    this.setState({selectTitle: e.target.id, selectOptions : [...groups]}) : this.setState({selectOptions: undefined});
                break;
            case detailItems[6].value :
                selectOptions === undefined ?
                    this.setState({selectTitle: e.target.id, selectOptions : [...roles]}) : this.setState({selectOptions: undefined});
                break;
            default:
                this.setState({selectTitle: undefined, selectOptions: undefined});
                break;
        }
    };

    onClickSelectOption = (e, selectName) => {
        const {t} = this.props;

        switch(selectName) {
            case detailItems[4].value :

                if (parseInt(e.target.id) === userConstants.ORGAN_ID_ADMINISTRATOR) {
                    this.setState({
                        groupId: String(userConstants.ORGAN_ID_ADMINISTRATOR),
                        groupName: t('TEXT_ROOT_GROUP_NAME_P'),
                        organizationId: e.target.id,
                        organizationName: e.target.innerText,
                        isGroupSelectionDisabled: true
                    });

                } else {
                    this.setState({organizationId: e.target.id, organizationName: e.target.innerText, groupId: '', groupName: '', isGroupSelectionDisabled: false});
                }
                break;
            case detailItems[5].value :
                this.setState({groupId: e.target.id, groupName: e.target.innerText});
                break;
            case detailItems[6].value :
                this.setState({roleId: e.target.id, roleName: e.target.innerText});
                break;
            default:
                break;
        }
    };

    onClickDateOption = (e) => {
        const {date} = this.state;
        let selectOption = '';
        let currentDate = new Date();
        let selectStartDate = new Date();

        dateOptionItems.map((option) => {
           if(option.id === e.target.id) {
               selectOption = option.value;
           }
        });

        switch (e.target.id) {
            case 'aWeekAgo':
                selectStartDate.setDate(currentDate.getDate() - 7);
                break;
            case 'aMonthAgo':
                selectStartDate.setMonth(currentDate.getMonth() - 1);
                break;
            case 'threeMonthAgo':
                selectStartDate.setMonth(currentDate.getMonth() - 3);
                break;
            case 'sixMonthAgo':
                selectStartDate.setMonth(currentDate.getMonth() - 6);
                break;
            case 'aYearAgo':
                selectStartDate.setFullYear(currentDate.getFullYear() - 1);
                break;
            default:
                break;
        }
        const {toggleInternalPopupStatus} =  this.props;
        toggleInternalPopupStatus(false)

        this.setState({dateOptions: undefined, date:{...date, startDate: selectStartDate, endDate: currentDate, option: selectOption}});
    };

    handleDeviceGroupSelection = (groups) => {
        this.setState({...this.state, deviceGroups: groups});
        this.closePopup(commonConstants.COMMON_GROUP_POPUP)
    }

    handleGroupSelection = (param) => {

        if(!param || !param[0]) {
            return null;
        }

        if (param[0].groupId === param[0].organizationId) {
            return null;
        }

        const groupId = param[0].groupId;
        const groupName = param[0].groupName;
        this.setState({...this.state, showDetail: true, groupId: groupId, groupName: groupName});

        this.closePopup(commonConstants.ADD_USER_GROUP_SELECTION);

    };

    onClickGroupPopup = (id) => {
        const {addPopup, toggleInternalPopupStatus} = this.props;
        const {deviceGroups} = this.state;
        if (id === 'deviceGroup') {
            addPopup({
                type: commonConstants.COMMON_GROUP_POPUP,
                id: commonConstants.COMMON_GROUP_POPUP,
                mode: 'device',
                checkbox: true,
                checkedKeys: deviceGroups && deviceGroups.map(group => group.groupId),
                save: (groups)=>this.handleDeviceGroupSelection(groups),
                close: ()=>this.closePopup(commonConstants.COMMON_GROUP_POPUP)
            });
        } else if (id === 'contents') {
            addPopup({
                type: contentConstants.SELECT_CONTENT_POPUP,
                id: contentConstants.SELECT_CONTENT_POPUP,

                mode: 'content_playlist_filter',
                multiple: true,
                selectContent: this.handleContentsSelection,
                closeContentPopup: ()=>this.closePopup(contentConstants.SELECT_CONTENT_POPUP)
            });
        }

        toggleInternalPopupStatus(true);
    }

    closePopup = (id) => {
        const {closePopup, toggleInternalPopupStatus} = this.props;
        toggleInternalPopupStatus(false);
        closePopup(id)
    }

    handleContentsSelection = (contents) => {
        if (contents !== undefined && contents.length > 0) {
            this.setState({
                contents: contents
            })
            this.closePopup(contentConstants.SELECT_CONTENT_POPUP);
        }
    }

    handleDeleteDeviceGroup = (groupId) => {
        const {deviceGroups} = this.state;
        const {toggleInternalPopupStatus} = this.props;

        toggleInternalPopupStatus(true);
        this.setState({
            deviceGroups: deviceGroups.filter(deviceGroup => deviceGroup.groupId !== groupId)
        }, () => toggleInternalPopupStatus(false))
    }

    handleDeleteContentGroup = (id)  => {
        const {contents} = this.state;
        const {toggleInternalPopupStatus} = this.props;

        toggleInternalPopupStatus(true);
        this.setState({
            contents: contents.filter(content => content.selectedId !== id)
        }, () => toggleInternalPopupStatus(false))
    }

    onClickGroupSelectionPopup = (e) => {
        const {addPopup, toggleInternalPopupStatus} = this.props;

        addPopup({
            id: commonConstants.ADD_USER_GROUP_SELECTION,
            type: commonConstants.ADD_USER_GROUP_SELECTION,
            onSave: this.handleGroupSelection,
            onClose: () => {this.closePopup(commonConstants.ADD_USER_GROUP_SELECTION);},
            organizationId: parseInt(this.state.organizationId)
        });

        toggleInternalPopupStatus(true);
    };

    renderInput() {
        const {creator} = this.state;
        return (
            <div className="float_l" id="searchDetailPopup" style={{display: "inline-block", position: "relative"}}>
                <input type="text" id="creator" name="creator" value={creator}
                           style={{width: 250}} onChange={this.handleChange}/>
            </div>
       );
    };

    renderPopup(id, title) {
        const {t} = this.props;
        const {groupName, deviceGroups, contents} = this.state;

        if (id === 'group') {
            return (
                <div className="float_l" id="TABLE_ORGANIZATION_P"
                     style={{display: "inline-block", position: "relative"}}>
                    <button id="TABLE_ORGANIZATION_P" className="base un_radius select" value="0"
                            style={{width: "273px", overflow: "hidden"}} onClick={this.onClickGroupSelectionPopup}
                            disabled={this.state.isGroupSelectionDisabled}>
                        <span>{groupName === '' ? t('TEXT_SELECT_P') : groupName}<span className="arrow"></span></span>
                    </button>
                </div>
            )
        } else if (id === 'contents') {
            return (
                <div className="float_l" id="searchDetailPopup" style={{display: "inline-block", position: "relative"}}>
                    <button id="detailPopupBtn" className="circle_btn search float_l mr8" style={{borderRadius: "15px"}} onClick={()=>this.onClickGroupPopup(id)}/>
                    <div style={{float:'left', maxHeight:"100px", overflowY:"auto", marginTop:4}}>
                    {
                        id === 'contents' && contents !== undefined && contents.length > 0 &&
                        contents.map(
                            content  => <Chip width={40} height={20} title={content.selectedName} onDelete={()=>this.handleDeleteContentGroup(content.selectedId)}/>
                        )
                    }
                    </div>
                </div>
            )
        } else {
            return (
                <div className="float_l" id="searchDetailPopup" style={{display: "inline-block", position: "relative"}}>
                    <button id="detailPopupBtn" className="circle_btn search float_l mr8" style={{borderRadius: "15px"}} onClick={()=>this.onClickGroupPopup(id)}/>
                    <div style={{float:'left', maxHeight:"100px", overflowY:"auto", marginTop:4}}>
                    {
                        id === 'deviceGroup' && deviceGroups !== undefined && deviceGroups.length > 0 &&
                        deviceGroups.map(
                            deviceGroup  => <Chip width={40} height={20} title={deviceGroup.groupNameText !== undefined ? deviceGroup.groupNameText : deviceGroup.groupName} onDelete={()=>this.handleDeleteDeviceGroup(deviceGroup.groupId)}/>
                        )
                    }
                    </div>
                </div>
            )
        }

    };

    renderSelect(selectName, disable) {
        const {t} = this.props;
        const {selectTitle, selectOptions, organizationName, groupName, roleName} = this.state;
        let selectValue = '';

        switch(selectName) {
            case detailItems[4].value :
                selectValue = organizationName;
                break;
            case detailItems[5].value :
                selectValue = groupName;
                break;
            case detailItems[6].value :
                selectValue = roleName;
                break;
            default:
                selectValue = '';
                break;
        }

        return (
            <div className="float_l" id={selectName} style={{display: "inline-block", position: "relative"}}
                onClick={this.onClickSelectBtn}
            >
                <button id={selectName} className="base un_radius select" style={{width: "273px"}} value="0" disabled={disable}>
                    <span style={{width:'100%'}}>
                        <span id={selectName}  className="text_ellipsis" style={{width:'95%'}}>{selectValue !== '' ? selectValue : t("TEXT_SELECT_P")}</span>
                        <span className="arrow" style={{pointerEvents: 'none', position: 'absolute', right: '7px'}}></span>
                    </span>
                </button>
                <div className="option_list" style={{width: "273px", display: selectOptions && selectTitle === selectName ? '' : 'none'}} id={selectName}>
                    <div>
                        <ul>
                            {
                                selectOptions && selectOptions.map((option) => {
                                    return <li><a href="#" class={"select_option"} id={option.id} value={option.value} onClick={(e) => this.onClickSelectOption(e, selectName)}>{t(option.value)}</a></li>
                                })
                            }

                        </ul>
                    </div>
                </div>
            </div>
        );

    }

    showDateOption = () => {
        const {toggleInternalPopupStatus} = this.props;
        const {dateOptions} = this.state;
        if (dateOptions === undefined) {
            toggleInternalPopupStatus(true);
            this.setState({dateOptions: dateOptionItems});
        }else{
            this.setState({dateOptions : undefined})
        }
    };

    blurDateOption = () => {
        this.setState({dateOptions : undefined})
    };

    updateDate = (type, date) => {
        const {toggleInternalPopupStatus} = this.props;
        toggleInternalPopupStatus(true);
        if (type === 'start') {
            this.setState({date:{ ...this.state.date, startDate: date}}, ()=> toggleInternalPopupStatus(false))
        } else if (type === 'end') {
            this.setState({date:{ ...this.state.date, endDate: date}}, ()=> toggleInternalPopupStatus(false))
        }
    };

    renderDate() {
        const {t} = this.props;
        const {date, dateOptions} = this.state;

        return (
                <div className="float_l" id="searchDetailPopup" style={{display: "inline-block", position: "relative"}}>
                    <table style={{margin: "0 0 10px 0"}}>
                        <tbody>
                            <tr>
                                <td style={{padding: 0}}>
                                    <button id="detailDateTypeBtn" className="base un_radius select" style={{width: "209px"}} value="0"
                                            onClick={this.showDateOption} onBlur={this.blurDateOption}>
                                        <span>
                                            {t(date.option)}
                                            <span className="arrow"></span>
                                        </span>
                                    </button>
                                    <div className="option_list" style={{display: dateOptions === undefined ? "none" : ''}} id="periodList">
                                        <div>
                                            <ul>
                                                {
                                                    dateOptions && dateOptions.map((option) => {
                                                        return <li><a href="#" id={option.id} value={option.value} onMouseDown={this.onClickDateOption}>{t(option.value)}</a></li>
                                                    })
                                                }

                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr style={{marginTop: "10px"}}>
                                <td style={{padding: "10px 0 0 0", display: "inline-flex", width: "100%"}}>
                                    <div className="search_detail_date">
                                        <DateInput date={(date.option === '' || date.startDate == '') ? '' : new Date(date.startDate)}
                                                   width={122}
                                                   maxDate={date.option === '' ? '' : new Date(date.endDate)}
                                                   disabled={date.option !== 'MESSAGE_STATISTICS_MENU_USER_DEFINE_P'}
                                                   onChange={(selectDate) => this.updateDate('start', selectDate)}
                                        />
                                    </div>
                                    <span className="space mr10 float_l">&nbsp;~&nbsp;</span>
                                    <div className="search_detail_date">
                                        <DateInput date={(date.option === '' || date.endDate == '')? '' : new Date(date.endDate)}
                                                   width={122}
                                                   minDate={date.option === '' ? '' : new Date(date.startDate)}
                                                   disabled={date.option !== 'MESSAGE_STATISTICS_MENU_USER_DEFINE_P'}
                                                   onChange={(selectDate) => this.updateDate('end', selectDate)}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
    }

    render() {
        const {t, searchDetails = []} = this.props;

        return (
            <div className="search_detail_popup">
                <div className="title">
                    <span>{t("BUTTON_DETAIL_P")}</span>
                </div>
                <div className="clearfix">
                    <table>
                        <colgroup>
                            <col width="110px"/>
                            <col width="273px"/>
                        </colgroup>
                        <tbody>
                        {
                            searchDetails.map((detailItem) => {
                                let title = '';
                                let id = '';
                                let disable = detailItem.disable !== undefined ? detailItem.disable : false;

                                detailItems.map((item) => {
                                   if(item.id === detailItem.title) {
                                       title = item.value;
                                       id = item.id;
                                   }
                                });

                                let type = '';
                                switch(detailItem.type) {
                                    case 'input':
                                        type = this.renderInput();
                                        break;
                                    case 'popup':
                                        type = this.renderPopup(id, title);
                                        break;
                                    case 'select':
                                        type = this.renderSelect(title, disable);
                                        break;
                                    case 'date':
                                        type = this.renderDate();
                                        break;
                                    default:
                                        break;
                                }
                                return (
                                    <tr>
                                        <th style={{paddingTop: "13px", verticalAlign: "top"}}>{t(title)}</th>
                                        <td>
                                            {type}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
                <div className="searchButtonWrap" >
                    <WhiteButton
                        id={"SEARCH_DETAIL_SEARCH"}
                        name={t("BUTTON_SEARCH")}
                        className="search_btn"
                        width="102px"
                        onClick={this.onClickSearch}
                    />
                </div>
            </div>
        );
    }
}

export default connect(
    null,
    dispatch => ({
        addPopup: (id)=>dispatch(popupAction.addPopup(id)),
        closePopup: (id)=>dispatch(popupAction.closePopup(id))
    })

)(withTranslation()(SearchDetail));
