import {userConstants} from '../constants';
import {userService} from '../services';
import {alertActions} from '../actions';
import {history} from '../helper';
import {isNil} from 'lodash';

export const userActions = {
    login,
    logout,
    loginWithCode,
    initOtp,
    success
};

function login(username, password) {
    return dispatch => {
        dispatch(request({username}));
        userService.login(username, password)
            .then(
                user => {
                    dispatch(success(user));
                    history.push('/');
                },
                error => {

                    if(isNil(error) || isNil(error.body) || isNil(error.body.errorCode) ){
                        dispatch(failure(error));
                        return;
                    }

                    const mfa = {
                        userId: username,
                        password: password
                    };

                    switch(error.body.errorCode){
                        case '401011' :
                            dispatch(initOtp({...mfa,
                                type: userConstants.LOGIN_SETUP_OTP,
                                otpAuthInfo: error.body.errorDetails.otpAuthInfo}));
                            break;
                        case '401010' :
                            dispatch(initOtp({...mfa,
                                type: userConstants.LOGIN_REQUIRED_OTP}));
                            break;
                        case '401012' :
                            localStorage.removeItem('device_' + username);

                            dispatch(initOtp({...mfa,
                                type: userConstants.LOGIN_REQUIRED_OTP, isRemoveTrustedDevice:true}));
                            break;
                        default :
                            dispatch(failure(error));
                            dispatch(alertActions.error(error.toString()));
                            break;


                    }
                }
            );
    };

}

function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }

function loginWithCode(params){
    return dispatch => {
        // dispatch(request({ username }));
        userService.loginWithCode(params)
            .then(
                user => {
                    dispatch(success(user));
                    history.push('/');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    // function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    /*userService.logout();
    history.push('/login');*/
    return { type: userConstants.LOGOUT };
}

function initOtp(mfa) {

    return { type: userConstants.LOGIN_INIT_OTP, mfa };
}
