import React, {useRef, useState} from 'react'
import FilterImage from '../../../images/bg/filter_arrow.png';
import {useTranslation} from "react-i18next";
import {useOutsideClick} from "../../../helper";
import './TutorialPopup.css';
import {popupAction} from "../../../actions";
import {useDispatch} from "react-redux";
import {commonConstants} from "../../../constants";

const TutorialPopup = () => {

    const tutorials = window.globalConfig.tutorial || {};

    const [show, setShow] = useState(false);
    const {t} = useTranslation();
    const ref = useRef();
    const dispatch = useDispatch();

    useOutsideClick(ref, ()=> {
        if (show){
            setShow(false)
        }
    })

    const showPopup = () => {
        setShow(!show);
    }

    const handleTutorial = (tutorial) => {
        window.open(tutorial.url, "_blank");
    }

    return (
        <div className={'tutorial_popup'}>
            <button type="button" id="tutorialBtn" className="mr8" onClick={showPopup} ></button>
            {
                tutorials !== undefined && tutorials.length > 0 &&
                    <>
                        <div className={'dim'} style={{display: show ? '':'none'}}></div>
                        <div className="tutorial_wrap" style={{display: show ? '':'none', zIndex: 101}} ref={ref}>
                            <div>
                            <span>
                                <img src={FilterImage}/>
                            </span>
                                <h3>{t("COM_WATCH_TUTORIAL_VIDEO")}</h3>
                                <div className="clearfix">
                                    <div className="user_detail">
                                        <ul id="tutorialMovieList">
                                            {
                                                tutorials.map(
                                                    (t, index) =>
                                                        <li key={index}><span onClick={()=>handleTutorial(t)}>{t.text}</span></li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </div>

    )
}
export default TutorialPopup;