import React, {Component, Fragment} from "react";
import './DashboardPanel.css'
import './DeviceSummary.css'
import {SortableHandle} from "react-sortable-hoc";
import {connect} from "react-redux";
import {menuAction} from "../../actions";
import {withTranslation} from "react-i18next";
import errorIcon from '../../images/icon/widget_ic_devi_error.png';
import warningIcon from '../../images/icon/widget_ic_devi_warning.png';
import resolvedIcon from '../../images/icon/widget_ic_devi_resolved.png';
import {MoonLoader} from "react-spinners";
import {toastr} from 'helper/toastrIntercept';
import {dashboardService} from "../../services";
import {getErrorMessage} from "../../helper/responseHandler";

class DeviceSummary extends Component {

    state = {
        device: {},
        loading: true,
    };

    constructor(props) {
        super(props);
        this.onAllDeviceClickEvent = this.onAllDeviceClickEvent.bind(this);
        this.onUnApprovalDeviceClickEvent = this.onUnApprovalDeviceClickEvent.bind(this);
        this.onErrorStatusClickEvent = this.onErrorStatusClickEvent.bind(this);
        this.onWarningStatusClickEvent = this.onWarningStatusClickEvent.bind(this);
        this.onResolvedStatusClickEvent = this.onResolvedStatusClickEvent.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
    }

    componentDidMount() {
        this.fetchGetDeviceInfo();
    }

    async onAllDeviceClickEvent() {
        const {removeTab, moveTab, t} = this.props;
        removeTab('DEVICE');
        moveTab({id: 'DEVICE', title : t("TEXT_DEVICE_P"), active: true, close: true});
    }

    async onUnApprovalDeviceClickEvent() {
        const {removeTab, moveTab, t} = this.props;
        removeTab('DEVICE');
        moveTab({id: 'DEVICE', title : t("TEXT_DEVICE_P"), active: true, close: true}, 'DEVICE', 'UNAPPROVED_DEVICE');
    }

    async onErrorStatusClickEvent() {
        const {removeTab, moveTab, t} = this.props;
        removeTab('DEVICE');
        moveTab({id: 'DEVICE', title : t("TEXT_DEVICE_P"), active: true, close: true}, 'DEVICE_HEALTH', 'DEVICE_HEALTH_ERROR');
    }

    async onWarningStatusClickEvent() {
        const {removeTab, moveTab, t} = this.props;
        removeTab('DEVICE');
        moveTab({id: 'DEVICE', title : t("TEXT_DEVICE_P"), active: true, close: true}, 'DEVICE_HEALTH', 'DEVICE_HEALTH_WARNING');
    }

    async onResolvedStatusClickEvent() {
        const {removeTab, moveTab, t} = this.props;
        removeTab('DEVICE');
        moveTab({id: 'DEVICE', title : t("TEXT_DEVICE_P"), active: true, close: true}, 'DEVICE_HEALTH', 'DEVICE_HEALTH_RESOLVED');
    }

    fetchGetDeviceInfo = () => {
        this.setState({loading: true,});
        Promise.all([
            dashboardService.fetchCurrentDeviceConnectionInfo(),
            dashboardService.fetchDeviceHealthInfo(),
        ]).then(res => {
            this.setState({
                device: Object.assign(res[0].items, res[1].items),
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally(() => {this.setState({loading: false});});
    };

    render() {
        let {t} = this.props;
        let {device, loading} = this.state;
        return (
            <div className='dashboard-widget'>
                {this.renderHeader()}
                <div className='dashboard-widget-body'>
                    {loading && <MoonLoader css={`position: absolute;top: calc(50% - 40px);left: calc(50% - 40px);`} color="#6e78c8"/>}
                    {!loading &&
                    <Fragment>
                        <div className='device-summary'>
                            <div className='device-summary-connected' style={{width: (device.nonApprovalCount !== undefined ? '33%':'50%')}} onClick={this.onAllDeviceClickEvent}>
                                {device.connectionCount}<span>{t("COM_MIS_TEXT_CONNECTED_P")}</span>
                            </div>
                            <div className='device-summary-disconnected' style={{width: (device.nonApprovalCount !== undefined ? '33%':'50%')}} onClick={this.onAllDeviceClickEvent}>
                                {device.disconnectionCount}<span>{t("MIS_TEXT_DISCONNECTED_P")}</span>
                            </div>
                            {device.nonApprovalCount !== undefined  &&
                            <div className='device-summary-unapproved' onClick={this.onUnApprovalDeviceClickEvent}>
                                {device.nonApprovalCount}<span>{t("COM_TV_SID_UNAPPROVED")}</span>
                            </div>
                            }
                        </div>
                        <div className="device-health">
                            <div className="device-health-info">
                                <ul>
                                    <li onClick={this.onErrorStatusClickEvent}>
                                        <div style={{float: 'left'}}><img src={errorIcon} style={{marginRight: '9px'}}/></div>
                                        <div className='title'>{t("COM_TEXT_TITLE_ERROR_P")}</div>
                                        <div className='count' style={{color: '#ee392f'}}>{device.error}</div>
                                    </li>
                                    <li onClick={this.onWarningStatusClickEvent}>
                                        <div style={{float: 'left'}}><img src={warningIcon} style={{marginRight: '9px'}}/></div>
                                        <div className='title'>{t("TEXT_TITLE_WARNING_P")}</div>
                                        <div className='count' style={{color: '#ffa41f'}}>{device.warning}</div>
                                    </li>
                                    <li onClick={this.onResolvedStatusClickEvent}>
                                        <div style={{float: 'left'}}><img src={resolvedIcon} style={{marginRight: '9px'}}/></div>
                                        <div className='title'>{t("MIS_SID_RESOLVED")}</div>
                                        <div className='count' style={{color: '#0081c6'}}>{device.resolved}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Fragment>
                    }
                </div>
            </div>
        )
    };

    renderHeader() {
        let {id, isEditMode, t} = this.props;
        const DragHandle = SortableHandle(() =>
            <div className='title'>{t("TEXT_DEVICE_SUMMARY_P_KR_MIS20")}</div>
        );
        return (
            <div className='dashboard-widget-header'>
                <DragHandle/>
                {
                    isEditMode && <div title="Close" className="remove" onClick={() => this.props.onRemoveLayoutClickEvent(id)}></div>
                }
            </div>
        );
    }
}

export default connect(
    null,
    dispatch => ({
        removeTab: (id) => dispatch(menuAction.removeTab(id)),
        moveTab: (tab, id, childrenId) => dispatch(menuAction.moveTab(tab, id, childrenId)),
    })
)(withTranslation()(DeviceSummary));
