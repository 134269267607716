import classNames from "classnames";
import React from "react";
import {APlayer, DefaultPlayer, Flip, IPlayer, LitePlayer, RMPlayer, SPlayer, WPlayer} from "../components/icon";
import i18n from '../language/i18n'
import {isNil} from "lodash";


export function jsonToTree(json) {
    let map = {}, node, roots = [], i;
    for (i = 0; i < json.length; i += 1) {
        map[json[i].groupId] = i;
        json[i].children = [];
    }
    for (i = 0; i < json.length; i += 1) {
        node = json[i];
        if (json[i].groupId === 0) {
            json[i].groupName = i18n.t('TEXT_ROOT_GROUP_NAME_P');
        }
        node.key = json[i].groupId;
        node.name = json[i].groupName;
        node.isOrganization = node.description === 'Organization' ? true :false;
        roots.push(node);
    }

    return roots;
}

export function jsonToTreeWithChildren(json, groupDepth = 1, useAdminTitle = true) {

    json.sort(function (a, b){
        return a.groupDepth - b.groupDepth
    });

    let map = {}, node, roots = [], i;

    for (i = 0; i < json.length; i += 1) {
        map[json[i].groupId] = i;
        json[i].children = [];
    }

    for (i = 0; i < json.length; i++) {
        node = json[i];
        if (useAdminTitle && json[i].groupId === 0) {
            json[i].groupName = i18n.t('TEXT_ROOT_GROUP_NAME_P');
        }
        node.key = node.groupId;
        node.name = node.groupName;
        node.isOrganization = node.description === 'Organization' ? true :false;
        if (node.groupDepth > groupDepth ) {
            try {
                json[map[node.parentGroupId]].children.push(node);
            } catch (e) {
                ;
            }
        } else {
            roots.push(node);
        }
    }

    return roots;
}

export const Icon = (mode, group, disabled, showPlayerIcon = true) => {
    //const PlayerIcon = getPlayerType(mode, group.priority) || '';
    return (
        <span
            className={classNames(
                'customize-icon',
                {
                    'organization': (group.groupDepth === 1 && group.description === 'Organization') || group.type === 'ORGANIZATION',
                }
            )}
        >
            {
                showPlayerIcon &&  (group.description !== 'Organization' || group.groupDepth > 1) && (group.type === undefined || group.type !== 'ORGANIZATION') &&
                <PlayerType mode={mode} priority={group.priority} disabled={disabled}/>
            }
        </span>
    )
}

const PlayerType = ({mode, priority, disabled}) => {
    if (mode !== 'device')
        return (
            <DefaultPlayer disabled={disabled}/>
        )

    switch (priority) {
        case -1 :
            return (
                <APlayer disabled={disabled} deviceTypeVersion={1}/>
            )
        case -2 :
            return (
                <WPlayer disabled={disabled} deviceTypeVersion={1}/>
            )
        case -3:

            return (
                <RMPlayer deviceType={'RIPLAYER'} deviceTypeVersion={1} disabled={disabled}/>
            )
        case -50 :
            return (
                <Flip disabled={disabled} deviceTypeVersion={1}/>
            )
        case -48:
            return (
                <Flip deviceTypeVersion={2} disabled={disabled}/>
            )
        case -100:
            return (
                <RMPlayer deviceType={'RSPLAYER'} deviceTypeVersion={4} disabled={disabled} popup={true}/>
            )
        case -98:
            return (
                <RMPlayer deviceType={'RSPLAYER'} deviceTypeVersion={5} disabled={disabled} popup={true}/>
            )
        case -96:
            return (
                <RMPlayer deviceType={'RSPLAYER'} deviceTypeVersion={6} disabled={disabled} popup={true}/>
            )
        case 1:
            return (
                <LitePlayer disabled={disabled}/>
            )
        case 1000:
        case 2000:
        case 5:
            return (
                <SPlayer deviceTypeVersion={1} disabled={disabled}/>
            )
        case 10:
            return (
                <SPlayer deviceTypeVersion={2} disabled={disabled}/>
            )
        case 20:
            return (
                <SPlayer deviceTypeVersion={3} disabled={disabled}/>
            )
        case 50:
            return (
                <SPlayer deviceTypeVersion={4} disabled={disabled}/>
            )
        case 60:
            return (
                <SPlayer deviceTypeVersion={5} disabled={disabled}/>
            )
        case 70:
            return (
                <SPlayer deviceTypeVersion={6} disabled={disabled}/>
            )
        case 80:
            return (
                <SPlayer deviceTypeVersion={7} disabled={disabled}/>
            )
        case 90:
            return (
                <SPlayer deviceTypeVersion={8} disabled={disabled}/>
            )
        case 110:
            return (
                <IPlayer disabled={disabled}/>
            )
        default :
            return (
                <DefaultPlayer disabled={disabled}/>
            )
    }
}

const Title = (node, isMainMenu = false, parentGroupList) => {
    return (
        <>
            <span title={parentGroupList !== undefined ? parentGroupList : node.groupName}>{node.groupName}</span>
            {
                (isMainMenu || node.groupDepth > 1) &&
                <span className={'group_count'}>{isMainMenu ? node.resourceCount : node.deviceCount}</span>
            }
        </>
    )
}

export function jsonToTreeForDeviceAfterSearch(mode, json, tagAssign = false, isIncludeOrganization = true) {
    let node, roots = [], i, checked = [];
    for (i = 0; i < json.length; i++) {
        node = json[i];
        node.key = String(node.groupId);

        let parentGroupList = undefined;
        if (node.parentsGroupIds) {
            parentGroupList = node.parentsGroupIds.map(group => group.groupName).join(' > ');
            if (mode !== 'device') {
                parentGroupList += ' > '+node.groupName;
            }
        }
        node.title = Title(node, false, parentGroupList);

        if (!isIncludeOrganization) {
            if (node.description === 'Organization') {
                continue;
            }
        }

        if (node.status !== undefined && node.status.selected) {
            checked.push(String(node.key));
            node.checkable = true;
        }
        if (node.status !== undefined && node.status.disabled) {
            node.disabled = true;
            node.disableCheckbox = true;
        }
        if (tagAssign && (node.type === 'LPLAYER' || node.type === 'FLIP' || node.type === 'FLIP2'|| node.type === 'SPLAYER')) {
            node.disabled = true;
            node.disableCheckbox = true;
        }
        node.icon = Icon(mode, node, node.disabled);
        roots.push(node);
    }
    return {
        treeData: roots,
        defaultCheckedKeys: checked
    }
}

export function jsonToTreeForDevice(json, checkedKeys, allowSelectOrganization, allEnabled = false, tagAssign = false, selectAllUse = false) {
    let map = {}, node, roots = [], i, checked = [], groups = [], allCount = 0;
    for (i = 0; i < json.length; i += 1) {
        map[json[i].groupId] = i;
        json[i].children = [];
    }
    for (i = 0; i < json.length; i++) {
        node = json[i];
        node.key = String(node.groupId);
        node.title = Title(node);

        if ((node.status !== undefined && node.status.selected) ||
            !isNil(checkedKeys) && checkedKeys.includes(node.groupId)) {
            //checked.push(String(node.key));
            checked.push(node.groupId);
            groups.push({groupName: node.groupName, groupId: node.groupId});
            node.checkable = true;
        }
        if (!allEnabled && node.status !== undefined && node.status.disabled) {
            node.disabled = true;
            node.disableCheckbox = true;
        } else {
            allCount = allCount+1;
        }

        if(allowSelectOrganization && node.type === 'ORGANIZATION') {
            node.disabled = false;
        }
        if (tagAssign && (node.type === 'LPLAYER' || node.type === 'FLIP' || node.type === 'FLIP2'|| node.type === 'SPLAYER')) {
            node.disabled = true;
            node.disableCheckbox = true;
        }

        node.icon = Icon('device', node, node.disabled);

        if (node.groupDepth > 1) {
            json[map[node.parentGroupId]].children.push(node);
        } else {
            if(!selectAllUse) {
                node.disableCheckbox = true;
            }
            roots.push(node);
        }

    }

    return {
        treeData: roots,
        defaultCheckedKeys: checked,
        selected: groups,
        allCount : allEnabled && allowSelectOrganization ? groups.length : allCount
    }
}

export function jsonToSubmenuTreeForDevice(json) {
    let map = {}, node, roots = [], i;
    for (i = 0; i < json.length; i += 1) {
        map[json[i].groupId] = i;
        json[i].children = [];
    }
    for (i = 0; i < json.length; i++) {
        node = json[i];
        node.key = node.groupId;
        node.name = node.groupName;
        node.title = Title(node, true);
        node.icon = Icon('device', node, node.disabled, false);
        node.depth = node.groupDepth;

        node.isOrganization = node.type === 'ORGANIZATION' ? true : false;

        if (node.groupDepth > 1) {
            json[map[node.parentGroupId]].children.push(node);
        } else {
            node.disableCheckbox = true;
            roots.push(node);
        }
    }

    return roots;
}

export function jsonToTreeForCategory(json) {
    let map = {}, node, roots = [], i;
    for (i = 0; i < json.length; i += 1) {
        map[json[i].groupId] = i;
        json[i].children = [];
    }
    for (i = 0; i < json.length; i += 1) {
        node = json[i];
        node.key = json[i].groupId;
        node.name = json[i].groupName;

        if (node.groupDepth > 0 ) {
            try {
                json[map[node.pGroupId]].children.push(node);
            } catch (e) {
                ;
            }
        } else {
            roots.push(node);
        }
    }
    return roots;
}

export function jsonToTreeForContentByUser(json) {
    let map = {}, node, roots = [], i;

    if(json[0].groupName === 'ROOT'){
        let rootItem = json[0];
        json = json.filter(item => item.groupName !== 'ROOT');
        rootItem.groupName = i18n.t('TEXT_ROOT_GROUP_NAME_P');
        json[json.length] = rootItem;
    }

    for (i = 0; i < json.length; i += 1) {
        map[json[i].groupId] = i;
        json[i].children = [];
    }
    for (i = 0; i < json.length; i += 1) {
        node = json[i];
        node.key = json[i].organizationId;
        node.name = json[i].groupName;
        node.organizationId = json[i].organizationId;
        roots.push(node);
    }
    return roots;
}

export function jsonToTreeForUserWithChildren(organizationJson, userJson, groupJson, groupDepth = 1) {
    let groupMap = {}, userMap = [], orgMap = [], node, roots = [], i;

    if(organizationJson[0].groupName === 'ROOT'){
        let rootItem = organizationJson[0];
        organizationJson = organizationJson.filter(item => item.groupName !== 'ROOT');
        rootItem.groupName = i18n.t('TEXT_ROOT_GROUP_NAME_P');
        organizationJson[organizationJson.length] = rootItem;
    }

    groupJson.sort(function (a, b){
        return a.groupDepth - b.groupDepth
    });

    for (i = 0; i < groupJson.length; i += 1) {
        groupMap[groupJson[i].groupId] = i;
        groupJson[i].children = [];
    }

    for (i = 0; i < userJson.length; i += 1) {
        userMap[userJson[i].userId] = i;
        userJson[i].children = [];
    }

    for (i = 0; i < organizationJson.length; i += 1) {
        orgMap[organizationJson[i].organizationId] = i;
        organizationJson[i].children = [];
    }

    for (i = 0; i < groupJson.length; i++) {
        node = groupJson[i];
        node.key = node.groupId;
        node.name = node.groupName;
        node.isOrganization = node.description === 'Organization';
        if (node.groupDepth > groupDepth ) {
            try {
                groupJson[groupMap[node.parentGroupId]].children.push(node);
            } catch (e) {
                ;
            }
        } else {
            userJson[userMap[node.creatorId]].children.push(node);
        }
    }

    for (i = 0; i < userJson.length; i++) {
        node = userJson[i];
        node.key = node.userId;
        node.name = node.userId;
        node.isOrganization = node.description === 'Organization';
        organizationJson[orgMap[node.organizationId]].children.push(node);
    }

    for (i = 0; i < organizationJson.length; i += 1) {
        node = organizationJson[i];
        node.key = node.organizationId;
        node.name = node.groupName;
        roots.push(node);
    }

    return roots;
}

export function jsonToTreeForPlaylistByMePopup(json) {

    json.sort(function (a, b){
        return a.groupDepth - b.groupDepth
    });

    let map = {}, node, roots = [], i;

    for (i = 0; i < json.length; i += 1) {
        map[json[i].groupId] = i;
        json[i].children = [];
    }

    for (i = 0; i < json.length; i++) {
        node = json[i];
        node.key = node.groupId;
        node.name = node.groupName;

        if (node.groupDepth > 1 ) {
            try {
                json[map[node.parentGroupId]].children.push(node);
            } catch (e) {
                ;
            }
        } else {
            roots.push(node);
        }
    }
    return roots;
}

export function jsonToTreeForPlaylistByMe(json) {

    json.sort(function (a, b){
        return a.groupDepth - b.groupDepth
    });

    let map = {}, node, roots = [], i;

    for (i = 0; i < json.length; i += 1) {
        map[json[i].groupId] = i;
        json[i].children = [];
    }

    for (i = 0; i < json.length; i++) {
        node = json[i];
        node.key = node.groupId;
        node.name = node.groupName;

        if (node.groupDepth > 1 ) {
            try {
                json[map[node.parentGroupId]].children.push(node);
            } catch (e) {
                ;
            }
        } else {
            roots.push(node);
        }
    }
    return roots;
}


export function jsonToTreeForDatalinkTable(json) {

    let map = {}, node, roots = [], i;

    for (i = 0; i < json.length; i += 1) {
        map[json[i].id] = i;
        json[i].children = [];
    }

    for (i = 0; i < json.length; i += 1) {
        node = json[i];
        node.key = json[i].id;
        node.name = json[i].text;

        if (json[i].parent !== "#") {
            json[map[node.parent]].children.push(node);
        } else {
            roots.push(node);
        }
    }
    return roots;
}

export function jsonToTreeForShareContent(json) {
    let map = {}, node, roots = [], i;
    for (i = 0; i < json.length; i += 1) {
        map[json[i].groupId] = i;
        json[i].children = [];
    }
    for (i = 0; i < json.length; i += 1) {
        node = json[i];
        node.key = json[i].groupId;
        node.name = json[i].groupName;
        roots.push(node);
    }

    return roots;
}

export function jsonToTreeForUser(json) {
    json.sort(function (a, b){
        return a.groupDepth - b.groupDepth
    });

    let map = {}, node, roots = [], i;

    if(json[0].groupName === 'ROOT'){
        let rootItem = json[0];
        json = json.filter(item => item.groupName !== 'ROOT');
        rootItem.groupName = i18n.t('TEXT_ROOT_GROUP_NAME_P');
        json[json.length] = rootItem;
    }

    for (i = 0; i < json.length; i += 1) {
        map[json[i].groupId] = i;
        json[i].children = [];
    }

    for (i = 0; i < json.length; i += 1) {
        node = json[i];
        node.key = json[i].groupId;
        node.name = json[i].groupName;
        node.isOrganization = node.responseDataType === 'USER_ORGANIZATION';

        if (node.groupDepth > 1 ) {
            try {
                json[map[node.parentGroupId]].children.push(node);
            } catch (e) {
                ;
            }
        } else {
            roots.push(node);
        }
    }

    return roots;
}

export function jsonToTreeForSoldToCode(json) {
    let map = {}, node, roots = [], i;

    for (i = 0; i < json.length; i += 1) {
        map[json[i].soldToCode] = i;
        json[i].children = [];
    }

    for (i = 0; i < json.length; i++) {
        node = json[i];

        node.key = node.soldToCode;
        node.groupId = node.soldToCode;
        node.name = node.accountNm;
        node.modelCd = node.modelCd;
        node.secorgId = node.secorgId;
        if (node.parentSoldToCode !="" ) {
            try {
                json[map[node.parentSoldToCode]].children.push(node);
            } catch (e) {
                ;
            }
        } else {
            roots.push(node);
        }
    }

    return roots;
}

export function jsonToTreeForBrandCode(json) {
    let node,  roots = [], i;


    for (i = 0; i < json.length; i++) {
        node = json[i];

        node.key = node.accountCode;
        node.groupId = node.accountCode;
        node.name = '('+ node.brandNm +') '+node.accountNm;
        node.modelCd = node.modelCd;
        node.brandCode = node.brandCode;
        node.children = [];
        roots.push(node);
    }
    return roots;
}
