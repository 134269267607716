import React, {useEffect, useMemo, useState} from 'react';
import "../../components/table/react-table.css";
import WhiteButton from "../../components/button/WhiteButton";
import Checkbox from "../../components/checkbox/Checkbox";
import {Trans, useTranslation} from 'react-i18next';
import {userService} from "../../services";
import Pagination from "../../components/table/Pagination";
import {commonConstants, contentConstants} from "../../constants";
import {connect} from "react-redux";
import {menuAction, popupAction} from "../../actions";
import {toastr} from 'helper/toastrIntercept';
import './User.css'
import DeviceRelativeTimeCell from "../../components/device/DeviceRelativeTimeCell";
import {useMISOpt} from "../../components/misopt";
import {getPageSize, useCheckRefWithSelectedCnt as useCheckRef, useFilter} from "../../helper";
import {getErrorMessage} from "../../helper/responseHandler";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {useTrGroupProps} from "../../helper/tables";
import {updateCache} from "../../helper/cache/tableCache";

const LDAPOrganization = (props) => {
    const {t} = useTranslation();
    const {getAuthority} = useMISOpt();
    const userAuthority = getAuthority("USER");

    const sortColumns = [
        {id: 'organizationName', value: 'org_name'},
        {id: 'groupName', value: 'group_name'},
        {id: 'remoteLdapOrganization', value: 'ldap_group'},
        {id: 'roleName', value: 'role_name'},
        {id: 'lastSyncTime', value: 'last_sync_time'},
    ];

    const [style, setStyle] = useState({
        height: '762px'
    });

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setState] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
    });

    const {items = [], loading = false, totalCount = 0} = data;
    const {page, pageSize, sorted} = filter;
    const {addPopup, closePopup, reloadGroups} = props;

    const [checkAll, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        }
    }, []);

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched) {
            fetchGetLDAPOrganizationList();
        }
    }, [filter]);

    const updateDimensions = () => {
        let height = window.innerHeight - 204;
        setStyle({
            ...style,
            height: height
        })
    };

    const addLDAPOrganization = () => {
        addPopup({
            type: commonConstants.ADD_LDAP_ORGANIZATION,
            id: commonConstants.ADD_LDAP_ORGANIZATION,
            onClose: () => closePopup(commonConstants.ADD_LDAP_ORGANIZATION),
            onSave: () => {
                closePopup(commonConstants.ADD_LDAP_ORGANIZATION);
                fetchGetLDAPOrganizationList();
            }
        });
    };

    const editLDAPOrganization = () => {
        let ldapId = items[selected.current[0]].ldapId;

        addPopup({
            type: commonConstants.EDIT_LDAP_ORGANIZATION,
            id: commonConstants.EDIT_LDAP_ORGANIZATION,
            ldapId: ldapId,
            onSave: () => {
                closePopup(commonConstants.EDIT_LDAP_ORGANIZATION);
                fetchGetLDAPOrganizationList();
            },
            onClose: () => closePopup(commonConstants.EDIT_LDAP_ORGANIZATION)
        });
    };

    const deleteLDAPOrganization = () => {
        addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("MIS_SID_20_DELETE_LDAP_SYNC"),
            message: t("ALERT_WANT_DELETE"),
            width: 310,
            height: 130,
            onClickYes: () => {
                closePopup(commonConstants.COMMON_CONFIRM_POPUP);
                fetchDeleteLDAPOrganization();
            },
            onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP),
        });
    };

    const syncLDAPOrganization = () => {
        fetchSyncLDAPOrganizations();
    };

    const columns = useMemo(() => [
        {
            id: "checkbox",
            Header: () => {
                return (
                    <Checkbox
                        id={'ALL'}
                        classname={"table"}
                        name={"check"}
                        ref={checkAll}
                        onChange={toggleSelectAll}
                    />
                )
            },
            Cell: (row) => {
                return (
                    <Checkbox id={items[row.index].ldapId}
                              classname="table"
                              name="check"
                              index={row.index}
                              onChange={toggleRow}
                              ref={setCheckBoxRefs}
                    />
                )
            },
            width: 40,
            resizable: false,
            sortable: false
        },
        {
            Header: t("TABLE_ORGANIZATION_P"),
            accessor: "organizationName",
            Cell: (props) => <span className={"list_column_data"} title={props.original.organizationName}>{props.original.organizationName}</span>,
            width: 120,
        },
        {
            Header: t("TEXT_GROUP_P"),
            accessor: 'groupName',
            Cell: (props) => <span className={"list_column_data"} title={props.original.groupName}>{props.original.groupName}</span>,
            width: 120,
        },
        {
            Header: t("MIS_TEXT_LDAP_ORGAN_P"),
            accessor: 'remoteLdapOrganization',
            Cell: (props) => <span className={"list_column_data"} title={props.original.remoteLdapOrganization}>{props.original.remoteLdapOrganization}</span>,
            width: 296,
        },
        {
            Header: t("COM_TEXT_ROLE_P"),
            accessor: 'roleName',
            Cell: (props) => <span className={"list_column_data"} title={props.original.roleName}>{props.original.roleName}</span>,
            width: 170,
        },
        {
            Header: t("MIS_SID_20_TIME_OF_LAST_SYNC"),
            accessor: 'lastSyncTime',
            Cell: (props) => props.original.lastSyncTime ? <DeviceRelativeTimeCell value={props.original.lastSyncTime}/> : <span className={"list_column_data"}>-</span>,
            resizable: false,
        }
    ], [items]);

    const fetchGetLDAPOrganizationList = () => {
        const {page, pageSize, sorted} = filter;
        userService.fetchLDAPOrganizationsFilter({
            startIndex: page === 0 ? page + 1 : page * pageSize + 1,
            pageSize: pageSize,
            sortOrder: sorted[0].desc ? 'DESC' : 'ASC',
            sortColumn: sortColumns.find(column => column.id === sorted[0].id).value,
        }).then(res => {
            let result = [];
            res.items.forEach((item) => {
                result.push({
                    ...item,
                    isCheck: false,
                });
            })
            setState({
                ...data,
                isCheckAll: false,
                isEditable: false,
                isDeletable: false,
                isSyncable: false,
                loading: false,
                items: result,
                totalCount: res.totalCount,
            });
            updateCache('USER', {items: result, filter: filter, totalCount: res.totalCount})
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    const fetchDeleteLDAPOrganization = () => {
        let ldapIds = selected.current.map(key => items[key].ldapId);

        userService.deleteLDAPOrganizations({ldapOrganizationIds: ldapIds}).then(res => {
            toastr.success(t('ALERT_SUCCESS_DELETE'));
            let pageMin = page * Number(pageSize);
            let deletedTotalCount = totalCount - res.items.successList.length;
            let deletePage = page;
            if(deletedTotalCount <= pageMin) {
                deletePage = page - 1 < 0 ? 0 : page - 1;
            }
            setFilter({...filter, page: deletePage});
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    const fetchSyncLDAPOrganizations = () => {
        let ldapIds = selected.current.map(key => items[key].ldapId);

        userService.syncLDAPOrganizations({ldapOrganizationIds: ldapIds}).then(res => {
            toastr.success(t('TEXT_SUCCESS_P'));
            fetchGetLDAPOrganizationList();
            reloadGroups("USER_BY_GROUP");
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    }

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow,'ldap_tr_group');

    const {CREATE = false, MANAGE = false} = userAuthority;
    return (
        <div style={{width: '100%', height: '100%', display: props.currContent === 'SYNC_LDAP_ORGANIZATION' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"ADD"} name={t("COM_BUTTON_ADD")} disable={false} onClick={addLDAPOrganization} authority={CREATE || MANAGE}/>
                    <WhiteButton id={"EDIT"} name={t("COM_BUTTON_EDIT")} disable={selectedCnt !== 1} onClick={editLDAPOrganization} authority={CREATE || MANAGE}/>
                    <WhiteButton id={"DELETE"} name={t("COM_BUTTON_DELETE")} disable={selectedCnt < 1} onClick={deleteLDAPOrganization} authority={CREATE || MANAGE}/>
                    <WhiteButton id={"SYNC"} name={t("COM_TEXT_SYNC_P")} disable={selectedCnt < 1} onClick={syncLDAPOrganization} authority={CREATE || MANAGE}/>
                </div>
            </div>
            <div className='ldap_list_view'>
                <MagicInfoTable
                    data={items}
                    loading={loading}
                    sorted={sorted}
                    minRows={0}
                    showPagination={false}
                    columns={columns}
                    pageSize={items.length}
                    onSortedChange={onSortedChange}
                    className="-striped -highlight"
                    style={style}
                    getTbodyProps={() => {
                        return {
                            className: 'ldap_table_body',
                        };
                    }}
                    getTrGroupProps={getTrGroupPropsType2}
                    getTdProps={() => {
                        return {
                            className: 'ldap_td_props'
                        };
                    }}/>
                <Pagination totalCount={totalCount}
                            page={page}
                            Pagination={pageSize}
                            pageSizeOptions={contentConstants.PAGE_SIZE_OPTIONS}
                            onPageChange={onPageChange}
                            onPageSizeChange={onPageSizeChange}
                            divide={props.divide}/>
            </div>
        </div>
    );
};

export default connect(
    state => ({
        menu: state.menu
    }),
    dispatch => ({
        openDetailView: (type) => dispatch(popupAction.openDetailView(type)),
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
        reloadGroups: (submenuId, groupId) => dispatch(menuAction.reloadGroup(submenuId, groupId)),
    })
)(LDAPOrganization);

