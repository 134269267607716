import React, {memo, useEffect, useMemo, useState} from 'react';
import WhiteButton from "../../components/button/WhiteButton";
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import SupportedDeviceIcon from "../../components/icon/SupportedDeviceIcon";
import "../../components/table/react-table.css";
import {useDispatch} from "react-redux";
import {commonConstants, RULESET_PAGE_SIZE_OPTIONS} from "../../constants";
import {useTranslation} from "react-i18next";
import {rulesetService} from '../../services/ruleset.service';
import {menuAction, popupAction} from "../../actions";
import {toastr} from 'helper/toastrIntercept';
import GroupsPopup from '../../components/popup/GroupsPopup';
import {useCheckRefWithSelectedCnt as useCheckRef, useFilter} from '../../helper';
import {getErrorMessage} from '../../helper/responseHandler';
import {useMISOpt} from '../../components/misopt';
import {snakeCase} from "lodash";
import DeviceRelativeTimeCell from '../../components/device/DeviceRelativeTimeCell';
import MagicInfoTable from '../../components/table/MagicInfoTable';
import {useTrGroupProps} from "../../helper/tables";
import {getMixString} from '../../language/languageUtils';
import {updateCache} from "../../helper/cache/tableCache";

const RulesetRecycleBin = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {getAuthority} = useMISOpt();
    const [authority, setAuthority] = useState({});

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
    });

    const [popups, setPopups] = useState({
        rulesetGroupPopup: false,
    });

    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0, pages = 0} = data;
    const {page, pageSize, sorted} = filter;

    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const fetchData = (reloadGroup = false) => {
        const {page, pageSize, keyword, sorted: [{id, desc}]} = filter;
        setData({...data, loading: true});
        rulesetService.fetchRulesetFilter({
            startIndex: (page * pageSize) + 1,
            pageSize,
            searchText: keyword,
            isDeleted: true,
            sortColumn: snakeCase(id).toUpperCase(),
            sortOrder: desc ? 'DESC' : 'ASC',
            includeTemp: true
        }).then(res => {
            setData({...data, loading: false, items: res.items, totalCount: res.totalCount});
            if(reloadGroup) {
                dispatch(menuAction.reloadGroup('RULESET_BY_GROUP'));
            }
            updateCache('RULESET', {items: res.items, filter: filter, totalCount: res.totalCount})
        });
    };

    const restoreRuleset = (groupId) => {
        const rulesetIds = getCheckedId();

        rulesetService.fetchRestoreRuleset({rulesetIds, groupId})
            .then(res => {
                toastr.success(t('COM_TEXT_SUCCESS_P'));
                fetchData(true);
            })
            .catch(e => {
                toastr.error(getErrorMessage(e));
            })
            .finally();
    };

    const deleteRuleset = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("COM_BUTTON_DELETE"),
            message: t("MESSAGE_CONTENT_CONFIRM_DELETE_CONTENT_P"),
            onClickYes: handleDelete,
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
        }));
    };

    const handleDelete = () => {
        const rulesetIds = getCheckedId();

        const params = {
            deleteMethod: 'DELETE_PERMANENTLY',
            rulesetIds: rulesetIds
        }

        rulesetService.fetchDeleteRuleset(params)
            .then(res => {
                toastr.success(t('ALERT_SUCCESS_DELETE'));
                fetchData();
            })
            .catch(e => {
                toastr.error(getErrorMessage(e));
            })
            .finally(dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)));
    };

    const handleEmptyRecycleBin = () => {
        rulesetService.emptyRecycleBin()
            .then(res => {
                toastr.success(t('ALERT_SUCCESS_DELETE'));
                fetchData();
            })
            .catch(e=> {
                toastr.error(getErrorMessage(e))
            })
            .finally(dispatch(popupAction.closePopup("EMPTY_RULESET_RECYCLE_BIN_POPUP")));
    };

    const emptyRecycleBinPopup = () => {
        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: "EMPTY_RULESET_RECYCLE_BIN_POPUP",
            title: t("BUTTON_EMPTY_RECYCLE_BIN_P"),
            message: t("MESSAGE_CONTENT_CONFIRM_EMPTY_RECYCLE_BIN_P"),
            onClickYes: () => handleEmptyRecycleBin(),
            onClose: () => dispatch(popupAction.closePopup("EMPTY_RULESET_RECYCLE_BIN_POPUP"))
        }));
    };

    useEffect(() => {
        setStyle({height: window.innerHeight - 204});
        setAuthority(getAuthority('CONTENT_PLAYLIST_RULESET'));
    }, []);

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched) {
            fetchData();
        }
    }, [filter]);

    const getCheckedId = () => {
        return selected.current.map(s => items[s].rulesetId);
    }

    const showRulesetGroupPopup = () => {
        setPopups({...popups, rulesetGroupPopup: !popups.rulesetGroupPopup});
    }

    const columns = useMemo(()=>[
        {
            accessor: "rulesetId",
            show: false
        },
        {
            id: "checkbox",
            width: 46,
            sortable: false,
            Header:() => {
                return (
                    <Checkbox
                        id={'RulesetRecycleBin_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={items[row.index].rulesetId}
                        index={row.index}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs}
                    />
                )
            },
            resizable: false
        },
        {
            Header: t("LIST_TITLE_NAME"),
            accessor: "name",
            Cell: props => (
                <span title={props.original.name}>
                    {props.original.name}
                </span>
            ),
            width: 400
        },
        {
            Header: t("COM_MAPP_SID_SUPPORTED_DEVICES"),
            Cell: props => <SupportedDeviceIcon deviceType={props.original.deviceType} deviceTypeVersion={props.original.deviceTypeVersion}/>,
            width: 150,
            sortable : false
        },
        {
            Header: t("TEXT_CREATOR_P"),
            accessor: "creator",
            width: 150
        },
        {
            Header: t("COM_TEXT_MODIFY_DATE_P"),
            accessor: "modifyDate",
            Cell: ({value}) => <DeviceRelativeTimeCell value={value} />
        }
    ], [items]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow);

    return (
        <div style={{width: '100%',display: props.currContent === 'RULESET_RECYCLE_BIN' ? 'block':'none' }}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id="rulesetRestore" name={t("BUTTON_RESTORE_P")} disable={selectedCnt < 1} authority={authority.CREATE || authority.MANAGE} onClick={()=>showRulesetGroupPopup()}/>
                </div>
                <div className="leftButton">
                    <WhiteButton id="rulesetDelete" name={t("COM_BUTTON_DELETE")} disable={selectedCnt < 1} authority={authority.CREATE || authority.MANAGE} onClick={()=>deleteRuleset()}/>
                </div>
                <div className="leftButton">
                    <WhiteButton id={"rulesetDeleteAll"} name={t("COM_BUTTON_DELETE_ALL_P")} onClick={()=>emptyRecycleBinPopup()} authority={authority.CREATE || authority.MANAGE}/>
                </div>
                <div className="rightButton">
                    <SearchBar id="rulesetSearch" placeholder={getMixString(["MIS_SID_MIX_NAME", "MIS_SID_RULESET"])} onClickSearch={onKeywordChange} />
                </div>
            </div>
            { popups.rulesetGroupPopup &&
                <GroupsPopup
                    mode="ruleset"
                    checkbox={false}
                    close={() => showRulesetGroupPopup()}
                    save={(groups) => {
                        try {
                            if(groups !== undefined && groups.length > 0) {
                                showRulesetGroupPopup();
                                restoreRuleset(groups[0].groupId);
                            }
                            else {
                                toastr.error(t('MESSAGE_COMMON_SELECT_GROUP_P'));
                            }
                        }
                        catch(e) {
                            toastr.error(t("MESSAGE_SCHEDULE_PROGRAM_ERROR_P"));
                        }
                    }}/>
            }
            <MagicInfoTable
                data={items}
                loading={loading}
                sorted={sorted}
                onSortedChange={onSortedChange}
                style={style}
                columns={columns}
                noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                getTrGroupProps={getTrGroupPropsType2}
                usePagination={true}
                paginationOptions={{
                    totalCount: totalCount,
                    page: page,
                    pageSize: pageSize,
                    pageSizeOptions: RULESET_PAGE_SIZE_OPTIONS,
                    onPageChange: onPageChange,
                    onPageSizeChange: onPageSizeChange,
                    divide: props.divide
                }}
            />
        </div>
    )
}

export default memo(RulesetRecycleBin);