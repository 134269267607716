import React, {useRef, useState} from 'react';
import uuid from "uuid";
import moment from "moment-timezone";
import Moment from "moment";
import i18n from '../../language/i18n';
import {CONTENT_SCHEDULE_PROGRAM_TYPE_AD, inputSources} from "../../constants";
import {isNumber} from "lodash";
import {useOutsideClick} from "../hooks";
import {getMixString} from "../../language/languageUtils";
import EMPTY_IMAGE from "../../images/img/empty_img_5.png";

export const isValidExpirationDate = (event) => {
    const {expirationDate} = event;
    if (expirationDate) {
        if (Moment().startOf('day') > Moment(expirationDate,'YYYYMMDD')) {
            return true;
        }
    }
    return false;
}

export const DeviceGroups = ({deviceGroups, width}) => {
    return (
        <>
            {
                deviceGroups.length < 3 ?
                    <ul>
                        {
                            deviceGroups.map(group => <li key={group.groupId} title={group.groupName}><a href={'#'} >{group.groupName}</a></li>)
                        }
                    </ul>
                    :
                    <MoreDeviceGroups deviceGroups={deviceGroups} width={width}/>
            }
        </>
    )
}

const MoreDeviceGroups = ({deviceGroups, width}) => {
    const [show, setShow] = useState(false);
    const ref = useRef();
    useOutsideClick(ref, ()=> {
        setShow(false);
    })
    const showDeviceGroups = (e) => {
        e.stopPropagation();
        setShow(!show);
    }
    return (
        <ul ref={ref}>
            {
                deviceGroups[0] !== undefined &&
                <li key={deviceGroups[0].groupId} title={deviceGroups[0].groupName}><a href={'#'}>{deviceGroups[0].groupName}</a></li>
            }
            {
                deviceGroups[1] !== undefined &&
                <li key={deviceGroups[1].groupId} title={deviceGroups[1].groupName}><a href={'#'}>{deviceGroups[1].groupName}</a></li>
            }
            {
                deviceGroups[2] !== undefined &&
                <li key={deviceGroups[2].groupId} title={deviceGroups[2].groupName}><a href={'#'}>{deviceGroups[2].groupName}</a></li>
            }
            <li>
                <button className="list_more_wrap" onClick={showDeviceGroups}></button>
                <div className="list_more" style={{display: !show ? 'none' : '', width: width}}>
                    <div style={{overflowY:'scroll', height:177}}><span></span>
                        <ul style={{overflow:'hidden',height:'auto'}}>
                            {
                                deviceGroups.map(group => <li key={group.groupId} title={group.groupName}><a href={'#'}>{group.groupName}</a></li>)
                            }
                        </ul>
                    </div>
                </div>
            </li>
        </ul>
    )

}

export const createUUID = () => {
    return uuid().toLowerCase();
}
export const simpleConvertSchedule = (originEvent) => {
    const event = Object.assign({}, originEvent);
    if (event.repeatType === 'ONCE') {
        event.durationInSeconds =  (Moment(event.endDate+' '+event.endTime).toDate().getTime() - Moment(event.startDate+' '+event.startTime)) / 1000;
    } else {
        event.durationInSeconds =  (Moment(event.startDate+' '+event.endTime).toDate().getTime() - Moment(event.startDate+' '+event.startTime)) / 1000;
    }
    return event;
}

export const convertSchedule = (originEvent) => {
    const event = Object.assign({}, originEvent);

    if (event.repeatType === 'ONCE') {
        const startMoment  = Moment(event.start);
        const endMoment = Moment(event.end);
        event.startDate = startMoment.format('YYYY-MM-DD');
        event.endDate = endMoment.format('YYYY-MM-DD');
        event.durationInSeconds =  (event.end.getTime() - event.start.getTime()) / 1000;
        event.startTime =  startMoment.format('HH:mm:ss');

    } else {
        event.startDate = event.ranges.start.format('YYYY-MM-DD');
        event.endDate = event.ranges.end.format('YYYY-MM-DD');
        event.startTime = event.startTime;

        const startTimeArray = event.startTime.split(':');
        const endTimeArray = event.endTime.split(':');
        const startTime = Moment().set({'hour': startTimeArray[0], minute: startTimeArray[1], second: startTimeArray[2]}).toDate();
        const endTime = Moment().set({'hour': endTimeArray[0], minute: endTimeArray[1], second: endTimeArray[2]}).toDate();
        event.durationInSeconds =  (endTime.getTime() - startTime.getTime()) / 1000;

        event.daysOfWeek = undefined;
        event.groupId = undefined;
    }

    event.start = undefined;
    event.end = undefined;
    event.backgroundColor = undefined;
    event.id = undefined;
    return event;
}

export const getRepeatedDayOfWeek = (weekList) => {
    const weekly = [];
    for (const week of weekList) {
        switch (week) {
            case 'MON':
                weekly.push(1);
                break;
            case 'TUE':
                weekly.push(2);
                break;
            case 'WED':
                weekly.push(3);
                break;
            case 'THU':
                weekly.push(4);
                break;
            case 'FRI':
                weekly.push(5);
                break;
            case 'SAT':
                weekly.push(6);
                break;
            case 'SUN':
                weekly.push(0);
                break;
        }
    }
    return weekly;
}

export const inputSourceToString = (contentId) => {
    if (contentId !== undefined && contentId !== '') {
        const found = inputSources.find(inputSource => inputSource.value === contentId)
        if (found !== undefined) {
            return found.text;
        }
    }
    return contentId;
}

export const convertMessageScheduleFromServer = (messageFromServer) => {
    const messages = Object.assign({}, messageFromServer);
    messages.messageList.map(
        message => message.endTime = Moment(message.startDate+' '+message.startTime).add(message.duration, 'seconds').format('HH:mm:ss')
    )
    return messages;
}


export const convertContentScheduleFromServer = (programFromServer) => {

    const convertEvent = (event) => {
        event.title = inputSourceToString(event.contentName);
        const start = moment(event.startDate+' '+event.startTime);
        const end = moment(event.endDate+' '+event.startTime).add(event.durationInSeconds, 's');
        if (event.repeatType !== 'ONCE') {
            event.ranges = {start: start.clone(), end : end.clone()};
            event.startRecur = event.startDate;
            if(event.isHW) {
                event.endRecur = '3000-01-01';
            }
            else {
                event.endRecur = end.clone().add(1, 'days').format('YYYY-MM-DD');
            }
            event.daysOfWeek = event.repeatedDayOfWeekList !== undefined && event.repeatedDayOfWeekList.length > 0 ? getRepeatedDayOfWeek(event.repeatedDayOfWeekList): [0,1,2,3,4,5,6];
            event.endTime = end.format('HH:mm:ss');
            event.groupId = event.scheduleId;
        } else {
            event.startTime = undefined;
            event.endTime = undefined;
            event.start = start.toDate();
            event.end = end.toDate();
        }

        event.id = event.scheduleId;
        event.startDate = undefined;
        event.durationInSeconds = undefined;
    }
    let program = Object.assign({}, programFromServer);

    if (program.deviceType === 'IPLAYER') {
        program.deviceType = 'iPLAYER';
    }

    program.channels.map(
        channel => {
            if (channel.frame.events === undefined) {
                channel.frame.events = [];
            } else {
                channel.frame.events !==undefined && channel.frame.events.length > 0 && channel.frame.events.map(
                    event => {
                        convertEvent(event);
                    }
                )
            }
            channel.frame.frames !== undefined && channel.frame.frames.length > 0 && channel.frame.frames.map(
                frame => {
                    if (frame.events === undefined) {
                        frame.events = [];
                    } else {
                        frame.events !== undefined && frame.events.map(
                            event => {
                                convertEvent(event);
                            }
                        )
                    }
                }
            )
            channel.frame.adSlots !== undefined && channel.frame.adSlots.map(
                slot => {
                    if (slot.events === undefined) {
                        slot.events = [];
                    }
                }
            )
        }
    )
    if (program.deployReservation === undefined) {
        program.deployReservation = {};
    }
    return program;
}

export const validThumbnailId = (id) => {

    const regExpUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    const regExpDefaultThumbnail = /^[A-Z]*_+[A-Z]*/;
    //check uuid
    if (id !== undefined && id !== null) {
        return id.match(regExpUUID) || id.match(regExpDefaultThumbnail);
    }
    return false;
}

export const updatePublishStatus = (programId, percent, completeDeviceCount, totalDeviceCount) => {
    let statusElement = document.querySelector('#publish_status_' + programId);
    if(statusElement != null) {
        statusElement.innerHTML = '<span class="status_wrap"><span style="width: ' + percent + '%; background: #66ca84;"></span></span>'
            + `${completeDeviceCount}/${totalDeviceCount} ` + i18n.t('TEXT_DEVICE_P');
    }
}

export const getTotalContentSize = (programType, channels) => {
    let totalSize = 0;
    if (channels !== undefined && channels.length > 0) {
        if (programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD) {
            if (channels !== undefined && channels.length > 0 && channels[0].frame !== undefined && channels[0].frame.adSlots !== undefined) {
                channels[0].frame.adSlots.map(
                    slot => {
                        if (slot.events !== undefined && slot.events.length > 0) {
                            slot.events.map(
                                event => {
                                    if (event.fileSize) {
                                        const fileSize = parseInt(event.fileSize, 10);
                                        if (isNumber(fileSize) && fileSize > 0) {
                                            totalSize += fileSize;
                                        }
                                    }
                                }
                            )
                        }
                    }
                )
            }
        } else {
            channels.map(
                channel => {
                    channel.frame !== undefined && channel.frame.events !== undefined && channel.frame.events.length > 0 &&
                    channel.frame.events.map(
                        event => {
                            if (event.fileSize) {
                                const fileSize = parseInt(event.fileSize, 10);
                                if (isNumber(fileSize) && fileSize > 0) {
                                    totalSize += fileSize;
                                }
                            }
                        }
                    )
                    channel.frame !== undefined && channel.frame.frames !== undefined && channel.frame.frames.events !== undefined && channel.frame.frames.events.length > 0 &&
                    channel.frame.frames.events.map(
                        event => {
                            if (event.fileSize) {
                                const fileSize = parseInt(event.fileSize, 10);
                                if (isNumber(fileSize) && fileSize > 0) {
                                    totalSize += fileSize;
                                }
                            }
                        }
                    )
                }
            )
        }
    }

    return totalSize;
}

export const deviceTypeVersionToInt = (deviceTypeVersion) => {
    return parseInt(deviceTypeVersion, 10);
}

export const renderNodata = () => {
    return (
        <div className='no_data_wrap'>
            <div>
                <img src={EMPTY_IMAGE} style={{marginTop:260}}/>
                    <span className="empty_title">{i18n.t('TEXT_TITLE_SCHEDULE_P')}</span>
                    <span>{getMixString(["MIS_SID_MIX_ADD_YOUR", "TEXT_TITLE_SCHEDULE_P"])}</span>
            </div>
        </div>
    );
}

export const getFirstDayOfWeek = ()=>{
    let firstDay=0;
    try{
        var userData=JSON.parse(localStorage.getItem('user'));        
        if(userData!==null)
        {   
            if(userData.scheduleFirstDay!==undefined)
            {
                firstDay=userData.scheduleFirstDay
            }
        }
    }catch(e)
    {
        console.log("[Schedule Utils] Error is getting first day of week, setting default 0, error:",e);
    }
    return parseInt(firstDay,10);
};