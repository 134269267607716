import {_axios as axios} from "../helper/axiosIntercepts";
import {
    authHeader,
    handleBinDownloadResponse,
    handleDownloadResponse,
    handleResponse,
    history,
    objToQueryString
} from '../helper';
import {fetchForDownload, fetchWithToken as fetch} from '../helper/fetchIntercept.js';
import {merge} from "lodash";
import store from '../store';
import {setExclusive} from "../helper/fetchUtils";

const backendHost = window.globalConfig.backendHost || process.env.REACT_APP_BACKEND_URL;

export const deviceService = {
    fetchDevices,
    deleteDevices,
    fetchDeviceFilter,
    exportDeviceFilter,
    fetchDeviceTimeFilter,
    fetchDeviceSetupFilter,
    fetchDeviceDisplayFilter,
    fetchDeviceSecurityFilter,
    fetchDeviceById,
    fetchDeviceTagsById,
    updateDeviceTags,
    fetchDeviceMemoById,
    updateDeviceMemo,
    deleteDeviceMemo,
    fetchDeviceGeneralInfo,
    updateDeviceGeneralInfo,
    fetchGeneralInfoRequestStatus,
    fetchDeviceTimeInfo,
    fetchDeviceCurrentTimeInfo,
    updateDeviceTimeInfo,
    fetchTimeInfoRequestStatus,
    fetchDeviceSetupInfo,
    fetchDeviceCurrentSetupInfo,
    updateDeviceSetupInfo,
    getDeviceCurrentSetupInfo,
    fetchSetupInfoRequestStatus,
    fetchDeviceDisplayInfo,
    fetchDeviceCurrentDisplayInfo,
    updateDeviceDisplayInfo,
    fetchDisplayInfoRequestStatus,
    fetchDeviceSecurityInfo,
    fetchDeviceCurrentSecurityInfo,
    updateDeviceSecurityInfo,
    fetchSecurityInfoRequestStatus,
    fetchDeviceStatus,
    exportDeviceStatus,
    fetchDeviceContentDownload,
    fetchDeviceGroups,
    fetchDeviceSearchedGroups,
    fetchDeviceGroupById,
    createDeviceGroup,
    updateDeviceGroup,
    moveDeviceGroup,
    deleteDeviceGroup,
    fetchDeviceConnectionCheck,
    approveDevices,
    updateDeviceApproval,
    exportUnapprovedDevices,
    exportUnapprovedDevicesGroupCode,
    fetchDeviceScheduleCheck,
    fetchDeviceScheduleUpcomingExpires,
    exportDeviceScheduleUpcomingExpires,
    fetchDeviceUpcomingExpires,
    exportDeviceUpcomingExpires,
    fetchDevicePlaylistToExpires,
    exportDevicePlaylistToExpires,
    fetchDeviceTimezoneCheck,
    fetchDeviceStorageCheck,
    exportNofifications,
    fetchDeviceRegisteredSoftware,
    fetchDeviceRegisteredSoftwareById,
    fetchDeviceReservedSoftware,
    fetchDeviceWarningRules,
    fetchDeviceRemoteJobs,
    fetchDeviceCancelRemoteJobs,
    fetchDeviceAddRemoteJobs,
    fetchDeviceReuseRemoteJobs,
    fetchDeviceEditRemoteJobs,
    fetchDevicePresets,
    fetchDevicePresetFilter,
    createDevicePreset,
    updateDevicePreset,
    copyDevicePreset,
    deleteDevicePreset,
    fetchDevicePreset,
    fetchDevicePresetDeployStatus,
    fetchDevicePresetResult,
    fetchDevicePresetSetting,
    createDeviceWarningRules,
    updateDeviceWarningRulesByRuleId,
    updateWarningRuleGroups,
    deleteDeviceWarningRules,
    fetchDeviceWarningRulesByRuleId,
    fetchDeviceWarningRuleElements,
    quickControlDevice,
    fetchDeviceLedCabinets,
    fetchDeviceLedCabinetsByIds,
    fetchDeviceLedCabinet,
    updateDeviceLedCabinet,
    sendLedCabinetCommand,
    scanLedCabinet,
    fetchLedCabinetLayout,
    fetchLedCabinetWarningRule,
    exportLedCabinets,
    fetchCurrentLedCabinet,
    fetchCurrentLedCabinetStatus,
    checkDeviceApproval,
    moveDevice,
    fetchDeviceTypes,
    fetchDeviceModels,
    createSoftware,
    fetchSoftwareForEdit,
    fetchSoftwareForDeploy,
    updateSoftwareForDeploy,
    cancelSoftwareDeploy,
    updateSoftware,
    deleteSoftware,
    exportSoftware,
    fetchSoftwareForPublish,
    publishSoftware,
    retryPublishSoftware,
    fetchSoftwareStatus,
    exportSoftwareStatus,
    searchGroupByName,
    fetchSignageSlaveInfo,
    updateSignageSetting,
    scanChildDevices,
    fetchSignageLayout,
    downloadSoftware,
    downloadCustomize,
    requestNetworkMode,
    updateExpirationDate,
    updateDeviceType,
    fetchDeviceTypeConversionStatus,
    fetchDeviceVnc,
    fetchDeviceRMServerVnc,
    replaceVwlDevice,
    updateDeviceLogProcess,
    fetchDeviceCollectedLogs,
    downLoadDeviceCollectedLogs,
    deleteDeviceCollectedLogs,
    fetchDeviceSystemUsage,
    deployDeviceSchedule,
    updateDeviceChannel,
    fetchDeviceWarningErrorInfo,
    fetchDeviceWarningErrorHistory,
    fetchBackupPlayChecking,
    fetchBackupMode,
    saveBackupConfig,
    deleteBackupConfig,
    fetchDeviceConnectionCheckByGroupId,
    fetchVwlLayoutInfoByGroupId,
    cancelVwlLayoutByGroupId,
    previewVwlLayoutByGroupId,
    fetchDeviceImage,
    fetchDeviceFlipInfo,
    updateFlipDeviceGeneralInfo,
    fetchFlipDeviceGeneralInfoRequest,
    fetchFlipDeviceStatusRequest,
    fetchFlipDeviceAllStatusRequest,
    fetchAvailableFunctions,
    sendPostBoot,
    fetchTagsByDeviceGroups,
    fetchDeviceContentDownloadStatus,
    fetchServerStatus,
    fetchReplacementCheck,
    fetchLicenseUsage,
    sendAnalyticsEnable,
    sendAnalyticsCancel,
};

function fetchDevices(state) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    let obj ={
        pageSize : state.pageSize,
        startIndex : state.pages,
    }

    let params = objToQueryString(obj);
    return fetch(backendHost+'/restapi/v2.0/rms/devices'+params, requestOptions).then(handleResponse);
}

function deleteDevices(ids) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/deleted-devices`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(ids),
    }).then(handleResponse);
}

function fetchDeviceFilter(filter = {startIndex: 1, pageSize: 30}) {
    const requestOptions = setExclusive({
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(filter)
    })
    return fetch(backendHost+'/restapi/v2.0/rms/devices/filter', requestOptions).then(handleResponse);
}

function exportDeviceFilter(type, filter = {startIndex: 1, pageSize: 30}, category = 'DEVICE_INFORMATION') {
    return fetchForDownload(`${backendHost}/restapi/v2.0/rms/devices/filter/export?exportType=${type}&category=${category}`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(filter),
    }).then(handleBinDownloadResponse);
}

function fetchDeviceTimeFilter(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/time/filter`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(filter),
    }).then(handleResponse);
}

function fetchDeviceSetupFilter(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/setting/filter`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(filter),
    }).then(handleResponse);
}

function fetchDeviceDisplayFilter(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/display/filter`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(filter),
    }).then(handleResponse);
}

function fetchDeviceSecurityFilter(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/security/filter`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(filter),
    }).then(handleResponse);
}

function fetchDeviceById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(backendHost+'/restapi/v2.0/rms/devices/'+id, requestOptions).then(handleResponse);
}

function fetchDeviceTagsById(id, type) {
    const params = objToQueryString({type});
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${id}/tags${params}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function updateDeviceTags(tagInfo) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/tags`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(tagInfo),
    }).then(handleResponse);
}

function fetchDeviceMemoById(id) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${id}/memo`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function updateDeviceMemo(id, memo) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${id}/memo`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify({memo}),
    }).then(handleResponse);
}

function deleteDeviceMemo(id) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${id}/memo`, {
        method: 'DELETE',
        headers: authHeader('DELETE'),
    }).then(handleResponse);
}

function fetchDeviceGeneralInfo(deviceIds) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/general-info`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({ids: deviceIds})
    }).then(handleResponse);
}

function updateDeviceGeneralInfo(generalInfo) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/current-general-info`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(generalInfo),
    }).then(handleResponse);
}

function fetchGeneralInfoRequestStatus(deviceIds, requestId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/current-general-info`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({deviceIds, requestId})
    }).then(handleResponse);
}

function fetchDeviceTimeInfo(deviceIds) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/time-info`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({ids: deviceIds})
    }).then(handleResponse);
}

function fetchDeviceCurrentTimeInfo(deviceIds) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/current-time-info/request-ticket`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({ids: deviceIds})
    }).then(handleResponse);
}

function updateDeviceTimeInfo(timeInfo) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/current-time-info`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(timeInfo),
    }).then(handleResponse);
}

function fetchTimeInfoRequestStatus(deviceIds, requestId, step) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/current-time-info`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({deviceIds, requestId, step})
    }).then(handleResponse);
}

function fetchDeviceSetupInfo(deviceIds) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/setup-info`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({ids: deviceIds})
    }).then(handleResponse);
}

function fetchDeviceCurrentSetupInfo(deviceIds) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/current-setup-info/request-ticket`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({ids: deviceIds})
    }).then(handleResponse);
}

function updateDeviceSetupInfo(setupInfo) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/current-setup-info`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(setupInfo),
    }).then(handleResponse);
}

function fetchSetupInfoRequestStatus(deviceIds, requestId, isRequestStatistics) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/current-setup-info`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({deviceIds, requestId, isRequestStatistics})
    }).then(handleResponse);
}

function getDeviceCurrentSetupInfo(deviceIds, callback, repeatCount = 30, repeatPeriod= 1000) {
    let timer, checkCount = 0;

    return fetchDeviceCurrentSetupInfo(deviceIds).then(res => {
        const {requestId} = res.items;
        if(timer) {
            clearInterval(timer);
        }
        timer = setInterval(() => {
            fetchSetupInfoRequestStatus(deviceIds, requestId).then(res => {
                if(res.status === 'Fail') {
                    throw 'Fail';
                } else {
                    clearInterval(timer);
                    timer = null;
                    checkCount = 0;
                    callback && callback(res);
                }
                
            }).catch(error => {
                checkCount++;
                if(checkCount >= repeatCount) {
                    clearInterval(timer);
                    timer = null;
                    checkCount = 0;
                    callback && callback('Timeout');
                }
            });
        }, repeatPeriod);
    });
}


function fetchDeviceDisplayInfo(deviceIds) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/display-info`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({ids: deviceIds})
    }).then(handleResponse);
}

function fetchDeviceCurrentDisplayInfo(deviceIds) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/current-display-info/request-ticket`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({ids: deviceIds})
    }).then(handleResponse);
}

function updateDeviceDisplayInfo(displayInfo) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/current-display-info`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(displayInfo),
    }).then(handleResponse);
}

function fetchDisplayInfoRequestStatus(deviceIds, requestId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/current-display-info`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({deviceIds, requestId})
    }).then(handleResponse);
}

function fetchDeviceSecurityInfo(deviceIds) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/security-info`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({ids: deviceIds})
    }).then(handleResponse);
}

function fetchDeviceCurrentSecurityInfo(deviceIds) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/current-security-info/request-ticket`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({ids: deviceIds})
    }).then(handleResponse);
};

function updateDeviceSecurityInfo(securityInfo) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/current-security-info`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(securityInfo),
    }).then(handleResponse);
}

function fetchSecurityInfoRequestStatus(deviceIds, requestId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/current-security-info`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({deviceIds, requestId})
    }).then(handleResponse);
}

function fetchDeviceGroups() {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchDeviceSearchedGroups(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/searched-groups`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(filter),
    }).then(handleResponse);
}

function fetchDeviceGroupById(id) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups/${id}/sub-groups`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function createDeviceGroup(group) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(group)
    }).then(handleResponse);
}

function updateDeviceGroup(groupId, group) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups/${groupId}`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(group)
    }).then(handleResponse);
}

function moveDeviceGroup(groupId, destinationGroupId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups/${groupId}/destination-groups/${destinationGroupId}`, {
        method: 'PUT',
        headers: authHeader('PUT'),
    }).then(handleResponse);
}

function deleteDeviceGroup(groupId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups/${groupId}`, {
        method: 'DELETE',
        headers: authHeader('DELETE'),
    }).then(handleResponse);
}

function fetchDeviceStatus(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/status${objToQueryString(filter)}`, setExclusive({
        method: 'GET',
        headers: authHeader(),
    })).then(handleResponse);
}

function exportDeviceStatus(filter) {
    return fetchForDownload(`${backendHost}/restapi/v2.0/rms/devices/status/export${objToQueryString(filter)}`, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleBinDownloadResponse);
}

function fetchDeviceContentDownload(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/content-check${objToQueryString(filter)}`, setExclusive({
        method: 'GET',
        headers: authHeader(),
    })).then(handleResponse);
}

function fetchDeviceConnectionCheck(deviceIds) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/connections-checked`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({ids: deviceIds})
    }).then(handleResponse);
}

function approveDevices(approvalInfo) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/approved-devices`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(approvalInfo),
    }).then(handleResponse);
}

function checkDeviceApproval(groupId, deviceIds) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups/${groupId}/check-approved-devices`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(deviceIds),
    }).then(handleResponse);
}

function updateDeviceApproval(device) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/unapproved-devices/${device.deviceId}`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(device),
    }).then(handleResponse);
}

function exportUnapprovedDevices(filter) {
    return fetchForDownload(`${backendHost}/restapi/v2.0/rms/devices/unapproved/export${objToQueryString(filter)}`, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleBinDownloadResponse);
}

function exportUnapprovedDevicesGroupCode() {
    return fetchForDownload(`${backendHost}/restapi/v2.0/rms/devices/unapproved-code/export`, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleBinDownloadResponse);
}

function fetchDeviceScheduleCheck(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/schedule-check${objToQueryString(filter)}`, setExclusive({
        method: 'GET',
        headers: authHeader(),
    })).then(handleResponse);
}

function fetchDeviceScheduleUpcomingExpires(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/schedules/upcoming-expiries${objToQueryString(filter)}`, setExclusive({
        method: 'GET',
        headers: authHeader(),
    })).then(handleResponse);
}

function exportDeviceScheduleUpcomingExpires(filter) {
    return fetchForDownload(`${backendHost}/restapi/v2.0/rms/devices/schedules/upcoming-expiries/export${objToQueryString(filter)}`, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleBinDownloadResponse);
}

function fetchDeviceUpcomingExpires(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/upcoming-expiries${objToQueryString(filter)}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function exportDeviceUpcomingExpires(filter) {
    return fetchForDownload(`${backendHost}/restapi/v2.0/rms/devices/upcoming-expiries/export${objToQueryString(filter)}`, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleBinDownloadResponse);
}

function fetchDevicePlaylistToExpires(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/playlists/upcoming-expiries${objToQueryString(filter)}`, setExclusive({
        method: 'GET',
        headers: authHeader(),
    })).then(handleResponse);
}

function exportDevicePlaylistToExpires(filter) {
    return fetchForDownload(`${backendHost}/restapi/v2.0/rms/devices/playlists/upcoming-expiries/export${objToQueryString(filter)}`, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleBinDownloadResponse);
}

function fetchDeviceTimezoneCheck(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/timezone-check${objToQueryString(filter)}`, setExclusive({
        method: 'GET',
        headers: authHeader(),
    })).then(handleResponse);
}

function fetchDeviceStorageCheck(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/storage-check${objToQueryString(filter)}`, setExclusive({
        method: 'GET',
        headers: authHeader(),
    })).then(handleResponse);
}

function exportNofifications(filter) {
    return fetchForDownload(`${backendHost}/restapi/v2.0/rms/devices/notification/export${objToQueryString(filter)}`, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleBinDownloadResponse);
}

function fetchDeviceRegisteredSoftware(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/software/registered-software${objToQueryString(filter)}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchDeviceRegisteredSoftwareById(softwareId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/software/registered-software/${softwareId}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchDeviceReservedSoftware(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/software/reserved-software${objToQueryString(filter)}`, setExclusive({
        method: 'GET',
        headers: authHeader(),
    })).then(handleResponse);
}

function fetchDeviceWarningRules(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/warning-rules${objToQueryString(filter)}`, setExclusive({
        method: 'GET',
        headers: authHeader(),
    })).then(handleResponse);
}

function fetchDeviceRemoteJobs(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/remote-jobs${objToQueryString(filter)}`, setExclusive({
        method: 'GET',
        headers: authHeader(),
    })).then(handleResponse);
}

function fetchDeviceCancelRemoteJobs(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/remote-job/canceled-jobs`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(filter),
    }).then(handleResponse);
}

function fetchDeviceAddRemoteJobs() {
    return fetchForDownload(`${backendHost}/restapi/v2.0/rms/remote-job`, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleBinDownloadResponse);
}

function fetchDeviceReuseRemoteJobs(jobId) {
    return fetchForDownload(`${backendHost}/restapi/v2.0/rms/remote-job/${jobId}/reuse`, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleBinDownloadResponse);
}

function fetchDeviceEditRemoteJobs(jobId) {
    return fetchForDownload(`${backendHost}/restapi/v2.0/rms/remote-job/${jobId}/edit`, {
        method: 'PUT',
        headers: authHeader('PUT'),
    }).then(handleBinDownloadResponse);
}

function fetchDevicePresets(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/preset${objToQueryString(filter)}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchDevicePresetFilter(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/preset/filter`, setExclusive({
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(filter),
    })).then(handleResponse);
}

function createDevicePreset(preset) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/preset`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(preset),
    }).then(handleResponse);
}

function updateDevicePreset(preset) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/preset/${preset.preconfigId}`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(preset),
    }).then(handleResponse);
}

function copyDevicePreset(presetId, preset) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/preset/${presetId}/copy`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(preset),
    }).then(handleResponse);
}

function deleteDevicePreset(presetIds) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/preset/deleted-presets`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({ids: presetIds}),
    }).then(handleResponse);
}

function fetchDevicePreset(presetId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/preset/${presetId}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchDevicePresetDeployStatus(presetId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/preset/${presetId}/deploy-status`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchDevicePresetResult(deviceId, count) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/managed-preset${objToQueryString({count})}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchDevicePresetSetting() {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/preset/server-settings`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function createDeviceWarningRules(requestBody) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/warning-rules`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(requestBody),
    }).then(handleResponse);
}

function updateDeviceWarningRulesByRuleId(requestBody) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/warning-rules/${requestBody.ruleId}`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(requestBody),
    }).then(handleResponse);
}

function updateWarningRuleGroups(requestBody) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups/warning-rules/`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(requestBody),
    }).then(handleResponse);
}

function deleteDeviceWarningRules(requestBody) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/warning-rules/deleted-rules`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(requestBody),
    }).then(handleResponse);
}

function fetchDeviceWarningRulesByRuleId(ruleId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/warning-rules/${ruleId}`, {
        method: 'GET',
        headers: authHeader('GET'),
    }).then(handleResponse);
}

function fetchDeviceWarningRuleElements() {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/warning-rules/elements`, {
        method: 'GET',
        headers: authHeader('GET'),
    }).then(handleResponse);
}

function quickControlDevice(controlInfo) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/quick-control`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(controlInfo),
    }).then(handleResponse);
}

function fetchDeviceLedCabinets(deviceId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/led-cabinets`, {
        method: 'GET',
        headers: authHeader('GET'),
    }).then(handleResponse);
}

function fetchDeviceLedCabinet(deviceId, cabinetId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/led-cabinets/details?cabinetIds=${cabinetId}`, {
        method: 'GET',
        headers: authHeader('GET'),
    }).then(handleResponse);
}

function updateDeviceLedCabinet(deviceId, cabinetIds, cabinet) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/led-cabinets${objToQueryString({cabinetIds})}`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(cabinet),
    }).then(handleResponse);
}

function fetchDeviceLedCabinetsByIds(deviceId, cabinetIds) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/led-cabinets/details${objToQueryString({cabinetIds})}`, {
        method: 'GET',
        headers: authHeader('GET'),
    }).then(handleResponse);
}

function sendLedCabinetCommand(deviceId, command) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/led-cabinets/commands`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(command)
    }).then(handleResponse);
}

function scanLedCabinet(deviceId, scanInfos) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/led-cabinets/scan`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(scanInfos)
    }).then(handleResponse);
}

function fetchLedCabinetLayout(deviceId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/led-cabinets/layout`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchLedCabinetWarningRule(deviceId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/led-cabinets/warning-rule`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function exportLedCabinets(deviceId) {
    return fetchForDownload(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/led-cabinets/export`, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleBinDownloadResponse);
}

function fetchCurrentLedCabinet(deviceId, cabinetIds) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/current-led-cabinets/request-ticket`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({ids: cabinetIds})
    }).then(handleResponse);
}

function fetchCurrentLedCabinetStatus(deviceId, requestId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/current-led-cabinets/${requestId}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function moveDevice(groupId, deviceIds) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups/${groupId}/moved-devices${objToQueryString({groupId})}`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({ids: deviceIds}),
    }).then(handleResponse);
}

function fetchDeviceTypes() {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/device-types`, {
        method: 'GET',
        headers: authHeader('GET'),
    }).then(handleResponse);
}

function fetchDeviceModels(deviceType) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/software/${deviceType}/registered-software`, setExclusive({
        method: 'GET',
        headers: authHeader('GET'),
    })).then(handleResponse);
}

const retrieveToken = () => {
    try {
        let user = JSON.parse(localStorage.getItem('user'));
        return user.token;
    } catch(e) {
        console.log('retrieveToken',e);
    }
    return null;
}

function createSoftware(software, onProgress) {
    const formData  = new FormData();

    const requestOptions = {
        method: 'POST',
        headers: authHeader('FILE'),
        onUploadProgress: (event) => { onProgress(Math.round( (event.loaded * 100) / event.total)) }
    };

    const token = retrieveToken();
    let optionsWithToken = requestOptions;
    if (token !== null) {
        optionsWithToken = merge({}, requestOptions, {
            headers: {
                api_key: token
            }
        })
    }

    for(const name in software) {
        formData.append(name, software[name]);
    }

    return axios.post(`${backendHost}/restapi/v2.0/rms/devices/software/registered-software`, formData, optionsWithToken
        ).then(response => {
        return response.data;
    }).catch(response => {
        if (response.status !== 200) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                history.push('/login');
            }
            return Promise.reject(response.message);
        }
    });
}

function fetchSoftwareForEdit(softwareId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/software/${softwareId}/edit-software-info`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchSoftwareForDeploy(softwareId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/software/${softwareId}/edit-software-deploy?softwareId=${softwareId}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function updateSoftwareForDeploy(softwareId, rsvDate) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/software/${softwareId}/edit-software-deploy${objToQueryString({rsvDate})}`, {
        method: 'PUT',
        headers: authHeader('PUT'),
    }).then(handleResponse);
}

function cancelSoftwareDeploy(rsvId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/software/${rsvId}/cancel-software-deploy`, {
        method: 'DELETE',
        headers: authHeader('DELETE'),
    }).then(handleResponse);
}

function updateSoftware(software) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/software/${software.softwareId}/edit-software-save`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(software),
    }).then(handleResponse);
}

function deleteSoftware(softwareIds) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/software/deleted-softwares`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(softwareIds),
    }).then(handleResponse);
}

function fetchSoftwareForPublish(softwareId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/software/${softwareId}/registered-deploy-popup`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function publishSoftware(software) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/software/registered-deploy-popup`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(software),
    }).then(handleResponse);
}

function retryPublishSoftware(softwareId, rsvDate) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/software/${softwareId}/retry-software-deploy${objToQueryString({rsvDate: rsvDate})}`, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleResponse);
}

function exportSoftware(filter) {
    return fetchForDownload(`${backendHost}/restapi/v2.0/rms/devices/software/registered-software/export${objToQueryString(filter)}`, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleBinDownloadResponse);
}

function fetchSoftwareStatus(filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/software/reserved-software/${filter.reservationId}${objToQueryString(filter)}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function exportSoftwareStatus(filter) {
    return fetchForDownload(`${backendHost}/restapi/v2.0/rms/devices/software/reserved-software/export${objToQueryString(filter)}`, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleBinDownloadResponse);
}

function searchGroupByName(values) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/searched-groups`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(values),
    }).then(handleResponse);
}

function fetchSignageSlaveInfo(deviceId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/layouts/${deviceId}/signage-slave-info`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function updateSignageSetting(deviceId, settingType) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/layouts/${deviceId}/signage-display-setting?signageType=${settingType}`, {
        method: 'PUT',
        headers: authHeader('PUT'),
    }).then(handleResponse);
}

function scanChildDevices(deviceId, childCount) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/child-devices/${childCount}`, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleResponse);
}

function fetchSignageLayout(deviceId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/layouts/${deviceId}/show-signage-layout`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function downloadSoftware(filePath, signal) {
    return fetchForDownload(`${backendHost}/restapi/v2.0/rms/devices/software/registered-software/download${objToQueryString({filepath: filePath})}`, {
        method: 'GET',
        headers: authHeader(),
        signal: signal,
    }).then(handleBinDownloadResponse);
}

function downloadCustomize(filePath) {
    return fetchForDownload(`${backendHost}/restapi/v2.0/rms/devices/customize/download${objToQueryString({filePath})}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleBinDownloadResponse);
}

function requestNetworkMode(deviceIds) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/network-mode`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({ids: deviceIds})
    }).then(handleResponse);
}

function updateExpirationDate(dateInfo) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/licenses/expiration-date`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(dateInfo)
    }).then(handleResponse);
}

function updateDeviceType(deviceIds, type) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/conversions`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify({deivceIds: deviceIds, type})
    }).then(handleResponse);
}

function fetchDeviceTypeConversionStatus(deviceIds, requestId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/conversions`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({deviceIds, requestId})
    }).then(handleResponse);
}

function fetchDeviceVnc(deviceId, data) {
    return fetchForDownload(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/vnc`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(data)
    }).then(handleBinDownloadResponse);
}

function fetchDeviceRMServerVnc(deviceId, command) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/rmserver/${command}`, {
        method: 'GET',
        headers: authHeader('GET'),
    }).then(handleResponse);
}

function replaceVwlDevice(deviceId, newDeviceId, device) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/replace/${newDeviceId}`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(device),
    }).then(handleResponse);
}

function updateDeviceLogProcess(deviceId, param) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/log/process`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(param),
    }).then(handleResponse);
}

function fetchDeviceCollectedLogs(deviceId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/log/collected`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function downLoadDeviceCollectedLogs(deviceId, filepath) {
    return fetchForDownload(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/log/download${objToQueryString({filepath})}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleBinDownloadResponse);
}

function deleteDeviceCollectedLogs(deviceId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/log/clear-collected`, {
        method: 'DELETE',
        headers: authHeader('DELETE'),
    }).then(handleResponse);
}

function fetchDeviceSystemUsage(deviceId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/system-usage`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function deployDeviceSchedule(deviceId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/schedule-deploy`, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleResponse);
}

function updateDeviceChannel(channelInfo) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/save-channel`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(channelInfo),
    }).then(handleResponse);
}

function fetchDeviceWarningErrorInfo(deviceId, filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/warning-error-info${objToQueryString(filter)}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchDeviceConnectionCheckByGroupId(groupId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups/${groupId}/connections-checked`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchDeviceWarningErrorHistory(deviceId, filter) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/warning-error-info/history${objToQueryString(filter)}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchBackupPlayChecking(groupId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups/${groupId}/backup-tag-check`, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleResponse);
}

function fetchBackupMode(groupId, deviceIds) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups/${groupId}/backup-mode`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({ids: deviceIds}),
    }).then(handleResponse);
}

function saveBackupConfig(groupId, backupConfig) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups/${groupId}/backup-config`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(backupConfig),
    }).then(handleResponse);
}

function deleteBackupConfig(groupId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups/${groupId}/backup-clear`, {
        method: 'PUT',
        headers: authHeader('PUT'),
    }).then(handleResponse);
}

function fetchVwlLayoutInfoByGroupId(groupId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups/${groupId}/vwl-info`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function cancelVwlLayoutByGroupId(groupId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups/${groupId}/vwl-cancel`, {
        method: 'PUT',
        headers: authHeader('PUT'),
    }).then(handleResponse);
}

function previewVwlLayoutByGroupId(groupId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups/${groupId}/vwl-preview`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchDeviceImage(url) {
    let convertedUrl = url;
    if(url.startsWith('/MagicInfo')){
        convertedUrl = backendHost + url.replace('/MagicInfo', '')
    }
    return fetch(convertedUrl, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchDeviceFlipInfo(deviceIds) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/flips/general-info`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(deviceIds),
    }).then(handleResponse);
}

function updateFlipDeviceGeneralInfo(flipInfo) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/flips/current-general-info`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(flipInfo),
    }).then(handleResponse);
}

function fetchFlipDeviceGeneralInfoRequest(flipInfo, requestId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/flips/current-general-info/${requestId}`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(flipInfo),
    }).then(handleResponse);
}

function fetchFlipDeviceStatusRequest(deviceIds, requestId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/flips/current-status/${requestId}`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(deviceIds),
    }).then(handleResponse);
}

function fetchFlipDeviceAllStatusRequest(deviceIds) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/flips/current-status`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(deviceIds),
    }).then(handleResponse);
}

function fetchAvailableFunctions(groupId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups/${groupId}/available-functions`, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleResponse);
}

function sendPostBoot(deviceId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/send-boot`, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleResponse);
}

function fetchTagsByDeviceGroups(groupIds) {
    const body = {
        ids: groupIds
    }
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups/tags`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(body)
    }).then(handleResponse)

}

function fetchDeviceContentDownloadStatus(deviceId, scheduleType) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/${deviceId}/contents/download-status${objToQueryString({scheduleType})}`, {
        method: 'GET',
        headers: authHeader('GET'),
    }).then(handleResponse);
}

function fetchServerStatus(serverUrl) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/check-server-status${objToQueryString({serverUrl})}`, {
        method: 'GET',
        headers: authHeader('GET'),
    }).then(handleResponse);
}

function fetchReplacementCheck(checkInfo) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups/replacement-check`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(checkInfo),
    }).then(handleResponse);
}

function fetchLicenseUsage() {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/license-usage`, {
        method: 'GET',
        headers: authHeader('GET'),
    }).then(handleResponse);
}

function sendAnalyticsEnable(groupId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups/analystic-enable?groupId=`+ groupId, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleResponse);
}

function sendAnalyticsCancel(groupId) {
    return fetch(`${backendHost}/restapi/v2.0/rms/devices/groups/analystic-cancel?groupId=`+ groupId, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleResponse);
}