import React, {createRef} from 'react';
import './Filter.css';
import ReactDOM from "react-dom";
import {withTranslation} from "react-i18next";
import {settingService, userService} from "../../services";
import classNames from 'classnames';
import withMISOpt, {deviceOptions} from '../misopt';
import ConnectionStatusFilter, {getConnectionStatusOption} from './ConnectionStatusFilter';
import NotificationFilter from './NotificationFilter';
import FunctionFilter from './FunctionFilter';
import SourceFilter from './SourceFilter';
import DeviceFilterStatus from './DeviceFilterStatus';
import FileTypeFilterStatus from "./FileTypeFilterStatus";
import UserFilterStatus from "./UserFilterStatus";
import ContentApprovalFilterStatus from "./ContentApprovalFilterStatus";
import ContentUseFilterStatus from "./ContentUseFilterStatus";
import ContentExpiration from "./ContentExpiration";
import ContentExpirationFilterStatus from "./ContentExpirationFilterStatus";
import MediaTagFilterStatus from "./MediaTagFilterStatus";
import ContentFileSizeFilterStatus from "./ContentFileSizeFilterStatus";
import DeviceTypes from "./DeviceTypes";
import ContentFileTypes from "./ContentFileTypes";
import Users from "./Users";
import DeviceStatus from "./DeviceStatus";
import ContentUse from "./ContentUse";
import MediaTag from "./MediaTag";
import ContentFileSize from "./ContentFileSize";
import PlaylistTypes from "./PlaylistTypes";
import PlaylistTypeFilterStatus from "./PlaylistTypeFilterStatus";
import RulesetStatus from "./RulesetStatus";
import {contentConstants, LEDBOX, playlistConstants, RSPLAYER} from "../../constants";
import RulesetStatusFilterStatus from './RulesetStatusFilterStatus';
import ContentDeviceFilterStatus from './ContentDeviceFilterStatus';
import isEmpty from "lodash/isEmpty";

const playlistTypes = playlistConstants.PLAYLISTTYPES;

const fileSizeList = ["0-10MB","10-50MB", "50-500MB", "500MB-"];
const fileSizeConvert = [{minFileSizeInMB: 0, maxFileSizeInMB:10, label: "0-10MB"},
    {minFileSizeInMB: 10, maxFileSizeInMB:50, label: "10-50MB"},
    {minFileSizeInMB: 50, maxFileSizeInMB:500, label: "50-500MB"},
    {minFileSizeInMB: 500, maxFileSizeInMB:null, label: "500MB-"}];

const approvalStatusOption = [
    {label:"TEXT_ALL_P", value:"ALL"},
    {label:"TEXT_APPROVAL_P", value:"APPROVED"},
    {label:"COM_TV_SID_UNAPPROVED", value:"UNAPPROVED"}
    ];
const contentUseOption =[
    {label:"TEXT_ALL_P", value:"ALL"},
    {label:"COM_SID_USED_KR_MIS20", value:"IN_USE"},
    {label:"MIS_SID_NOT_USED_KR_BLANK", value:"UNUSED"}
];
const expirationStatusOption = [
    {label:"TEXT_ALL_P", value:"ALL"},
    {label:"MIS_SID_EXPRIATION_STAUTS", value:"EXPIRED"},
    {label:"MIS_SID_NOT_EXPIRED", value:"VALID"}
];
const rulesetStatusList = [
    {label:"COM_SID_USED_KR_MIS20", value: "USED"},
    {label:"MIS_SID_NOT_USED", value: "NOT_USED"},
    {label:"MIS_SID_CAMAR_IMCOMPLETE", value: "UNSCHEDULABLE"}
];

const allRulesetStatus = ["USED", "NOT_USED", "UNSCHEDULABLE"];

const allOption = ["ALL"];
const emptyOption = ["NONE"];

const getDeviceTypes = supportedIconDevices => {
    return supportedIconDevices.map(dt => ({type: dt.deviceType === 'IPLAYER' ? dt.deviceFullType : dt.deviceType, version: dt.deviceTypeVersion, id: dt.deviceFullType}));
};

class Filter extends React.Component {

    constructor(props) {
        super(props);
        const position = {};
        const menu = {};
        const {supportedIconDevices} = props.misopt;

        switch (props.type.toUpperCase()) {
            case 'DEVICE':
                menu.deviceType = true;
                menu.device = true;
                menu.mediaTag = true;
                menu.source = true;
                break;
            case 'CONTENT_POPUP':
            case 'CONTENT_SCHEDULE':
            case 'PLAYLIST':
                menu.fileType = true;
                menu.users = true;
                menu.mediaTag = true;
                menu.fileSize = true;
                position.x = 0;
                position.y = 30;
                break;
            case 'CONTENT':
                menu.contentDeviceType = true;
                menu.fileType = true;
                menu.users = true;
                menu.approvalStatus = true;
                menu.contentUse = true;
                menu.expirationStatus = true;
                menu.mediaTag = true;
                menu.fileSize = true;
                break;
            case 'CONTENT_SCHEDULE_PLAYLIST':
            case 'PLAYLIST_POPUP':
                menu.playlistType = true;
                menu.users = true;
                position.x = 0;
                position.y = 30;
                break;
            case 'RULESET':
                menu.contentDeviceType = true;
                menu.rulesetStatus = true;
                menu.users = true;
                break;
            default:
                break;
        }

        this.deviceTypes = getDeviceTypes(supportedIconDevices);
        if(props.type === 'RULESET') {
            this.deviceTypes = this.deviceTypes.filter(deviceType => deviceType.type.toUpperCase() === 'IPLAYER' || (deviceType.type.toUpperCase() === 'SPLAYER' && deviceType.version >= 6));
        }
        this.connectionStatusOption = getConnectionStatusOption(this.props.t);
        this.state = {
            position: this.props.position !== undefined ? this.props.position : position,
            show: false,
            filter: [],
            contents: this.props.mediaTypes !== undefined ? this.props.mediaTypes : contentConstants.FILTER_MEDIA_TYPE,
            playlists: this.props.playlistTypes !== undefined ? this.getPlaylistTypes(this.props.playlistTypes) : playlistTypes,
            devices: this.deviceTypes,
            fileSizes: fileSizeList,
            users: [],
            mediaTags : [],
            rulesetStatus: rulesetStatusList,
            selected: {
                devices: [], //deviceTypes.map(device => device.id),
                playlistTypes: this.props.playlistTypes !== undefined ? this.props.playlistTypes : ["0","2","3","5","6"],
                contents: this.props.mediaTypes !== undefined ? this.props.mediaTypes : contentConstants.FILTER_MEDIA_TYPE,
                users: [],
                mediaTags: [],
                approvalStatus: approvalStatusOption[0],
                contentUse: contentUseOption[0],
                expirationStatus: expirationStatusOption[0],
                fileSizes: fileSizeConvert,
                connectionStatus: this.connectionStatusOption[0].value,
                notifications: [],
                functions: [],
                sources: deviceOptions.inputSource,
                rulesetStatus: allRulesetStatus
            },
            fromSize:'',
            toSize:'',
            customInputBox: true,
            organId: props.organId !== undefined ? props.organId : 'ALL',
            deviceTypeChanged: false,
            sourceChanged: false,

            menu: menu
        };

        this.deviceTypeCheckAll = createRef();
        this.deviceTypeCheckBox = [];

        this.playlistTypeCheckAll = createRef();
        this.playlistTypeCheckBox = [];

        this.contentCheckAll = createRef();
        this.contentCheckBox = [];

        this.userCheckBox = [];

        this.fileSizeCheckAll = createRef();
        this.fileSizeCheckBox = [];

        this.mediaTagCheckBox = [];

        this.originalData = {};

        this.rulesetStatusCheckAll = createRef();
        this.rulesetStatusCheckBox = [];
    }

    componentDidMount() {
        const {type,cachedFilter = {}} = this.props;
        const {selected} = this.state;
        let devices = this.props.type.toUpperCase() === 'PLAYLIST'? undefined : (this.props.type.toUpperCase() !== 'DEVICE' ?
        this.deviceTypes.map(device => device.id) :
        this.props.misopt.misopt.deviceTypes.map(dt => dt.deviceFullType));     

        if(type=='CONTENT') {
            let fileSizes = selected.fileSizes;
            let fromSize = this.state.fromSize;
            let toSize = this.state.toSize;
            if(cachedFilter != undefined){
                if(cachedFilter.deviceTypes != undefined){
                    if(!cachedFilter.deviceTypes.includes("ALL")){
                       devices = cachedFilter.deviceTypes;
                    }
                }

                if(cachedFilter.fileSizes != undefined){
                   fileSizes = cachedFilter.fileSizes;
                   if(fileSizes.length == 1 && fileSizes[0].label == "ALL"){
                       fileSizes = fileSizeConvert;
                   }
                   let customFileSizeObj = fileSizes.filter(tmpSize => tmpSize.label == "Custom");
                   if(customFileSizeObj&& customFileSizeObj.length > 0){
                       fromSize = customFileSizeObj[0].minFileSizeInMB;
                       toSize = customFileSizeObj[0].maxFileSizeInMB;
                   }
                }
            }
   
            this.setState({
                ...this.state,
                fromSize : fromSize,
                toSize : toSize,
                selected: {
                    ...selected,
                    devices: devices,
                    contents: cachedFilter.contentTypes != undefined ? cachedFilter.contentTypes : contentConstants.FILTER_MEDIA_TYPE,
                    users: cachedFilter.creatorIds != undefined ? cachedFilter.creatorIds : [],
                    approvalStatus : cachedFilter.approvalStatus != undefined ? this.getOptionByValue(approvalStatusOption, cachedFilter.approvalStatus) : approvalStatusOption[0],
                    contentUse : cachedFilter.contentUsingStatus != undefined ? this.getOptionByValue(contentUseOption, cachedFilter.contentUsingStatus) : contentUseOption[0],
                    expirationStatus : cachedFilter.expirationStatus != undefined ? this.getOptionByValue(expirationStatusOption, cachedFilter.expirationStatus) : expirationStatusOption[0],
                    mediaTags : cachedFilter.tagIds != undefined ? cachedFilter.tagIds : [],
                    fileSizes: fileSizes,
                }
            });
        } else if (type == 'RULESET') {
            let users = selected.users;
            let rulesetStatus = selected.rulesetStatus;
            if(cachedFilter != undefined){
                if(cachedFilter.deviceTypes != undefined){
                    if(!cachedFilter.deviceTypes.includes("ALL")){
                        devices = cachedFilter.deviceTypes;
                    }
                }
                if(cachedFilter.creatorIds != undefined){
                    users = cachedFilter.creatorIds;
                }
                if(cachedFilter.rulesetStatus != undefined){
                    if(cachedFilter.rulesetStatus.includes("ALL")){
                        rulesetStatus = allRulesetStatus;
                    } else {
                        rulesetStatus = cachedFilter.rulesetStatus;
                    }
                    
                }
            }

            this.setState({
                selected: {
                    ...selected,
                    devices: devices,
                    users: users,
                    rulesetStatus: rulesetStatus,
                }
            });
        } else if (type == 'playlist') {
            let fileSizes = selected.fileSizes;
            let fromSize = this.state.fromSize;
            let toSize = this.state.toSize;
            if(cachedFilter.fileSizes !== undefined){
                fileSizes = cachedFilter.fileSizes;
                if(fileSizes.length == 1 && fileSizes[0].label == "ALL"){
                    fileSizes = fileSizeConvert;
                }
                let customFileSizeObj = fileSizes.filter(tmpSize => tmpSize.label == "Custom");
                if(customFileSizeObj&& customFileSizeObj.length > 0){
                    fromSize = customFileSizeObj[0].minFileSizeInMB;
                    toSize = customFileSizeObj[0].maxFileSizeInMB;
                }
            }

            this.setState({
                fromSize : fromSize,
                toSize : toSize,
                selected: {
                    ...selected,
                    contents: cachedFilter.contentTypes != undefined ? cachedFilter.contentTypes : this.props.mediaTypes,
                    users: cachedFilter.creatorIds != undefined ? cachedFilter.creatorIds : [],
                    mediaTags: cachedFilter.tagIds != undefined ? cachedFilter.tagIds : [],
                    fileSizes: fileSizes,
                }
            });
        } else if(type=='content_schedule') {
            let fileSizes = selected.fileSizes;
            let fromSize = this.state.fromSize;
            let toSize = this.state.toSize;
            if(cachedFilter.fileSizes !== undefined){
                fileSizes = cachedFilter.fileSizes;
                if(fileSizes.length == 1 && fileSizes[0].label == "ALL"){
                    fileSizes = fileSizeConvert;
                }
                let customFileSizeObj = fileSizes.filter(tmpSize => tmpSize.label == "Custom");
                if(customFileSizeObj&& customFileSizeObj.length > 0){
                    fromSize = customFileSizeObj[0].minFileSizeInMB;
                    toSize = customFileSizeObj[0].maxFileSizeInMB;
                }
            }
            this.setState({
                fromSize : fromSize,
                toSize : toSize,
                selected: {
                    ...selected,
                    contents: cachedFilter.contentTypes != undefined ? cachedFilter.contentTypes : this.props.mediaTypes !== undefined ? this.props.mediaTypes : contentConstants.FILTER_MEDIA_TYPE,
                    users: cachedFilter.creatorIds != undefined ? cachedFilter.creatorIds : [],
                    mediaTags: cachedFilter.tagIds != undefined ? cachedFilter.tagIds : [],
                    fileSizes: fileSizes
                }
            });
        } else if(type=='content_schedule_playlist'){
            this.setState({
                selected: {
                    ...selected,
                    playlistTypes: cachedFilter.playlistTypes != undefined ? cachedFilter.playlistTypes : this.props.playlistTypes !== undefined ? this.props.playlistTypes : ["0","2","3","4","5","6"],
                    users: cachedFilter.creatorIds != undefined ? cachedFilter.creatorIds : [],
                }
            });
        } else if(type == "device"){
            if(cachedFilter.deviceType != undefined){
                if(cachedFilter.deviceType === emptyOption){
                    devices = [];
                } else if(cachedFilter.deviceType.length > 0){
                    devices = cachedFilter.deviceType;
                }
            }

            let sources=[]
            if(cachedFilter.inputSources == undefined){
                sources = deviceOptions.inputSource
            }else if (cachedFilter.inputSources != undefined && cachedFilter.inputSources.length==0){
                sources = deviceOptions.inputSource
            }else if (cachedFilter.inputSources != undefined && cachedFilter.inputSources.length!=0) {
                for(let i =0; i <cachedFilter.inputSources.length;i++){
                    let temp = deviceOptions.inputSource.find(item=> item.value == cachedFilter.inputSources[i]);
                    if(temp != undefined)
                        sources.push(temp);
                }
            }

            this.setState({
                selected: {
                    ...selected,
                    devices: devices,
                    connectionStatus: cachedFilter.connectionStatus? cachedFilter.connectionStatus : this.connectionStatusOption[0].value,
                    notifications: cachedFilter.alarmTypes? cachedFilter.alarmTypes : [],
                    functions: cachedFilter.functionTypes? cachedFilter.functionTypes : [],
                    mediaTags: cachedFilter.tagIds != undefined ? cachedFilter.tagIds : [],
                    sources : sources
                }
            });

        } else{
            this.setState({
                selected: {
                    ...selected,
                    devices: this.props.type.toUpperCase() === 'PLAYLIST' ? undefined : (this.props.type.toUpperCase() !== 'DEVICE' ?
                        this.deviceTypes.map(device => device.id) :
                        this.props.misopt.misopt.deviceTypes.map(dt => dt.deviceFullType))

                }
            });
        }
        //document.addEventListener('click', this.handleClickOutside, true);

        if(this.props.onRef !== undefined){
            this.props.onRef(this);
        }
    }

    componentWillUnmount() {
        if(this.props.onRef !== undefined){
            this.props.onRef(undefined);
        }
    }

    resetCheckBox = () => {
        this.originalData = {
            devices: this.props.type.toUpperCase() === 'PLAYLIST'? undefined : (this.props.type.toUpperCase() !== 'DEVICE' ?
                this.deviceTypes.map(device => device.id) :
                this.props.misopt.misopt.deviceTypes.map(dt => dt.deviceFullType)),
            playlistTypes: this.props.playlistTypes !== undefined ? this.getPlaylistTypes(this.props.playlistTypes) : playlistTypes,
            contents: this.props.mediaTypes !== undefined ? this.props.mediaTypes : contentConstants.FILTER_MEDIA_TYPE,
            users: [],
            mediaTags: [],
            approvalStatus: approvalStatusOption[0],
            contentUse: contentUseOption[0],
            expirationStatus: expirationStatusOption[0],
            fileSizes: fileSizeList,
            connectionStatus: this.connectionStatusOption[0],
            notifications: [],
            functions: [],
            sources: deviceOptions.inputSource,
            rulesetStatus: allRulesetStatus
        };
        this.initCheckbox();
    };

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this.dim);
        if (domNode && domNode === event.target) {
            this.initCheckbox();
            this.setState({
                show: false
            });
        }
    };

    getPlaylistTypes = (types) => {
        let rtnPlaylistTypes = [];
        playlistTypes.map(
            p => {
                const type = types.find(f => String(f) === p.id);
                if (type !== undefined) {
                    rtnPlaylistTypes.push(p);
                }
            }
        );
        return rtnPlaylistTypes;
    };

    openFilter() {
        const {show, users, mediaTags, organId, selected} = this.state;
        const loginUser = JSON.parse(localStorage.getItem('user'));

        if (!show && users.length < 1) {
            userService.fetchUserFilter({
                startIndex: 1,
                pageSize: 10000,
                groupId: loginUser.groupId,
                sortColumn: 'USER_ID',
                sortOrder: 'ASC',
            }, false).then(
                res => {
                    this.setState({
                        users: res.items
                    })
                }
            )
        }

        if (!show && mediaTags.length < 1) {
            settingService.fetchTags({
                startIndex: 1,
                pageSize: 10000,
                type: 'media',
                sortColumn: 'tag_value',
                sortOrder: 'asc',
                organId: organId
            }, false).then(res => {
                this.setState({
                    mediaTags: res.items
                })
            });
        }

        this.originalData = selected;
        this.setState({
            show: !this.state.show
        })
    }

    initMediaTagCheckbox = () => {
        const {mediaTags} = this.state;
        console.log(this.state.menu);
        if(this.state.menu.mediaTag !== undefined && this.state.menu.mediaTag){
            for(let i = 0; i < mediaTags.length; i++){
                if(this.mediaTagCheckBox[mediaTags[i].tagId].checked){
                    this.mediaTagCheckBox[mediaTags[i].tagId].checked = false;
                }
            }

            if(this.originalData.mediaTags !== undefined) {
                if(this.originalData.mediaTags.length === mediaTags.length){
                    for(let i = 0; i < mediaTags.length; i++){
                        if(!this.mediaTagCheckBox[mediaTags[i].tagId].checked){
                            this.mediaTagCheckBox[mediaTags[i].tagId].checked = true;
                        }
                    }
                }else {
                    this.originalData.mediaTags.map(tag => {
                        this.mediaTagCheckBox[tag.tagId].checked = true;
                    });
                }
            }
        }
    };

    initCheckbox = () => {
        const {users, mediaTags, fileSizes, rulesetStatus, selected, contents} = this.state;
        const {type, misopt: {misopt}} = this.props;

        if(JSON.stringify(this.originalData) === JSON.stringify(selected)) {
            // console.log("no need to init");
        }else {
            this.setState({
                selected:this.originalData
            });

            // devices: [],
            if(this.state.menu.deviceType || this.state.menu.contentDeviceType){
                if(type.toUpperCase() !== 'DEVICE') {
                    for (let i = 0; i < this.deviceTypes.length; i++) {
                        if (this.deviceTypeCheckBox[this.deviceTypes[i].id].checked) {
                            this.deviceTypeCheckBox[this.deviceTypes[i].id].checked = false;
                        }
                    }
                } else {
                    for(const dev in this.deviceTypeCheckBox) {
                        if(this.deviceTypeCheckBox[dev].checked) {
                            this.deviceTypeCheckBox[dev].checked = false;
                        }
                    }

                    for(const dev of this.originalData.devices) {
                        if(this.deviceTypeCheckBox[dev]) {
                            this.deviceTypeCheckBox[dev].checked = true;
                        }
                    }

                    let allChecked = true;
                    for(const dev in this.deviceTypeCheckBox) {
                        if(!this.deviceTypeCheckBox[dev].checked) {
                            allChecked = false;
                            break;
                        }
                    }

                    this.deviceTypeCheckAll.current.checked = allChecked;
                    this.initMediaTagCheckbox();
                    return;
                }

                if(this.originalData.devices !== undefined) {
                    if(this.originalData.devices.length === this.deviceTypes.length){
                        for(let i = 0; i < this.deviceTypes.length; i++){
                            if(!this.deviceTypeCheckBox[this.deviceTypes[i].id].checked){
                                this.deviceTypeCheckBox[this.deviceTypes[i].id].checked = true;
                            }
                        }
                        this.deviceTypeCheckAll.current.checked = true;
                    }else {
                        this.originalData.devices.map(item => {
                            this.deviceTypeCheckBox[item].checked = true;
                        });
                        this.deviceTypeCheckAll.current.checked = false;
                    }
                }
            }

            // playlist
            if(this.state.menu.playlistType !== undefined && this.state.menu.playlistType){
                for(let i = 0; i < playlistTypes.length; i++){
                    if(this.playlistTypeCheckBox[playlistTypes[i].id]&&this.playlistTypeCheckBox[playlistTypes[i].id].checked){
                        this.playlistTypeCheckBox[playlistTypes[i].id].checked = false;
                    }
                }
            }

            //     contents:[],
            if(this.state.menu.fileType !== undefined && this.state.menu.fileType){
                for(let i = 0; i < contents.length; i++){
                    if(this.contentCheckBox[contents[i]].checked){
                        this.contentCheckBox[contents[i]].checked = false;
                    }
                }

                if(this.originalData.contents !== undefined) {
                    if(this.originalData.contents.length === contents.length){
                        for(let i = 0; i < contents.length; i++){
                            if(!this.contentCheckBox[contents[i]].checked){
                                this.contentCheckBox[contents[i]].checked = true;
                            }
                        }
                        this.contentCheckAll.current.checked = true;
                    }else {
                        this.originalData.contents.map(content => {
                            this.contentCheckBox[content].checked = true;
                        });
                        this.contentCheckAll.current.checked = false;
                    }
                }
            }
            //     users: [],
            for(let i = 0; i < users.length; i++){
                if(this.userCheckBox[users[i].userInfo.userId].checked){
                    this.userCheckBox[users[i].userInfo.userId].checked = false;
                }
            }

            if(this.originalData.users !== undefined) {
                if(this.originalData.users.length === users.length){
                    for(let i = 0; i < users.length; i++){
                        if(!this.userCheckBox[users[i].userInfo.userId].checked){
                            this.userCheckBox[users[i].userInfo.userId].checked = true;
                        }
                    }
                }else {
                    this.originalData.users.map(userId => {
                        this.userCheckBox[userId].checked = true;
                    });
                }
            }
            //     mediaTag: [],
            this.initMediaTagCheckbox();
            
            //     approvalStatus: {},

            // contentUse: {},

            // expirationStatus: {},

            // fileSizes: []            
            if(this.state.menu.fileSize !== undefined && this.state.menu.fileSize){
                for(let i = 0; i < fileSizes.length; i++){
                    if(this.fileSizeCheckBox[fileSizes[i]].checked){
                        this.fileSizeCheckBox[fileSizes[i]].checked = false;
                    }
                }

                if(this.fileSizeCheckBox['Custom'].checked){
                    this.fileSizeCheckBox['Custom'].checked = false;
                }

                if(this.originalData.fileSizes !== undefined) {
                    if(this.originalData.fileSizes.length == fileSizeList.length){
                        for(let i = 0; i < fileSizes.length; i++){
                            this.fileSizeCheckBox[fileSizes[i]].checked = true;
                        }
                        this.fileSizeCheckAll.current.checked = true;
                        this.setState({
                            customInputBox:true
                        });
                    }else {
                        this.originalData.fileSizes.map(size => {
                            this.fileSizeCheckBox[size.label].checked = true;
                            if(size === 'Custom'){
                                this.setState({
                                    customInputBox:false
                                });
                            }
                        });
                        this.fileSizeCheckAll.current.checked = false;
                    }
                }
            }

            // rulesetStatus 
            if(this.state.menu.rulesetStatus !== undefined && this.state.menu.rulesetStatus){
                for(let i = 0; i < rulesetStatus.length; i++) {
                    if(this.rulesetStatusCheckBox[rulesetStatus[i].value].checked) {
                        this.rulesetStatusCheckBox[rulesetStatus[i].value].checked = false;
                    }
                }

                if(this.originalData.rulesetStatus !== undefined) {
                    if(this.originalData.rulesetStatus.length === rulesetStatusList.length) {
                        for(let i = 0; i < rulesetStatusList.length; i++) {
                            this.rulesetStatusCheckBox[rulesetStatusList[i].value].checked = true;
                        }
                        this.rulesetStatusCheckAll.current.checked = true;
                    }

                    else {
                        this.originalData.rulesetStatus.map(status => {
                            this.rulesetStatusCheckBox[status.value].checked = true;
                        });
                        this.rulesetStatusCheckAll.current.checked = false;
                    }
                }
            }
        }
    };

    closeFilter = () => {
        this.initCheckbox();
        this.setState({
            show: false
        });
    };

    saveFilter = () => {
        const {selected, fromSize, toSize} = this.state;
        const {type, misopt: {misopt}} = this.props;
        let fileSizeResult = [];
        if(fileSizeList.length == selected.fileSizes.filter(tmpSize=> tmpSize.label != "Custom").length) {
            fileSizeResult = [{minFileSizeInMB: 0, maxFileSizeInMB:null, label: "ALL"}];
        }else {
            for(var i =0 ; i < selected.fileSizes.length; i++){
                if(selected.fileSizes[i].label === 'Custom') {
                    fileSizeResult.push({minFileSizeInMB: parseInt(fromSize,10), maxFileSizeInMB: parseInt(toSize, 10), label: "Custom"});
                }else{
                    fileSizeResult.push(fileSizeConvert[fileSizeList.indexOf(selected.fileSizes[i].label)]);
                }
            }
        };

        let rulesetStatusResult = selected.rulesetStatus;
        if(selected.rulesetStatus.length === rulesetStatusList.length) {
            rulesetStatusResult = allOption;
        }

        let selectedDeviceTypes = [];
        if(type.toUpperCase() !== 'DEVICE' && type.toUpperCase() !== 'PLAYLIST') {
            selectedDeviceTypes = this.deviceTypes;
        } else {
            selectedDeviceTypes = misopt.deviceTypes;
        }

        const result = {
            ...selected,
            devices: selected.devices &&selected.devices.length === selectedDeviceTypes.length ? allOption : (selected.devices && selected.devices.length === 0 ? emptyOption : selected.devices),
            contents: selected.contents.length === this.state.contents.length ? allOption : (selected.contents.length === 0 ? emptyOption : selected.contents),
            users: selected.users,
            mediaTags: selected.mediaTags,
            approvalStatus: selected.approvalStatus.value,
            contentUse: selected.contentUse.value,
            expirationStatus: selected.expirationStatus.value,
            fileSizes: fileSizeResult,
            rulesetStatus: rulesetStatusResult
        };
        this.props.onSaveFilter(result);
        this.setState({
            show: false
        });
    };

    toggleContent = (type) => {
        const {selected, contents} = this.state;
        if (this.contentCheckBox[type].checked) {
            if(selected.contents.length + 1 === contents.length){
                this.contentCheckAll.current.checked = true;
            }
            this.setState({
                selected: {
                    ...selected,
                    contents: selected.contents.concat(type)
                }
            })
        } else {
            if(selected.contents.length === contents.length){
                this.contentCheckAll.current.checked = false;
            }
            this.setState({
                selected: {
                    ...selected,
                    contents: selected.contents.filter(content=> content !== type)
                }
            })
        }
    };

    toggleContentAll = (e) => {
        const {selected, contents} = this.state;
        if(e.target.checked){
            for(let i = 0; i < contents.length; i++){
                if(!this.contentCheckBox[contents[i]].checked){
                    this.contentCheckBox[contents[i]].checked = true;
                }
            }
            this.setState({
                selected: {
                    ...selected,
                    contents: contents
                }
            });
        }else {
            for(let i = 0; i < contents.length; i++){
                if(this.contentCheckBox[contents[i]].checked){
                    this.contentCheckBox[contents[i]].checked = false;
                }
            }
            this.setState({
                selected: {
                    ...selected,
                    contents: []
                }
            });
        }
    };

    togglePlaylistType = (id) => {
        const {selected, playlists} = this.state;
        if (this.playlistTypeCheckBox[id].checked) {
            if (selected.playlistTypes.length +1 === playlists.length) {
                this.playlistTypeCheckAll.current.checked = true;
            }
            this.setState({
                selected: {
                    ...selected,
                    playlistTypes: selected.playlistTypes.concat(id)
                },
                playlistTypeChanged: true,
            })
        } else {
            this.setState({
                selected: {
                    ...selected,
                    playlistTypes: selected.playlistTypes.filter(playlistTypeId=> playlistTypeId.toString() !== id)
                },
                playlistTypeChanged: true,
            })
            if (selected.playlistTypes.length === playlists.length) {
                this.playlistTypeCheckAll.current.checked = false;
            }
        }
    }

    toggleDevice = (deviceId) => {
        const {selected} = this.state;
        const {type, misopt: {misopt}} = this.props;

        let selectedDeviceTypes = [];
        if(type.toUpperCase() !== 'DEVICE') {
            selectedDeviceTypes = this.deviceTypes;
        } else {
            selectedDeviceTypes = misopt.deviceTypes;
        }

        if (this.deviceTypeCheckBox[deviceId].checked) {
            if (selected.devices.length + 1 === selectedDeviceTypes.length) {
                this.deviceTypeCheckAll.current.checked = true;
            }
            this.setState({
                selected: {
                    ...selected,
                    devices: selected.devices.concat(deviceId)
                },
                deviceTypeChanged: true,
            })
        } else {
            if (selected.devices.length === selectedDeviceTypes.length) {
                this.deviceTypeCheckAll.current.checked = false;
            }
            this.setState({
                selected: {
                    ...selected,
                    devices: selected.devices.filter(device => device !== deviceId)
                },
                deviceTypeChanged: true,
            })
        }
    };

    togglePlaylistTypeAll = (e) => {
        const {selected, playlists} = this.state;
        if (e.target.checked){
            for(let i = 0; i < playlists.length; i++){
                if(!this.playlistTypeCheckBox[playlists[i].id].checked){
                    this.playlistTypeCheckBox[playlists[i].id].checked = true;
                }
            }
            this.setState({
                selected: {
                    ...selected,
                    playlistTypes: playlists.map(idx => idx.id)
                },
                playlistTypeChanged: true,
            });
        } else {
            for(let i = 0; i < playlists.length; i++){
                if(this.playlistTypeCheckBox[playlists[i].id].checked){
                    this.playlistTypeCheckBox[playlists[i].id].checked = false;
                }
            }
            this.setState({
                selected: {
                    ...selected,
                    playlistTypes: []
                },
                deviceTypeChanged: true,
            });
        }
    }

    toggleDeviceAll = (e) => {
        const {selected} = this.state;
        const {type, misopt: {misopt}} = this.props;

        if(type.toUpperCase() !== 'DEVICE'){
            const {devices} = this.state;
            if(e.target.checked){
                for(let i = 0; i < devices.length; i++){
                    if(!this.deviceTypeCheckBox[devices[i].id].checked){
                        this.deviceTypeCheckBox[devices[i].id].checked = true;
                    }
                }
                this.setState({
                    selected: {
                        ...selected,
                        devices: this.deviceTypes.map(device => device.id)
                    },
                    deviceTypeChanged: true,
                });
            }else {
                for(let i = 0; i < devices.length; i++){
                    if(this.deviceTypeCheckBox[devices[i].id].checked){
                        this.deviceTypeCheckBox[devices[i].id].checked = false;
                    }
                }
                this.setState({
                    selected: {
                        ...selected,
                        devices: []
                    },
                    deviceTypeChanged: true,
                });
            }
        } else {
            if (e.target.checked) {
                for(const dtc in this.deviceTypeCheckBox) {
                    this.deviceTypeCheckBox[dtc].checked = true;
                }
                this.setState({
                    selected: {
                        ...selected,
                        devices: misopt.deviceTypes.map(device => device.deviceFullType)
                    },
                    deviceTypeChanged: true,
                });
            } else {
                for(const dtc in this.deviceTypeCheckBox) {
                    this.deviceTypeCheckBox[dtc].checked = false;
                }
                this.setState({
                    selected: {
                        ...selected,
                        devices: []
                    },
                    deviceTypeChanged: true,
                });
            }
        }
    };

    toggleUser = (userId) => {
        const {selected} = this.state;
        if (this.userCheckBox[userId].checked) {
            this.setState({
                selected: {
                    ...selected,
                    users: selected.users.concat(userId)
                }
            })
        } else {
            this.setState({
                selected: {
                    ...selected,
                    users: selected.users.filter(item => item !== userId)
                }
            })
        }
    };

    toggleFileSizeAll = (e) => {
        const {selected, fileSizes} = this.state;
        if(e.target.checked){
            for(let i = 0; i < fileSizes.length; i++){
                if(this.fileSizeCheckBox[fileSizes[i]]&&!this.fileSizeCheckBox[fileSizes[i]].checked){
                    this.fileSizeCheckBox[fileSizes[i]].checked = true;
                }
            }

            this.setState({
                selected: {
                    ...selected,
                    fileSizes:fileSizeConvert
                }
            });
        }else {
            for(let i = 0; i < fileSizes.length; i++){
                if(this.fileSizeCheckBox[fileSizes[i]]&&this.fileSizeCheckBox[fileSizes[i]].checked){
                    this.fileSizeCheckBox[fileSizes[i]].checked = false;
                }
            }

            this.setState({
                selected: {
                    ...selected,
                    fileSizes: []
                }
            });
        }

        if(this.fileSizeCheckBox['Custom'].checked){
            this.fileSizeCheckBox['Custom'].checked = false;
            this.setState({
                customInputBox: true
            })
        }
    };

    toggleFileSize = (size) => {
        const {selected, fileSizes} = this.state;
        if (this.fileSizeCheckBox[size].checked) {
            let checkSizeList = selected.fileSizes.concat(size);
            if(fileSizeList.every(item => checkSizeList.indexOf(item) > -1)){
                this.fileSizeCheckAll.current.checked = true;
                if(this.fileSizeCheckBox['Custom'].checked){
                    this.fileSizeCheckBox['Custom'].checked = false;
                    this.setState({
                        customInputBox: true
                    })
                }
                this.setState({
                    selected: {
                        ...selected,
                        fileSizes: fileSizeConvert
                    }
                });
            }else{
                let tmpSelectedSize = fileSizeConvert.filter(convert => convert.label == size);
                let tmpSizeList = selected.fileSizes.concat(tmpSelectedSize[0]);
                this.setState({
                    selected: {
                        ...selected,
                        fileSizes: tmpSizeList
                    }
                });
            }
        } else {
            if(fileSizeList.every(item => selected.fileSizes.indexOf(item) > -1)){
                this.fileSizeCheckAll.current.checked = false;
            }
            this.setState({
                selected: {
                    ...selected,
                    fileSizes: selected.fileSizes.filter(item=> item.label !== size)
                }
            });
        }

        console.log(selected.fileSizes);
    };

    toggleCustomSize = (custom) => {
        const {customInputBox, selected, fromSize, toSize} = this.state;
        let customSize = {minFileSizeInMB: parseInt(fromSize,10), maxFileSizeInMB:parseInt(toSize,10), label: "Custom"}
        if (this.fileSizeCheckBox[custom].checked) {
            this.setState({customInputBox: false, selected: {...selected, fileSizes: selected.fileSizes.concat(customSize)}});
        }else{
            this.setState({customInputBox: true, selected: {...selected, fileSizes: selected.fileSizes.filter(item => item.label !== 'Custom')}});
        }
    };

    changeCustomSize = (e) => {
        if(e.target.id === 'fromSize'){
            this.setState({
                fromSize:e.target.value
            });
        }else {
            this.setState({
                toSize:e.target.value
            });
        }
    };

    toggleTag = (tag) => {
        const {selected} = this.state;

        if (this.mediaTagCheckBox[tag.tagId].checked) {
            this.setState({
                selected: {
                    ...selected,
                    mediaTags: selected.mediaTags.concat(tag.tagId)
                }
            })
        } else {
            this.setState({
                selected: {
                    ...selected,
                    mediaTags: selected.mediaTags.filter(item => item !== tag.tagId)
                }
            })
        }
    };

    toggleRulesetStatusAll = (e) => {
        const {selected, rulesetStatus} = this.state;
        if(e.target.checked){
            for(let i = 0; i < rulesetStatus.length; i++) {
                if(!this.rulesetStatusCheckBox[rulesetStatus[i].value].checked) {
                    this.rulesetStatusCheckBox[rulesetStatus[i].value].checked = true;
                }
            }

            this.setState({
                selected: {
                    ...selected,
                    rulesetStatus: allRulesetStatus
                }
            });
        }
        else {
            for(let i = 0; i < rulesetStatus.length; i++) {
                if(this.rulesetStatusCheckBox[rulesetStatus[i].value].checked) {
                    this.rulesetStatusCheckBox[rulesetStatus[i].value].checked = false;
                }
            }

            this.setState({
                selected: {
                    ...selected,
                    rulesetStatus: []
                }
            });
        }
    };

    toggleRulesetStatus = (status) => {
        const {selected} = this.state;
        if (this.rulesetStatusCheckBox[status.value].checked) {
            selected.rulesetStatus.push(status.value);
            if(selected.rulesetStatus.length === rulesetStatusList.length) {
                this.rulesetStatusCheckAll.current.checked = true;
            }

            this.setState({
                selected: {
                    ...selected,
                    rulesetStatus: selected.rulesetStatus
                }
            });
        } else {
            this.rulesetStatusCheckAll.current.checked = false;
            this.setState({
                selected: {
                    ...selected,
                    rulesetStatus: selected.rulesetStatus.filter(item => item !== status.value)
                }
            });
        }
    };

    onClickApprovalStatus = (option) => e =>{
        e.preventDefault();
        const {selected} = this.state;
        this.setState({
            selected: {
                ...selected,
                approvalStatus:option
            }
        });
    };

    onClickContentUse = (option) => e =>{
        e.preventDefault();
        const {selected} = this.state;
        this.setState({
            selected: {
                ...selected,
                contentUse:option
            }
        });
    };

    onClickExpirationStatus = (option) => e =>{
        e.preventDefault();
        const {selected} = this.state;
        this.setState({
            selected: {
                ...selected,
                expirationStatus:option
            }
        });
    };

    onClickConnectionStatus = option => {
        const {selected} = this.state;
        this.setState({
            selected: {...selected, connectionStatus: option}
        });
    };

    onClickNotifications = (checked, option) => {
        const {selected} = this.state;

        if(checked) {
            this.setState({
                selected: {...selected, notifications: selected.notifications.concat(option)}
            });
        } else {
            this.setState({
                selected: {
                    ...selected, 
                    notifications: selected.notifications.filter(noti => noti !== option)
                }
            });
        }
    };

    onClickFunctions = (checked, option) => {
        const {selected} = this.state;

        if(checked) {
            this.setState({
                selected: {...selected, functions: selected.functions.concat(option)}
            });
        } else {
            this.setState({
                selected: {
                    ...selected, 
                    functions: selected.functions.filter(func => func !== option)
                }
            });
        }
    };

    onClickSources = (checked, option) => {
        const {selected} = this.state;

        if(option.value === 'all') {
            if(checked) {
                this.setState({
                    selected: {...selected, sources: deviceOptions.inputSource},
                    sourceChanged: true,
                });
            } else {
                this.setState({
                    selected: {...selected, sources: []},
                    sourceChanged: true,
                });
            }
        } else {
            if(checked) {
                this.setState({
                    selected: {...selected, sources: [...selected.sources, option]},
                    sourceChanged: true,
                });
            } else {
                this.setState({
                    selected: {
                        ...selected,
                        sources: selected.sources.filter(s => s.value !== option.value)
                    },
                    sourceChanged: true,
                });
            }
        }
    };

    onClickRulesetStatus = (option) => e =>{
        e.preventDefault();
        const {selected} = this.state;
        this.setState({
            selected: {
                ...selected,
                rulesetStatus:option
            }
        });
    };

    getOptionByValue = (options,value) => {
        return options.find(option=>option.value ==value);
    }

    render() {
        const {id} = this.props || '';
        const {type, width = type.toUpperCase() === 'PLAYLIST' ? 747 : 1357, misopt: {misopt}, t} = this.props;
        const {devices, users, mediaTags, contents, fileSizes, rulesetStatus, selected, selected:{approvalStatus, contentUse, expirationStatus}, fromSize,
            toSize, customInputBox, deviceTypeChanged, sourceChanged} = this.state;
        const {connectionStatusOption} = this;
        const className = type !== undefined ? type : '';

        return (

            <div className="select_box filter_container">
                <button className={classNames("select", {'contentSchedule' : className === 'content_schedule' || className === 'content_schedule_playlist'})} id="filterByBtn" onClick={()=>this.openFilter()}>
                    <span className="filterByIcon" style={{width: className !== 'content_schedule' && className !== 'content_schedule_playlist' ? 30 : 12}}>
                        {
                            className !== 'content_schedule' && className !== 'content_schedule_playlist'
                             && <span className="arrow" style={{marginTop: '-3px'}}></span>
                        }
                    </span>
                </button>
                <div className={"dim_background"} style={{display: (this.state.show ? '' : 'none')}} ref={dim => this.dim = dim}></div>
                <div className="filter_wrap" id="filterByDiv" style={{zIndex: 10000, display: (this.state.show ? '' : 'none'), left: this.state.position.x, top: this.state.position.y, width: width}}>
                    <div style={{width}}>
                        <div className="filter_status">
                            {
                                this.state.menu.contentDeviceType !== undefined && this.state.menu.contentDeviceType &&
                                    <ContentDeviceFilterStatus selected={selected} deviceTypes={this.deviceTypes}/>
                            }
                            {
                                this.state.menu.playlistType !== undefined && this.state.menu.playlistType &&
                                    <PlaylistTypeFilterStatus selected={selected} playlistTypeChanged={this.state.playlistTypeChanged} playlistTypes={this.state.playlists}/>
                            }
                            {
                                this.state.menu.deviceType !== undefined && this.state.menu.deviceType && type.toUpperCase() !== 'DEVICE' &&
                                    <DeviceFilterStatus deviceTypes={this.deviceTypes} connectionStatusOption={connectionStatusOption} selected={selected}
                                        deviceTypeChanged={deviceTypeChanged} sourceChanged={sourceChanged} />
                            }
                            {
                                this.state.menu.deviceType !== undefined && this.state.menu.deviceType && type.toUpperCase() === 'DEVICE' &&
                                <DeviceFilterStatus deviceTypes={misopt.deviceTypes} connectionStatusOption={connectionStatusOption} tagOptions={mediaTags} selected={selected}
                                                    deviceTypeChanged={true} sourceChanged={true}
                                />
                            }
                            {
                                this.state.menu.fileType !== undefined && this.state.menu.fileType &&
                                    <FileTypeFilterStatus selectedContents={selected.contents} mediaTypes={contents}/>
                            }
                            {
                                this.state.menu.users !== undefined && this.state.menu.users &&
                                    <UserFilterStatus selectedUsers={selected.users} />
                            }
                            {
                                this.state.menu.approvalStatus !== undefined && this.state.menu.approvalStatus &&
                                    <ContentApprovalFilterStatus approvalStatusLabel={approvalStatus.label}/>
                            }
                            {
                                this.state.menu.contentUse !== undefined && this.state.menu.contentUse &&
                                    <ContentUseFilterStatus contentUseLabel={contentUse.label}/>
                            }
                            {
                                this.state.menu.expirationStatus !== undefined && this.state.menu.expirationStatus &&
                                    <ContentExpirationFilterStatus expirationStatusLabel={expirationStatus.label}/>
                            }
                            {
                                this.state.menu.mediaTag !== undefined && this.state.menu.mediaTag && type.toUpperCase() !== 'DEVICE' &&
                                    <MediaTagFilterStatus mediaTags={mediaTags} selectedMediaTag={selected.mediaTags}/>
                            }
                            {
                                this.state.menu.fileSize !== undefined && this.state.menu.fileSize &&
                                    <ContentFileSizeFilterStatus selectedFileSizes={selected.fileSizes} fileSizeList={fileSizeConvert}/>
                            }
                            {
                                this.state.menu.rulesetStatus !== undefined && this.state.menu.rulesetStatus &&
                                    <RulesetStatusFilterStatus selectedRulesetStatus={selected.rulesetStatus} rulesetStatusList={rulesetStatusList} />
                            }
                        </div>
                        <div className="filter clearfix">
                            {
                                this.state.menu.contentDeviceType !== undefined && this.state.menu.contentDeviceType &&
                                <DeviceTypes devices={devices} selected={selected.devices} deviceTypeCheckAll={this.deviceTypeCheckAll} toggleDeviceAll={this.toggleDeviceAll} toggleDevice={this.toggleDevice} deviceTypeCheckBox={this.deviceTypeCheckBox}/>
                            }
                            {
                                this.state.menu.playlistType !== undefined && this.state.menu.playlistType &&
                                    <PlaylistTypes playlistTypes={this.state.playlists} playlistTypeCheckAll={this.playlistTypeCheckAll} togglePlaylistTypeAll={this.togglePlaylistTypeAll} togglePlaylistType={this.togglePlaylistType} playlistTypeCheckBox={this.playlistTypeCheckBox} selectedPlaylistType={selected.playlistTypes}/>
                            }
                            {
                                this.state.menu.deviceType !== undefined && this.state.menu.deviceType &&
                                    <DeviceTypes devices={
                                        type.toUpperCase() !== 'DEVICE' ? devices : 
                                        misopt.deviceTypes.filter(dt => dt.deviceFullType !== LEDBOX && dt.deviceFullType !== RSPLAYER).map(dt => ({type: dt.deviceType.toUpperCase(), version: dt.deviceTypeVersion, id: dt.deviceFullType}))
                                    } selected={selected.devices} deviceTypeCheckAll={this.deviceTypeCheckAll} toggleDeviceAll={this.toggleDeviceAll} toggleDevice={this.toggleDevice} deviceTypeCheckBox={this.deviceTypeCheckBox}/>
                            }
                            {
                                this.state.menu.device !== undefined && this.state.menu.device &&
                                    <>
                                        <ConnectionStatusFilter connectionStatus={selected.connectionStatus} onChange={this.onClickConnectionStatus} />
                                        <NotificationFilter notifications={selected.notifications} onChange={this.onClickNotifications} />
                                        <FunctionFilter functions={selected.functions} onChange={this.onClickFunctions} />
                                    </>
                            }
                            {
                                this.state.menu.fileType !== undefined && this.state.menu.fileType &&
                                    <ContentFileTypes id={id} contents={contents} contentCheckAll={this.contentCheckAll} toggleContentAll={this.toggleContentAll} toggleContent={this.toggleContent} contentCheckBox={this.contentCheckBox} selectedContents={selected.contents}/>
                            }
                            {
                                this.state.menu.rulesetStatus !== undefined && this.state.menu.rulesetStatus &&
                                    <RulesetStatus
                                        selected={selected.rulesetStatus}
                                        rulesetStatus={rulesetStatus}
                                        rulesetStatusCheckAll={this.rulesetStatusCheckAll}
                                        toggleRulesetStatusAll={this.toggleRulesetStatusAll}
                                        rulesetStatusCheckBox={this.rulesetStatusCheckBox}
                                        toggleRulesetStatus={this.toggleRulesetStatus}
                                    />
                            }
                            {
                                this.state.menu.users !== undefined && this.state.menu.users &&
                                    <Users id={id} users={users} userCheckBox={this.userCheckBox} toggleUser={this.toggleUser} selectedUsers={selected.users}/>
                            }
                            {
                                this.state.menu.approvalStatus !== undefined && this.state.menu.approvalStatus &&
                                    <DeviceStatus approvalStatus={approvalStatus} onClickApprovalStatus={this.onClickApprovalStatus} approvalStatusOption={approvalStatusOption}/>
                            }
                            {
                                this.state.menu.contentUse !== undefined && this.state.menu.contentUse &&
                                    <ContentUse contentUse={contentUse} contentUseOption={contentUseOption} onClickContentUse={this.onClickContentUse}/>
                            }
                            {
                                this.state.menu.expirationStatus !== undefined && this.state.menu.expirationStatus &&
                                    <ContentExpiration expirationStatus={expirationStatus} expirationStatusOption={expirationStatusOption} onClickExpirationStatus={this.onClickExpirationStatus}/>
                            }
                            {
                                this.state.menu.mediaTag !== undefined && this.state.menu.mediaTag &&
                                    <MediaTag id={id} type={type} mediaTags={mediaTags} toggleTag={this.toggleTag} mediaTagCheckBox={this.mediaTagCheckBox} selectedMediaTag={selected.mediaTags}/>
                            }
                            {
                            this.state.menu.source !== undefined && this.state.menu.source &&
                            <SourceFilter sources={selected.sources} onChange={this.onClickSources} />
                            }
                            {
                                this.state.menu.fileSize !== undefined && this.state.menu.fileSize &&
                                    <ContentFileSize id={id} fileSizes={fileSizes} selected={selected.fileSizes} fileSizeCheckAll={this.fileSizeCheckAll} toggleFileSizeAll={this.toggleFileSizeAll} fileSizeCheckBox={this.fileSizeCheckBox} toggleFileSize={this.toggleFileSize} customInputBox={customInputBox} fromSize={fromSize} toSize={toSize} changeCustomSize={this.changeCustomSize} toggleCustomSize={this.toggleCustomSize}/>
                            }
                        </div>
                        <div className="btn_wrap">
                            <button id="saveFilterBy" className="base mr20" style={{width: '102px'}} onClick={() => this.saveFilter()}>{t('BUTTON_VIEW_P')}</button>
                            <button id="cancelFilterBy" className="base" style={{width: '102px'}} onClick={() => this.closeFilter()}>{t('BUTTON_CANCEL_P')}</button>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default withTranslation()(withMISOpt(Filter));
