import React from 'react';
import {connect} from 'react-redux';
import {contentAction, menuAction, popupAction} from '../../../actions';
import './UploadContent.css';
import {withTranslation} from 'react-i18next';
import {bytesToSize} from '../../../helper';
import ProgressBar from '../../progressbar/ProgressBar';
import withMISOpt from '../../misopt';
import {contentService} from '../../../services';
import minimizeIcon from '../../../images/button/popup_btn_circle_hide_normal.png';
import circleCloseIcon from '../../../images/button/popup_btn_circle_close_normal.png';
import {commonConstants} from '../../../constants/CommonConstants';
import {toastr} from 'helper/toastrIntercept';
import classNames from "classnames";
import Dropzone from 'react-dropzone';
import {contentConstants} from "../../../constants";
import {getCancelTokenSource} from "../../../helper/axiosIntercepts";
import {validateSpecialChar} from "../../../helper/contentValidator"
import {globalZIndex} from "../../../helper";
import MagicInfoTable from "../../table/MagicInfoTable";

const fileTypeList = ".gif,.jpeg,.jpg,.png,.swf,.bmp,.asf,.avi,.mpeg,.mpg,.ts,.trp,.m2v,.m2p,.mp4,.m1v,.m4v,.m4t,.vob,.m2t,.tsp,.mov,.asx,.wmv,.tp,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.htm,.html,.pps,.ppsx,.pdf,.mp3,.ogg,.wav,.wma,.mp2,.ac3,.pcm,.lpcm,.flv,.wmf,.emf,.tif,.tiff,.mid,.mkv,.ra,.rm,.ram,.rmvb,.3gp,.svi,.m2ts,.divx,.mts,.vro";
const inputFileStyle = {
    fontSize: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 0,
    opacity: 0,
    cursor: 'pointer',
    width: 80,
    display: 'inline-block',
};

const UPLOAD_STATUS = {
    INIT: 'INIT',
    FAIL : 'FAIL',
    UPLOAD: 'UPLOAD',
    UPDATE: 'UPDATE',
    CANCEL: 'CANCEL',
    FINISHED: 'FINISHED',
    UPLOAD_COMPLETED: 'UPLOAD_COMPLETED'
};

class UploadContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detailView : true,
            minimize: false,
            items: [],
            tableStyle: {
                height: '201px'
            },
            completed: 0,
            total: 0,
            groupId : props.menu.groupId !== undefined ? props.menu.groupId : 0,
            groupName : 'default',
            percentage: 0,
            left : 619,
            top : 243,
            isCheck:[],
            isUploadStarting: false,
            cancelSource: getCancelTokenSource(),
            logInGroupId: localStorage.getItem('user') === null ? 0 : JSON.parse(localStorage.getItem('user')).groupId
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions.bind(this));
        this.updateDimensions();

        contentService.fetchGroups({
            groupType: contentConstants.MY_CONTENT_GROUP
        }).then((res) => {
            let group = res.items.find(item => item.groupId == this.state.groupId);
            if(group !== undefined){
                this.setState({groupId: group.groupId, groupName: group.groupName});
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions.bind(this));
    }

    updateDimensions() {
        let left = (window.innerWidth - 782) / 2;
        let top = (window.innerHeight - 480) / 2;
        this.setState({
            left: left,
            top: top
        });
    }

    closePopup = () => {
        const {closePopup, id} = this.props;
        closePopup(id);
    };

    uploadStart = async () => {
        const {t, mode = 'UPLOAD', updateContentId, onSuccess, reloadContent, reloadGroup} = this.props;
        const {items} = this.state;
        let uploadFunclist = [];

        for (const item of items) {
            if (item.loaded === 100) {
                item.status = UPLOAD_STATUS.FINISHED;
            }
            if (this._checkSkipItems(item)) {
                continue;
            }

            this.setState({isUploadStarting: true});

            item.loaded = 0;
            let formData = new FormData();
            formData.append("files", item.file);
            formData.append("groupId", item.groupId);
            formData.append("categoryIds", item.categoryIds);
            formData.append("contentType", this.getMediaType(item.fileName));

            if (mode !== undefined && mode === 'UPDATE') {
                formData.append("updatedContentId", updateContentId);
            }

            uploadFunclist.push(
                await contentService.uploadContent(formData, item.fileIndex, this.onProgress, this.state.cancelSource.token)
                    .then((res) => {
                        const {completed} = this.state;
                        item.loaded = 100;
                        item.status = UPLOAD_STATUS.UPLOAD_COMPLETED;
                        this.setState({
                            completed: completed + 1,
                            items
                        });

                        this.calculatePercentage();
                        toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                        if(mode !== undefined && mode === 'UPDATE') {
                            if(res.status === 'Success') {
                                onSuccess();
                            }
                        }
                    })
                    .catch((error) => {
                        if (error === UPLOAD_STATUS.CANCEL) {
                            this.setState({percentage: 0});
                            return;
                        }

                        const {completed} = this.state;
                        item.status = UPLOAD_STATUS.FAIL;
                        this.setState({
                            completed: completed + 1,
                            items
                        });

                        const {addPopup, closePopup, t} = this.props;
                        addPopup({
                            id: commonConstants.COMMON_CONFIRM_POPUP,
                            type: commonConstants.COMMON_CONFIRM_POPUP,
                            title: t('COM_BUTTON_CONFIRM'),
                            useMessageLine: false,
                            useNoButton: false,
                            yesTitle: t('COM_BUTTON_CLOSE_P'),
                            message: t('COM_IDS_MSG_UNEXPEXTED_ERROR'),
                            onClickYes: () => {closePopup(commonConstants.COMMON_CONFIRM_POPUP);}
                        });
                    })
            );
        }

        if (uploadFunclist.length === 0) {
            return;
        }

        await Promise.all([
            uploadFunclist
        ]).then(res => {
            this.setState({isUploadStarting: false});
            this.state.cancelSource = getCancelTokenSource();
            reloadContent(true);
            reloadGroup('MY_CONTENT');
            reloadGroup('BY_USER');
        })
    };

    _checkSkipItemsForCalculation = (item) => {
        return item.status === UPLOAD_STATUS.FINISHED  || item.isSupportedType === false || item.hasSpecialCharacter;
    };

    _checkSkipItems = (item) => {
        return item.status === UPLOAD_STATUS.FINISHED  || item.status === UPLOAD_STATUS.FAIL || item.isSupportedType === false || item.hasSpecialCharacter;
    };

    uploadStop = () => {
        this.state.cancelSource.cancel(UPLOAD_STATUS.CANCEL);
        this.setState({isUploadStarting: false});
    };

    calculatePercentage = () => {
        const {items} = this.state;
        let totalLoaded = 0;
        let totalCount = 0;
        for (let i = 0; i < items.length; ++i) {
            if (this._checkSkipItemsForCalculation(items[i])){
                continue;
            }
            totalLoaded += items[i].loaded;
            ++totalCount;
        }

        let totalPercentage = Math.round(totalLoaded / totalCount);
        this.setState({percentage: totalPercentage});

        if (totalPercentage === 100) {
            this.setState({isUploadStarting: false});
        }
    };

    onProgress = (fileIndex, loaded) => {
        const {items} = this.state;
        const index = items.findIndex(item => item.fileIndex === fileIndex);

        if (items[index].status !== UPLOAD_STATUS.UPLOAD_COMPLETED && loaded === 100) {
            return;
        }

        if (items[index].loaded === 100) {
            return;
        }

        items[index].loaded = loaded;
        this.setState({
            items
        });
        this.calculatePercentage();
    };

    getMediaType = (fileName) => {
        const {misopt:{misAcceptFileTypeObj}} = this.props;
        const ext = fileName.split('.').pop();
        if(ext==='html') {
            return 'OFFICE'
            //In case of upload '.html only' through common upload content popup, treat as OFFICE type.
            //cf)Through Webapp content upload popup, treat as 'HTML' content type.
        }
        const obj = misAcceptFileTypeObj(ext);
        return (obj !== undefined ? obj.type : null);
    };

    categoryPopup = () => {
        const {addPopup, closePopup, t} = this.props;
        const {items}= this.state;
        const saveGroup = (groups) => {
            closePopup(commonConstants.COMMON_GROUP_POPUP);
            if (groups && groups.length > 0) {
                items.filter(item => item.isCheck).map(item => {
                    item.categoryIds = groups.flatMap((group) => group.groupId).join(',');
                    item.categoryNames = groups.flatMap((group) => group.groupName).join(', ');
                });
                this.setState(items);
            }
        };

        let currentItem = items.filter(item => item.isCheck);
        if(currentItem.length > 0){
            let checkedKeys = currentItem.length === 1 ? currentItem[0].categoryIds.split(',') : [];
            addPopup({
                id: commonConstants.COMMON_GROUP_POPUP,
                type: commonConstants.COMMON_GROUP_POPUP,
                mode: 'category',
                save: saveGroup,
                close: () => closePopup(commonConstants.COMMON_GROUP_POPUP),
                disableSearchToolbar: true,
                checkbox: true,
                selectAll: false,
                checkedKeys: checkedKeys,
                expandedKeys: checkedKeys,
                selectedKeys: checkedKeys,
                checkStrictly: true,
                noTitle: t('COM_BUTTON_CLOSE_P'),
                organizationId : this.state.logInGroupId
            });
        } else {
            toastr.error(t('COM_TEXT_SELECT_CONTENT_P'));
        }
    };

    deleteButton = () => {
        const {t} = this.props;
        const {items} = this.state;

        if (items.filter(item => item.isCheck).length < 1) {
            toastr.error(t('COM_TEXT_SELECT_CONTENT_P'));
            return;
        }

        let {completed} = this.state;
        let remainItems = [];
        items.forEach(item => {
            if (item.isCheck) {
                if (item.loaded === 100) {
                    toastr.warning(t('COM_TV_SID_EDEN_UNABLE_DELTE'));
                    remainItems.push(item);
                } else if (item.status === UPLOAD_STATUS.FAIL) {
                    completed--;
                }
            } else {
                remainItems.push(item);
            }
        });
        this.setState({items: remainItems, total: remainItems.filter(item => item.isSupportedType).length, completed});
    };

    switchDetailView = () => {
        const {detailView} = this.state;

        if(detailView) {
            this.setState({
                detailView : false,
                height: 202
            })
        }else {
            this.setState({
                detailView : true,
                height: 433})
        }
    };

    switchMinimize = () => {
        const {minimize} = this.state;

        if(minimize) {
            this.setState({
                minimize : false
            })
        }else {
            this.setState({
                minimize : true
            })
        }
    };

    getTrGroupProps = (state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
            let {items} = this.state;
            return {
                className: 'content_tr_group',
                style: {
                    background: rowInfo.row.isCheck ? '#e6f2ff' : 'white'
                },
                onClick: () => {
                    items[rowInfo.index].isCheck = !rowInfo.row.isCheck;
                    this.setState({items});
                },
            }
        }else{
            return {}
        }
    };

    addFileItems = (files) => {
        const {t, mode = 'UPLOAD'} = this.props;
        const {groupId, groupName, items} = this.state;

        let acceptFileTypes = /^(gif|jpe?g|png|swf|bmp|asf|avi|mpeg|mpg|ts|trp|m2v|m2p|mp4|m1v|m4v|m4t|vob|m2t|tsp|mov|asx|wmv|tp|doc|docx|ppt|pptx|xls|xlsx|htm|html|pps|ppsx|pdf|mp3|ogg|wav|wma|mp2|ac3|pcm|lpcm|flv|wmf|emf|tif|tiff|mid|mkv|ra|rm|ram|rmvb|3gp|svi|m2ts|divx|mts|vro)$/i;
        let appendItems=[];
        files.forEach(file => {
            if(file.size === 0){
                toastr.error(t('MESSAGE_UPLOADER_ZERO_FILE_SIZE_P'))
            } else {
                appendItems.push({
                    file: file,
                    fileName: file.name,
                    groupId: groupId,
                    groupName: groupName,
                    categoryIds: '',
                    categoryNames: '',
                    fileSize: bytesToSize(file.size),
                    loaded: 0,
                    isCheck: false,
                    isSupportedType: acceptFileTypes.test(file.name.split('.').pop()),
                    hasSpecialCharacter: !validateSpecialChar(file.name),
                    status: UPLOAD_STATUS.INIT,
                    fileIndex: new Date().getTime()
                });
            }
        });
        if(mode === 'UPLOAD') {
            this.setState({
                items: [...items, ...appendItems],
                total: items.filter(item => item.isSupportedType).length + appendItems.filter(item => item.isSupportedType).length
            })
        } else {
            this.setState({
                items: [...appendItems],
                total: items.filter(item => item.isSupportedType).length + appendItems.filter(item => item.isSupportedType).length
            })
        }
    };

    onChangeItems = (event) => {
        const files = [...event.target.files];
        this.addFileItems(files);
    };

    onDropItems = (files) => {
        this.addFileItems(files);
    };

    render() {
        const {t, mode = 'UPLOAD', zIndex = globalZIndex} = this.props;
        const {detailView, items, tableStyle, completed, total, percentage, minimize, left, top, isUploadStarting} = this.state;
        const style = {left: left, top: top, position: 'absolute'};

        const totalStr = percentage === 0 ? t('COM_CRM_WAITING_KR_BLANK') : percentage === 100 ? t('MSG_FINISHED') : percentage+'%';
        return(
                <div>
                    <div className="dim_background" style={{display: (minimize === false ? 'block' : 'none'), zIndex: (zIndex+1)}}/>
                    <div className="upload_content_popup_wrap" style={{display: (minimize === false ? 'block' : 'none'), zIndex: (zIndex+2), ...style}}>
                        <div className={'popup_wrap upload_content_popup'}>
                            <div className="popup_header">
                                <h1>{t('TEXT_TITLE_CONTENT_UPLOAD_P')}</h1>
                                <div>
                                    {
                                        mode !== undefined && mode === 'UPLOAD' &&
                                        <button className="pop_circle" id="uploadHideBtn" onClick={() => this.switchMinimize()}>
                                            <img src={minimizeIcon}/>
                                        </button>
                                    }
                                    <button className="pop_circle" onClick={() => this.closePopup()}>
                                        <img src={circleCloseIcon}/>
                                    </button>
                                </div>
                            </div>
                            <div className="list_wrap_empty" id="contentFileupload">
                                <div className="popup_contents" style={{paddingTop: 40}}>

                                    <div className="uploading mb19" style={{margin: 0}}>
                                        {
                                            mode !== undefined && mode === 'UPLOAD' &&
                                            <span className="file_total">
                                                <span className="completeFile">{completed}</span>/
                                                <span className="totalFile">{total}</span> {t('DID_ADMIN_NOTICE_FILE')}
                                            </span>
                                        }
                                        <ProgressBar percentage={percentage} width={732} height={19} background={"#e8e8e8"}
                                                     fillerColor={"#4be48c"} useLabel={true}/>
                                    </div>
                                </div>
                                <div className="popup_btn_wrap clearfix noline">
                                    {
                                        mode !== undefined && mode === 'UPLOAD' &&
                                        <div className="float_l">
                                            <button id="uploadDetailBtn"
                                                    className={`detail_btn ${detailView === true ? "on" : ""}`}
                                                    onClick={() => this.switchDetailView()}>
                                                <span/>{t('BUTTON_DETAIL_P')}
                                            </button>
                                        </div>
                                    }
                                    <div className="float_r">
                                        <span className={classNames('base','mr8', 'span_button', {'disabled': isUploadStarting === true})} id="addContentFileBtn">
                                            {
                                                mode !== undefined && mode === 'UPLOAD' &&
                                                <input type="file" id="fileupload" name="files[]" multiple
                                                    style={inputFileStyle}
                                                    onChange={this.onChangeItems}
                                                    onClick={(event) => {
                                                        event.target.value = null
                                                    }}
                                                    accept={fileTypeList}
                                                    disabled={isUploadStarting}
                                                />
                                            }
                                            {
                                                mode !== undefined && mode === 'UPDATE' &&
                                                <input type="file" id="fileupload" name="files[]"
                                                       style={inputFileStyle}
                                                       onChange={this.onChangeItems}
                                                       onClick={(event) => {
                                                           event.target.value = null
                                                       }}
                                                       accept={fileTypeList}
                                                       disabled={isUploadStarting}
                                                />
                                            }
                                            {t('COM_BUTTON_ADD_CONTENT_P')}
                                        </span>

                                        <button className="base mr8" id="contentUploadStartBtn"
                                                onClick={isUploadStarting ? () => this.uploadStop() : () => this.uploadStart()}>{isUploadStarting ? t('COM_BUTTON_STOP_P') : t('BUTTON_START_UPLOAD_P')}</button>
                                        {
                                            mode !== undefined && mode === 'UPLOAD' &&
                                            <button className="base mr8" id="contentCategoryBtn"
                                                 onClick={() => this.categoryPopup()} disabled={isUploadStarting}>{t('DID_MAIN_CATEGORY')}</button>
                                        }
                                        {
                                            mode !== undefined && mode === 'UPLOAD' &&
                                            <button className={classNames('base', 'mr8', {'disabled': isUploadStarting})} id="deleteContentFileBtn"
                                                 onClick={() => this.deleteButton()} disabled={isUploadStarting}>{t('COM_BUTTON_DELETE')}</button>
                                        }
                                        <button type="reset" id="uploadFileCancelBtn" className="base"
                                                onClick={() => this.closePopup()} disabled={isUploadStarting}>{percentage === 100 ? t('COM_BUTTON_CLOSE_P') : t('BUTTON_CANCEL_P')}</button>
                                    </div>
                                </div>
                                {
                                    detailView &&
                                    <Dropzone onDrop={this.onDropItems}>
                                        {({getRootProps, getInputProps}) => (
                                        <div className="detail_view mr25 ml25 pb25" style={{outline:0}} {...getRootProps()}>
                                            <div className="upload_content_list_view" {...getInputProps()} style={{width: 732, height: 202, outline:0, display: ''}} >
                                                <MagicInfoTable
                                                    noDataText={null}
                                                    data={items}
                                                    minRows={0}
                                                    getTrGroupProps={this.getTrGroupProps}
                                                    showPagination={false}
                                                    resizable={false}
                                                    columns={[
                                                        {
                                                            Header: t('TEXT_AD_FILE_NAME_P'),
                                                            width: 155,
                                                            accessor: "fileName",
                                                        }, {
                                                            Header: t('TEXT_GROUP_P'),
                                                            width: 99,
                                                            show: mode !== undefined && mode === 'UPLOAD',
                                                            accessor: "groupName"
                                                        }, {
                                                            Header: t('DID_MAIN_CATEGORY'),
                                                            width: 100,
                                                            show: mode !== undefined && mode === 'UPLOAD',
                                                            accessor: "categoryNames",
                                                            Cell: (row) => {
                                                                return items[row.index].categoryNames === '' ? 'NONE' : items[row.index].categoryNames
                                                            }
                                                        }, {
                                                            Header: t('ADMIN_CONTENT_REGISTRATION_CONTENTS_LIST_JSP_FILESIZE'),
                                                            width: 80,
                                                            accessor: "fileSize"
                                                        }, {
                                                            Header: t('TEXT_STATUS_P'),
                                                            Cell: (row) =>
                                                                (items[row.index].isSupportedType
                                                                    ? items[row.index].hasSpecialCharacter
                                                                    ? t('COM_EBD_SPECIAL_CHAR_NOTALLOW_KR_2')
                                                                        : <div style={{display: "flex", alignItems: "center"}}>
                                                                            <div className='float_l'>
                                                                                <ProgressBar percentage={items[row.index].loaded} width={200} height={5} useLabel={false}
                                                                                             background={'#e8e8e8'} fillerColor={isNaN(items[row.index].loaded) ?"#FF0000":"#4be48c"}
                                                                                />
                                                                            </div>
                                                                            <div className='float_r ml20'>
                                                                                {isNaN(items[row.index].loaded) ? t('TEXT_FAIL_P'):items[row.index].loaded+'%'}
                                                                            </div>
                                                                        </div>
                                                                    : t('MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK')
                                                                )
                                                        }, {
                                                            Header: '',
                                                            width: 0,
                                                            accessor: "isCheck",
                                                            show: false,
                                                        }
                                                    ]}
                                                    className="-striped -highlight"
                                                    manual
                                                    style={tableStyle}
                                                />
                                            </div>
                                        </div>
                                        )}
                                    </Dropzone>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        minimize &&
                        <div className="progressBarWrap" onClick={()=>this.switchMinimize()}>
                            <div className="progressBar" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0">
                                <div className="progressBarText">{totalStr}</div>
                                <ProgressBar percentage={percentage} width={328} height={39} background={"#4be48c"} fillerColor={"#4be48c"} useLabel={false}/>
                            </div>
                        </div>
                    }
                </div>
        )
    }
}
export default connect(
    state => ({
        menu: state.menu
    }),
    dispatch => ({
        addPopup: (id) => dispatch(popupAction.addPopup(id)),
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
        reloadContent: (isReload)=> dispatch(contentAction.reloadContent(isReload)),
        reloadGroup: (submenuId, groupId) => dispatch(menuAction.reloadGroup(submenuId, groupId)),
    })

)(withTranslation()(withMISOpt(UploadContent)));