import React from 'react';
import './SupportedDeviceIcon.css';
import {APlayer, WPlayer} from "./index";
import {useMISOpt} from "../misopt";
import PlayerIcon from "./players";
import {MEDIA_TYPE_CHECK_FOR_WPLAYER_ICON} from "../../constants";


const SupportedDeviceIcon = ({deviceType = 'SPLAYER', deviceTypeVersion, mediaType, fileType = undefined}) => {

    const {supportedIconDevices, misOption, devices, getDevicePriority} = useMISOpt();
    const getDeviceTypeVersion = (version) => {
        switch (version+"") {
            case '1': case '1.0': return 1;
            case '2': case '2.0': return 2;
            case '3': case '3.0': return 3;
            case '4': case '4.0': return 4;
            case '5': case '5.0': return 5;
            case '6': case '6.0': return 6;
            case '7': case '7.0': return 7;
            case '8': case '8.0': return 8;
            default : return 1;
        }
    }

    const getAlterDevicePriority = (typeVersion) => {
        if(deviceType === 'WPLAYER' || deviceType === 'LPLAYER') {
            return 99999;
        }
        const alterDevice = devices.find(d => d.deviceTypeVersion === typeVersion);

        return alterDevice ? alterDevice.priority : 99999;
    };

    const typeVersion = getDeviceTypeVersion(deviceTypeVersion);
    const option = misOption(deviceType.toUpperCase(), typeVersion);
    const priority = mediaType !== undefined ? getDevicePriority(deviceType.toUpperCase(), typeVersion) :
                     option !== undefined ? option.priority : getAlterDevicePriority(typeVersion);

    if (priority === -1) {
        return (
            <span><APlayer deviceTypeVersion={1}/></span>
        )
    }
    if (priority === -2) {
        return (
            <span><WPlayer deviceTypeVersion={1}/></span>
        )
    }

    if(mediaType !== undefined && !MEDIA_TYPE_CHECK_FOR_WPLAYER_ICON.includes(mediaType)){
        return (
            <span>
            {
                supportedIconDevices !== undefined && supportedIconDevices.length > 0 &&  supportedIconDevices.map(
                    device => device !== undefined && device.contentInfo.fileTypes.findIndex(type => type.mediaType === mediaType && (fileType ? type.fileType === fileType : true)) > -1 ? <PlayerIcon deviceType={device.deviceType} deviceTypeVersion={device.deviceTypeVersion} text={''}/> : ''
                )
            }
            </span>
        )
    }

    return (
        <span>
            {
                supportedIconDevices !== undefined && supportedIconDevices.length > 0 &&  supportedIconDevices.map(
                    (device, index) =>
                        device !== undefined && device.priority >= priority ?
                            <PlayerIcon key={index} deviceType={device.deviceType} deviceTypeVersion={device.deviceTypeVersion} text={''}/>
                            :
                            ''
                )
            }
        </span>
    )
}

export default SupportedDeviceIcon;
