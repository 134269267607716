import {authHeader, createQuery, createQuery2, handleResponse, history} from '../helper';
import {fetchWithToken as fetch} from '../helper/fetchIntercept.js';

const backendHost = window.globalConfig.backendHost || process.env.REACT_APP_BACKEND_URL;

export const commonService = {
    fetchRole,
    fetchCategory,
    fetchCategoryById,
    fetchMediaTag,
    fetchServerVersion,
    fetchCheckAuth
};

function fetchRole() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(backendHost+'/restapi/v2.0/ums/roles', requestOptions).then(handleResponse);
}

function fetchCategory() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(`${backendHost}/restapi/v2.0/ems/settings/categories?ts=${new Date().getTime()}`, requestOptions).then(handleResponse);
}

function fetchCategoryById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    const params ={
        categoryId: id
    }
    return fetch(backendHost+'/restapi/v2.0/ems/settings/categories?'+createQuery(params), requestOptions).then(handleResponse);
}

function fetchMediaTag() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    const params ={
        organId: 1,
        pageSize: 100,
        sortColumn: 'tag_value',
        sortOrder: 'asc',
        startIndex: 1
    }
    return fetch(backendHost+'/restapi/v2.0/ems/settings/tags?'+createQuery(params), requestOptions).then(handleResponse);
}

function fetchServerVersion() {
    return fetch(`${backendHost}/auth?cmd=isMagicInfo`, {
        method: 'GET',
        headers: {'accept': 'application/json', 'content-type': 'application/json'}
    }).then(
        response => {
            return new Promise((resolve, reject) => {

                if (!response.ok) {
                    localStorage.removeItem('user');
                    history.push('/login');
                    return reject(response);
                } else {
                    return response.text().then(text => {
                        const data = text && JSON.parse(text);
                        if (!response.ok) {
                            if (response.status === 401) {
                                // auto logout if 401 response returned from api
                                history.push('/login');
                            }
                            return new reject(data);
                        }
                        resolve(data);
                    });
                }
            });
        }
    );
}

function fetchCheckAuth() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(backendHost+'/restapi/v2.0/auth/checkAuth', requestOptions).then(handleResponse);
}

/*
function fetchToken(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    }
    const params ={
        clientId: data.client_id,
        client_secret: data.client_secret,
        response_type: data.response_type,
        redirect_uri: data.redirect_uri,
    }
    return fetch(data.url + '?' + createQuery2(params), requestOptions).then(handleResponse);
}*/